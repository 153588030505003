import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  numericValidator,
  onlyNumbers,
  removeNonNumeric,
  toggleLoader,
} from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';
import { ResponseSearch } from './interfaces/register.interfaces';
import { RegisterService } from './services/register.service';

import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { ParemetersService } from 'src/app/shared/services/parameters.service';
import { validateCampo } from '../../shared/helpers/functions-helper.service';
import { AuthService } from '../login/services/auth.service';
import { StepService } from './components/step/service/step.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    './register.component.scss',
    './pages/third-page/third-page.component.html',
    './pages/four-page/four-page.component.scss',
    './pages/six-page/six-page.component.scss',
  ],
})
export class NewRegisterComponent2 {
  @ViewChild('otroInput') otroInput!: ElementRef;
  allyForm: FormGroup;
  step: number = 1;
  documentFrontImage: SafeResourceUrl | undefined;
  documentBackImage: SafeResourceUrl | undefined;
  selfieImage: SafeResourceUrl | undefined;
  defaultFormControl = new FormControl();
  cities: any[] = [];
  imagenFrontalVisible: boolean = false;
  imagenTraseraVisible: boolean = false;
  imagenSelfieVisible: boolean = false;
  selectedFiles: { [key: string]: File } = {};
  validateCampo = validateCampo;
  tokenMetamap: string = '';
  // id para las consultas del hook de metamap
  idMetamap: string = '';
  // id para las peticiones de envio de fotos
  idetifyMetamap: string = '';
  // verificacion de metamap completada
  verificacionMetamap: boolean = false;
  // reintento al consultar datos del cliente en metamap
  retriesSearchMetamap: number = 1;
  businessTypes: any[] = [];
  flagSendCodeOpt = false;
  // Modal para aceptar y enviar codigo por whatsapp!!!
  modalTermsAndConditions: boolean = false;
  phoneStorage: number;
  code: string | null;
  logo_proveedor: string | null;
  // se almacenara los datos del usuario cuando vengan de metamap aqui para luego recuperarlo ya que al deshabilitar los campos no se
  // puede recuperar los datos del formulario
  currentName = '';
  currentLastName = '';
  currentIdentification = '';
  currentPhone1Valido = '';
  existCustomer = false;
  currenValueRequest = 0;

  private swalModal: any; // Propiedad para almacenar el objeto Swal
  // vista de autorizacion de terminos y condiciones
  comunication_all = true;
  comunication_email = true;
  comunication_message = false;
  authorization_treatment_data = false;
  registeredChamberCommerceOptions: { value: string; label: string }[] = [
    { value: '1', label: 'Sí' },
    { value: '2', label: 'No' },
  ];

  /**
   * Representa una lista de pasos a seguir en el proceso.
   * Cada paso tiene un número y un nombre descriptivo.
   * @type {Array<{ numero: number, nombre: string }>}
   */
  pasos: { numero: number; nombre: string }[] = [
    { numero: 1, nombre: 'Terminos y condiciones' },
    { numero: 2, nombre: 'Verificación de identidad' },
    { numero: 3, nombre: 'Verificación de identidad' },
    { numero: 4, nombre: 'Verificación de identidad' },
    { numero: 5, nombre: 'Verificación de identidad' },
    { numero: 6, nombre: 'Datos Personales' },
    { numero: 7, nombre: 'Datos Personales' },
    { numero: 8, nombre: 'Datos de tu Negocio' },
    { numero: 9, nombre: 'Datos de tu Negocio' },
  ];

  /**
   * Constructor for the class.
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private loaderService: LoaderService,
    private parametersService: ParemetersService,
    private router: Router,
    private authService: AuthService,
    private navService: NavService,
    public StepService: StepService,
    private route: ActivatedRoute,
    private modalService: ModalServiceAlert
  ) {
    this.flagSendCodeOpt = false;
    this.initFormRegister();
    this.updateStorage();
    const storedStep = localStorage.getItem('step');
    this.step = storedStep ? parseInt(storedStep) : 1;
  }

  /**
   * Obtiene el paso actual del servicio StepService.
   *
   * @returns {number} El paso actual.
   * @see StepService#getStep
   */
  get stepNew() {
    return this.StepService.getStep();
  }

  /**
   * Incrementa el paso actual utilizando el servicio StepService.
   * Muestra en la consola el valor del paso actualizado.
   */
  incrementar() {
    this.StepService.incrementar();
    console.log(this.stepNew, 'EN INCREMENTAR');
  }

  /**
   * Decrementa el paso actual utilizando el servicio StepService.
   */
  decrementar() {
    this.StepService.decrementar();
  }

  /**
   * Alterna el estado de visualización del modal de términos y condiciones.
   * También muestra en consola el estado actual del modal y el paso actual.
   */
  toggleModal() {
    this.modalTermsAndConditions = !this.modalTermsAndConditions;
    console.log(this.modalTermsAndConditions);
    console.log(this.step);
  }

  /**
   * Obtiene el nombre de la sección actual basado en el paso en el que se encuentra el usuario.
   *
   * @returns {string} El nombre de la sección actual. Si el paso no se encuentra en el array 'pasos',
   * devuelve 'Nombre de sección no encontrado'.
   */
  getNombreSeccionActual() {
    const seccion = this.pasos.find((paso) => paso.numero === this.step);
    return seccion ? seccion.nombre : 'Nombre de sección no encontrado';
  }

  /**
   * Cambia el paso actual al valor proporcionado y almacena el nuevo paso en el almacenamiento local.
   *
   * @param {number} nuevoStep - El número del paso al que se debe cambiar.
   *
   */
  cambiarStep(nuevoStep: number): void {
    console.log(this.step);
    localStorage.setItem('step', nuevoStep.toString());
  }

  /**
   * Actualiza la propiedad `phoneStorage` utilizando el valor almacenado en `localStorage` bajo la clave 'phone_1'.
   * Si el valor no está presente o es nulo en `localStorage`, se asigna un valor predeterminado a `phoneStorage`.
   *
   * @returns {void}
   */
  updateStorage() {
    const phoneStorageString = localStorage.getItem('phone_1'); // Obtiene el valor como cadena desde localStorage
    if (phoneStorageString !== null) {
      this.phoneStorage = parseInt(phoneStorageString, 10); // Convierte la cadena en un número
    } else {
      // Maneja el caso en el que el valor en localStorage sea nulo
      // Puedes asignar un valor predeterminado o manejarlo de otra manera según tus necesidades
      this.phoneStorage = 0; // O cualquier otro valor predeterminado
    }
  }

  /**
   * Función para inicializar el formulario de registro del aliado.
   * Utiliza la biblioteca de Reactive Forms de Angular para crear un conjunto
   * de controles de formulario con validaciones específicas.
   *
   * @returns {void} No retorna ningún valor; su objetivo es inicializar y configurar `this.allyForm`
   */
  initFormRegister() {
    // Inicializa un nuevo formulario reactivo utilizando FormBuilder
    this.allyForm = this.formBuilder.group({
      // Define los campos del formulario y asigna validadores
      code_shop: ['', [Validators.required, Validators.minLength(3)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      identification_type: ['', Validators.required],
      identification: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      phone_1: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      phone_1_valido: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      // se elimina temporalmente
      // phone_2: [
      //   '',
      //   [
      //       phone2Validator,
      //   ],
      // ],
      phone_2: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          onlyNumbers(),
        ],
      ],
      address: ['', [Validators.required, Validators.minLength(5)]],
      nit: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(11),
          numericValidator(),
        ],
      ],
      value_request: ['', [Validators.required, Validators.minLength(4)]],
      //   user_id_commerce: [''],
      business_name: ['', [Validators.required, Validators.minLength(3)]],
      city_id: ['', Validators.required],
      businness_address: ['', [Validators.required, Validators.minLength(5)]],
      registered_chamber_commerce: [
        '',
        [Validators.required, Validators.minLength(1)],
      ],
      date_of_birth: ['', [Validators.required]],
      emission_date: ['', [Validators.required]],
      photo_document_front: ['', Validators.required],
      photo_document_back: ['', Validators.required],
      photo_selfie: ['', Validators.required],
      customer_business_types_id: ['', Validators.required],
      comunication_all: [true, ''],
      comunication_email: [true, ''],
      comunication_message: [false, ''],
      authorization_treatment_data: [false, Validators.required],
    });
  }

  /**
   * Método ngOnInit que se ejecuta al inicializar el componente.
   * No devuelve ningún valor (void).
   */
  ngOnInit(): void {
    // this.getCodeParamProveedor();
    this.code = this.route.snapshot.paramMap.get('code');
    // this.cambiarStep(this.step);
    // Llama a la función getCities para obtener una lista de ciudades
    this.getCities();

    // Llama a la función getBusinessTypes para obtener los tipos de negocios
    this.getBusinessTypes();

    //this.getInfoMetamap();

    // Línea comentada: si se descomenta, se ejecutará el método requestQuotaApproval.
    // Útil si solo se desea probar la aprobación de cupo de crédito sin ejecutar el resto del código.
    //this.requestQuotaApproval();
  }

  // getCodeParamProveedor() {
  //   this.code = this.route.snapshot.paramMap.get('code');
  //   this.allyForm.get('code_shop').setValue(this.code);
  // }

  /**
   * Se ejecuta cada vez que el usuario escribe en el campo 'password'.
   * Llama a la función 'removeNonNumeric' para eliminar cualquier carácter que no sea un número.
   *
   * @returns {void} No devuelve ningún valor.
   */
  onPasswordInput() {
    // Obtiene el control de formulario correspondiente al campo 'password'.
    const control = this.allyForm.get('password');

    // Llama a la función de ayuda 'removeNonNumeric' para eliminar caracteres no numéricos.
    // Verifica si el control es null antes de proceder
    if (control !== null) {
      // Llama a la función de ayuda 'removeNonNumeric' para eliminar caracteres no numéricos.
      removeNonNumeric(control);
    }
  }

  /**
   * Formatea el valor de entrada de un campo de formulario a un formato de moneda.
   * @param event - Evento que dispara la función, generalmente un evento de teclado o cambio.
   */
  formatInputToCurrency(event: any) {
    // Extrae el valor del campo de formulario del objeto de evento
    let inputValue = event.target.value;

    // Elimina todos los caracteres que no sean dígitos numéricos
    let numericValue = inputValue.replace(/[^0-9]/g, '');

    // Formatea el número con comas como separadores de miles
    let formattedValue = this.formatNumberWithCommas(numericValue);

    // Establece el valor del control "value_request" en el formulario "allyForm"
    // y previene la emisión de eventos para evitar cualquier bucle infinito de cambios
    this.allyForm.controls['value_request'].setValue(formattedValue, {
      emitEvent: false,
    });
  }

  /**
   * Formatea un número con comas como separadores de miles.
   * @param x - Objeto que tiene una función toString que devuelve una representación de cadena del número.
   * @returns Una cadena que representa el número formateado con comas como separadores de miles.
   */
  formatNumberWithCommas(x: { toString: () => string }) {
    // Convierte el número a una cadena y luego utiliza una expresión regular
    // para insertar comas como separadores de miles.
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Maneja el avance al siguiente paso en el formulario y realiza validaciones y acciones específicas.
   * @returns {void} No hay valor de retorno explícito.
   */
  nextStep() {
    toggleLoader(this.loaderService, false);
    const STEPS = {
      PHOTO_FRONT: 5,
      PHOTO_BACK: 6,
      PHOTO_SELFIE: 7,
      CODE_PROVIDER: 8,
      NAME_LASTNAME: 9,
      CEDULA: 10,
      FECHAS: 11,
      EMAIL: 12,
      ADDRESS: 13,
      PHONE_VALIDATION: 14,
      NIT_VALIDATION: 16,
      BUSINESS_DETAILS: 17,
      VALUE_REQUEST: 18,
      CITY_BUSINESS_ADDRESS: 19,
    };

    switch (this.stepNew) {
      case STEPS.PHOTO_FRONT:
        this.handlePhotoFrontStep();
        break;
      case STEPS.PHOTO_BACK:
        this.handlePhotoBackStep();
        break;
      case STEPS.PHOTO_SELFIE:
        this.handlePhotoSelfieStep();
        break;
      case STEPS.CODE_PROVIDER:
        this.handleCodeProviderStep();
        break;
      case STEPS.NAME_LASTNAME:
        this.handleNameLastnameStep();
        break;
      case STEPS.CEDULA:
        this.handleCedulaStep();
        break;
      case STEPS.FECHAS:
        this.handleFechas();
        break;
      case STEPS.EMAIL:
        this.handleEmailStep();
        break;
      case STEPS.ADDRESS:
        this.handleAddressStep();
        break;
      case STEPS.PHONE_VALIDATION:
        this.handlePhoneValidationStep();
        break;
      case STEPS.NIT_VALIDATION:
        this.handleNitValidationStep();
        break;
      case STEPS.BUSINESS_DETAILS:
        this.handleBusinessDetailsStep();
        break;
      case STEPS.VALUE_REQUEST:
        this.handleValueRequestStep();
        break;
      case STEPS.CITY_BUSINESS_ADDRESS:
        this.handleCityBusinessAddressStep();
        break;
      default:
        console.warn('Paso no reconocido:', this.stepNew);
        break;
    }
  }

  /**
   * Gestiona el paso relacionado con la foto frontal del documento.
   * Valida el campo 'photo_document_front' y, si es válido, genera un token para Metamap.
   * @returns {void}
   */
  handlePhotoFrontStep() {
    if (this.validateAndToggleLoader(['photo_document_front'])) {
      this.generarTokenMetamap();
    }
  }

  /**
   * Gestiona el paso relacionado con la foto trasera del documento.
   * Valida el campo 'photo_document_back' y, si es válido, procede con la validación de la foto de reverso.
   *
   * @returns {void}
   */
  handlePhotoBackStep() {
    if (this.validateAndToggleLoader(['photo_document_back'])) {
      this.validatePhotoReverso();
    }
  }

  /**
   * Gestiona el paso relacionado con la foto tipo selfie del usuario.
   * Valida el campo 'photo_selfie' y, si es válido, procede con la validación de la foto selfie.
   *
   * @returns {void}
   */
  handlePhotoSelfieStep() {
    if (this.validateAndToggleLoader(['photo_selfie'])) {
      this.validatePhotoSelfie();
    }
  }

  /**
   * Gestiona el paso relacionado con la verificación del código de proveedor.
   * Muestra un loader con el mensaje 'Verificando código de proveedor' y luego procede a verificar si el código de proveedor existe.
   */
  handleCodeProviderStep() {
    toggleLoader(this.loaderService, true, 'Verificando código de proveedor');
    this.verifyCodigoProveedorExist();
  }

  /**
   * Gestiona el paso relacionado con la validación de los campos de nombre y apellido.
   * Valida si los campos "name" y "last_name" son válidos y, en caso afirmativo, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleNameLastnameStep(): void {
    if (this.validateAndToggleLoader(['name', 'last_name'], false)) {
      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la validación de los campos de cédula.
   * Activa el componente de carga y llama a la función "verifyCedulaExist" para verificar la existencia de la cédula.
   *
   * @returns {void}
   */
  handleCedulaStep(): void {
    if (
      this.validateAndToggleLoader(
        ['identification_type', 'identification'],
        false
      )
    ) {
      // this.verifyCedulaExist();
      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la validacion de la fecha de nacimiento y fecha de expedicion de la cédula.
   * Activa el componente de carga y llama a la función "verifyCedulaExist" para verificar la existencia de la cédula.
   *
   * @returns {void}
   */
  handleFechas(): void {
    if (
      this.validateAndToggleLoader(['date_of_birth', 'emission_date'], false)
    ) {
      // this.verifyCedulaExist();
      console.log(this.allyForm.controls['date_of_birth'].value);
      console.log(this.allyForm.controls['emission_date'].value);

      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la validación de los campos de correo electrónico y contraseña.
   * Activa el componente de carga y, si los campos son válidos, llama a la función "verifyEmailExist" para verificar la existencia del correo electrónico.
   */
  handleEmailStep(): void {
    if (this.validateAndToggleLoader(['email', 'password'])) {
      if (!this.existCustomer) {
        this.verifyEmailExist();
      } else {
        this.incrementar();
        toggleLoader(this.loaderService, false);
      }
    }
  }

  /**
   * Gestiona el paso relacionado con la validación del campo de dirección.
   * Activa el componente de carga y, si el campo es válido, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleAddressStep(): void {
    if (this.validateAndToggleLoader(['address'], false)) {
      this.updateStorage();
      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la validación de los campos de teléfono.
   * Activa el componente de carga y, si los campos son válidos, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handlePhoneValidationStep(): void {
    if (this.validateAndToggleLoader(['phone_2'], false)) {
      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la validación del campo NIT.
   * Activa el componente de carga y, si el campo es válido, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleNitValidationStep(): void {
    if (this.validateAndToggleLoader(['nit'], false)) {
      if (!this.existCustomer) {
        this.verifyNitExist();
      } else {
        this.incrementar();
        toggleLoader(this.loaderService, false);
      }
    }
  }

  /**
   * Gestiona el paso relacionado con los detalles del negocio.
   * Activa el componente de carga y, si los campos son válidos, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleBusinessDetailsStep(): void {
    if (
      this.validateAndToggleLoader(
        [
          'business_name',
          'customer_business_types_id',
          'registered_chamber_commerce',
        ],
        false
      )
    ) {
      if (!this.existCustomer) {
        this.incrementar();
      } else {
        // incrementa 2 veces para saltar el paso el valor del cupo
        this.incrementar();
        this.incrementar();
      }
    }
  }

  /**
   * Gestiona el paso relacionado con la solicitud de valor.
   * Activa el componente de carga y, si el campo es válido, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleValueRequestStep(): void {
    // Usando validateAndToggleLoader directamente
    if (this.validateAndToggleLoader(['value_request'], false)) {
      this.incrementar();
    }
  }

  /**
   * Gestiona el paso relacionado con la ciudad y la dirección comercial.
   * Activa el componente de carga y, si los campos son válidos, avanza al siguiente paso.
   *
   * @returns {void}
   */
  handleCityBusinessAddressStep(): void {
    // Usando validateAndToggleLoader directamente
    if (this.validateAndToggleLoader(['city_id', 'businness_address'], false)) {
      this.incrementar();
    }
  }

  /**
   * Valida los campos del formulario y controla la visibilidad del componente de carga.
   *
   * @param {string[]} fields - Los campos a validar.
   * @returns {boolean} - True si todos los campos son válidos, de lo contrario, false.
   */
  validateAndToggleLoader(
    fields: string[],
    showLoader: boolean = true
  ): boolean {
    let isValid = true;
    fields.forEach((field) => {
      const control = this.allyForm.controls[field];
      if (control.invalid) {
        this.markFieldsSelectedAsTouched([field]);
        isValid = false;
      }
    });

    if (!isValid) {
      this.modalService.openModal(
        'Error',
        'Verifica la información ingresada',
        'error'
      );
      toggleLoader(this.loaderService, false);
    } else {
      toggleLoader(this.loaderService, showLoader);
    }
    return isValid;
  }

  /**
   * Retrocede al paso anterior en el formulario, si es posible.
   * @returns {void} No hay valor de retorno explícito.
   */
  prevStep() {
    // Mostrar el número de paso actual en la consola
    console.log(this.step);

    // Verificar si el paso actual es 1; si es así, no se puede retroceder más
    if (this.step == 1) {
      return; // No se realiza ninguna acción si el paso actual es 1
    }

    // Retroceder un paso si el paso actual no es 1
    this.step--;
  }

  /**
   * Valida y envía un código OTP al número de celular proporcionado en el formulario.
   * Realiza acciones según la validez del número de celular.
   * @returns {void} No hay valor de retorno explícito.
   */
  validarCelular() {
    // Obtener el control del número de celular del formulario
    const phone_1 = this.allyForm.controls['phone_1'];

    // Validar si el número de celular es inválido
    if (phone_1.invalid) {
      // Marcar el campo como "touched" para mostrar errores y mensaje de error
      this.markFieldsSelectedAsTouched(['phone_1']);
      // Mostrar un mensaje de error al usuario
      this.modalService.openModal(
        'Error',
        'Verifica la información ingresada',
        'error'
      );
      return; // Salir de la función si el número es inválido
    } else if (phone_1.valid) {
      /*
       * Enviar un código OTP al número de celular
       */
      this.sendCodeOtpCustomer(phone_1.value);
    }
  }

  /**
   * Obtiene y asigna las ciudades utilizando el servicio de parámetros.
   * Realiza una solicitud y actualiza la lista de ciudades.
   * @returns {void} No hay valor de retorno explícito.
   */
  getCities(): void {
    // Utilizar el servicio de parámetros para obtener las ciudades
    this.parametersService.getCities().subscribe((data: any) => {
      // Asignar la lista de ciudades desde los datos recibidos
      this.cities = data.data;
    });
  }

  /**
   * Obtiene y asigna los tipos de negocio utilizando el servicio de parámetros.
   * Realiza una solicitud y actualiza la lista de tipos de negocio.
   * @returns {void} No hay valor de retorno explícito.
   */
  getBusinessTypes(): void {
    // Utilizar el servicio de parámetros para obtener los tipos de negocio
    this.parametersService.getBusinessTypes().subscribe((data: any) => {
      // Mostrar los datos en la consola (opcional)
      // Asignar la lista de tipos de negocio desde los datos recibidos
      this.businessTypes = data.data;
    });
  }

  /**
   * Verifica la existencia de una cédula utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyCedulaExist(): void {
    toggleLoader(this.loaderService, true);
    const cedula = this.allyForm.controls['identification'].value;
    const cedulaExiste = `La cedula: ${cedula} ya se encuentra en nuestros registros`;
    this.registerService.verifyCedula(cedula).subscribe(
      (response: ResponseSearch) => {
        toggleLoader(this.loaderService, false);
        if (!response.ok) {
          this.incrementar(); // Avanzar al siguiente paso si la cédula no se encontró
        } else {
          this.modalService.openModal(
            'Error',
            response.message || cedulaExiste,
            'error'
          );
        }
      },
      (error) => {
        if (!error.error.ok) {
          this.incrementar();
        } else {
          this.modalService.openModal(
            'Error',
            error.error.message || cedulaExiste,
            'error'
          );
        }
        toggleLoader(this.loaderService, false);
      }
    );
  }

  /**
   * Verifica la existencia de un código de proveedor utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyCodigoProveedorExist(): void {
    const code_shop = this.allyForm.controls['code_shop'];
    console.log(code_shop);
    if (!code_shop.valid) {
      this.showErrorAndToggleLoader(
        ['code_shop'],
        'El código del proveedor no puede estar vacío.'
      );
      return;
    }
    const proveedorNoExiste = `El código del proveedor: ${code_shop.value} no se encuentra registrado`;
    this.registerService.verifyCodeProvider(code_shop.value).subscribe(
      (response: ResponseSearch) => {
        if (response.ok) {
          console.log(response.data, 'Resultado encontrado');
          this.getInfoMetamap();
        } else {
          this.showErrorAndToggleLoader(
            ['code_shop'],
            response.message || proveedorNoExiste
          );
        }
      },
      (error) => {
        this.showErrorAndToggleLoader(
          ['code_shop'],
          error.error.message || proveedorNoExiste
        );
        console.error('Error en la consulta de cédula:', error);
      }
    );
  }

  /**
   * Muestra un mensaje de error y controla la visibilidad del componente de carga, además de marcar
   * los campos especificados como "touched".
   *
   * @param {string[]} fields - Los campos a marcar como "touched".
   * @param {string} errorMessage - El mensaje de error a mostrar.
   */
  showErrorAndToggleLoader(fields: string[], errorMessage: string) {
    fields.forEach((field) => {
      this.allyForm.controls[field].markAsTouched();
    });

    toggleLoader(this.loaderService, false);
    this.modalService.openModal('Error', errorMessage, 'error');
  }

  /**
   * Verifica la existencia de un email utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyEmailExist(): void {
    const email = this.allyForm.controls['email'].value;
    const emailExiste = `El email: ${email} ya se encuentra registrado`;
    this.registerService.verifyEmail(email).subscribe(
      (response: ResponseSearch) => {
        toggleLoader(
          this.loaderService,
          false,
          'Cuando tu cupo se active, podrás acceder a zíro para ver tu estado de cuenta'
        );
        if (response.ok) {
          this.modalService.openModal(
            'Error',
            response.message || emailExiste,
            'error'
          );
        } else {
          this.incrementar(); // Avanzar al siguiente paso si el email no se encontró
        }
      },
      (error) => {
        //si el email no existe se avanza
        if (!error.error.ok) {
          this.incrementar();
        } else {
          this.modalService.openModal(
            'Error',
            error.error.message || emailExiste,
            'error'
          );
        }
        toggleLoader(this.loaderService, false);
      }
    );
  }

  /**
   * Verifica la existencia de un email utilizando el servicio de registro.
   * Realiza validaciones y una solicitud al servicio, gestionando posibles respuestas y errores.
   * @returns {void} No hay valor de retorno explícito.
   */
  verifyNitExist(): void {
    const nit = this.allyForm.controls['nit'].value;
    const nitExiste = `El nit: ${nit} ya se encuentra registrado`;
    this.registerService.verifyNit(nit).subscribe(
      (response: ResponseSearch) => {
        toggleLoader(
          this.loaderService,
          false,
          'Cuando tu cupo se active, podrás acceder a zíro para ver tu estado de cuenta'
        );
        if (response.ok) {
          this.modalService.openModal(
            'Error',
            response.message || nitExiste,
            'error'
          );
        } else {
          this.incrementar(); // Avanzar al siguiente paso si el email no se encontró
        }
      },
      (error) => {
        //si el email no existe se avanza
        if (!error.error.ok) {
          this.incrementar();
        } else {
          this.modalService.openModal(
            'Error',
            error.error.message || nitExiste,
            'error'
          );
        }
        toggleLoader(this.loaderService, false);
      }
    );
  }

  /**
   * Marca todos los campos del formulario como "touched".
   * Itera a través de los controles del formulario y los marca como "touched".
   * @returns {void} No hay valor de retorno explícito.
   */
  markAllFieldsAsTouched() {
    // Iterar a través de los nombres de los controles en el formulario
    Object.keys(this.allyForm.controls).forEach((controlName) => {
      // Marcar cada control como "touched"
      this.allyForm.controls[controlName].markAsTouched();
    });
  }

  /**
   * Marca los campos seleccionados del formulario como "touched".
   * Itera a través de los nombres de los campos proporcionados y los marca como "touched".
   * @param {string[]} fields - Lista de nombres de campos a marcar como "touched".
   * @returns {void} No hay valor de retorno explícito.
   */
  markFieldsSelectedAsTouched(fields: string[]) {
    fields.forEach((fieldName) => {
      // Obtener el control correspondiente al nombre del campo
      const control = this.allyForm.get(fieldName);
      if (control) {
        // Marcar el control como "touched"
        control.markAsTouched();
      }
    });
  }

  /** *********************************************************
   * PROCESO CODE OTP TERMINOS Y CONDICIONES
   *************************************************************/

  /**
   * Envía un código OTP al número de celular proporcionado utilizando el servicio de registro.
   * Muestra un mensaje de carga durante la solicitud y maneja posibles respuestas y errores.
   * @param {number} $celular - Número de celular al que se enviará el código OTP.
   * @returns {void} No hay valor de retorno explícito.
   */
  sendCodeOtpCustomer($celular: number) {
    // Mostrar mensaje de carga durante el envío del código OTP
    toggleLoader(
      this.loaderService,
      true,
      'Compra ahora tu inventario y paga después'
    );

    // Mensaje de error predeterminado
    const message = 'Ha ocurrido un error al enviar el código';

    // Realizar solicitud para enviar el código OTP al celular
    this.registerService.sendCodeOtp($celular).subscribe(
      (data: any) => {
        // Ocultar el mensaje de carga
        toggleLoader(this.loaderService, false);

        if (!data.ok) {
          // Mostrar mensaje de error si la respuesta no es exitosa
          this.modalService.openModal(
            'Error',
            data.message ? data.message : message,
            'error'
          );
        } else {
          // Mostrar modal con el código OTP si la respuesta es exitosa
          this.showModalCodeOtp();
        }
      },
      (error: any) => {
        // Ocultar el mensaje de carga
        toggleLoader(this.loaderService, false);

        // Mostrar mensaje de error en caso de error en la solicitud
        console.error(error);
        console.log('Algo paso');
        this.modalService.openModal(
          'Error',
          error.error.message ? error.error.message : message,
          'error'
        );
      }
    );
  }

  /**
   * Muestra un modal interactivo para ingresar el código OTP y confirmar los términos y condiciones.
   * El usuario puede ingresar el código OTP y confirmar su acuerdo con los términos y condiciones.
   * @returns {void} No hay valor de retorno explícito.
   */
  showModalCodeOtp() {
    Swal.fire({
      // title: "¿Estás de acuerdo con los términos y condiciones?",
      html:
        'Al ingresar el código confirmas  que eres el propietario de esta línea y estás de acuerdo con los ' +
        '<br>' +
        " <a target='_blank' href='https://somosziro.com/terminos-y-condiciones/' rel='noopener noreferrer' style='color: blue; text-decoration: underline;'>Términos y Condiciones</a>: " +
        '<br><br>' +
        "<input type='number' class='form-control' id='codeOtp' placeholder='Ingresa el código' value='' formControlName='codeOtp' required/>",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Acepto Términos y Condiciones',
      preConfirm: () => {
        // Obtener el código OTP ingresado por el usuario
        const codigoOtpDigitado = (<HTMLInputElement>(
          document.getElementById('codeOtp')
        )).value;
        if (codigoOtpDigitado === '') {
          Swal.showValidationMessage(
            'Por favor, ingresa el código que enviamos a tu WhatsApp'
          );
        } else {
          // Validar el código OTP ingresado por el usuario
          this.validateCodeOtpCustomer(Number(codigoOtpDigitado));
        }
      },
    });
  }

  /**
   * valida el codigo OTP enviado al celular
   * @param $code
   */
  validateCodeOtpCustomer($code: number) {
    const self = this; // Almacenamos el contexto actual en una variable
    toggleLoader(
      this.loaderService,
      true,
      'Nos aliamos con tu proveedor para ofrecerte un nuevo método de pago'
    );
    const phone_1 = this.allyForm.controls['phone_1'].value;
    localStorage.setItem('phone_1', phone_1);
    console.log($code);
    console.log(phone_1);
    const data = {
      phone: phone_1,
      code: $code,
    };
    const message = '¡Ha ocurrido un error al validar el código enviado!';
    this.registerService.validateCodeOtpCustomerService(data).subscribe(
      (data: any) => {
        toggleLoader(this.loaderService, false);
        if (!data.ok) {
          self.showModalCodeOtp(); // Usamos self en lugar de this
          this.modalService.openModal(
            'Error',
            data.message ? data.message : message,
            'error'
          );
        } else {
          console.log(data);
          this.modalTermsAndConditions = false;
          this.cambiarStep(2);
          this.incrementar();
        }
      },
      (error: any) => {
        toggleLoader(this.loaderService, false);
        Swal.fire({
          title: 'Error',
          text: error.error.message ? error.error.message : message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.showModalCodeOtp();
          }
        });
      }
    );
  }

  /** *********************************************************
   * PROCESO VALIDACIÓN METAMAP
   *************************************************************/

  /**
   * Maneja la selección de un archivo de imagen y realiza acciones en función del tipo de foto.
   * @param event - El evento de selección de archivo que desencadenó la función.
   * @param tipoFoto - El tipo de foto seleccionado ("frontal", "trasera" o "selfie").
   */
  onFileSelected(event: any, tipoFoto: string) {
    const file = event.target.files[0];
    // Ahora puedes hacer algo con el archivo, como convertirlo en una URL de objeto y mostrarlo en la página
    if (event.target && event.target.files && event.target.files.length > 0) {
      const fileUrl = URL.createObjectURL(file);
      // ...Haz algo con fileUrl, como mostrarlo en la página
      console.log(fileUrl);
      // Crear el elemento de imagen y asignarle las propiedades
      let imageElement = document.createElement('img');
      imageElement.src = fileUrl;
      imageElement.style.width = 'auto'; // ajusta a la anchura deseada
      imageElement.style.height = '300px'; // ajusta a la altura deseada

      // ...Haz algo con imageElement, como agregarlo a la página

      if (tipoFoto == 'frontal') {
        this.imagenFrontalVisible = true;
        this.documentFrontImage = fileUrl;
        this.selectedFiles['photo_document_front'] = file;
        const formControl = this.allyForm.get('photo_document_front');
        if (formControl) {
          formControl.setValue(file);
          //this.formData.append('photo_document_front', file, file.name);
        } else {
          console.error('Form control photo_document_front not found');
        }
      }
      if (tipoFoto == 'trasera') {
        this.imagenTraseraVisible = true;
        this.documentBackImage = fileUrl;
        this.selectedFiles['photo_document_back'] = file;
        const formControlBack = this.allyForm.get('photo_document_back');
        if (formControlBack) {
          formControlBack.setValue(file);
          //this.formData.append('photo_document_back', file, file.name);
        } else {
          console.error('Form control photo_document_back not found');
        }
      }
      if (tipoFoto == 'selfie') {
        this.imagenSelfieVisible = true;
        this.selfieImage = fileUrl;
        this.selectedFiles['photo_selfie'] = file;
        const formControlSelfie = this.allyForm.get('photo_selfie');
        if (formControlSelfie) {
          formControlSelfie.setValue(file);
          //this.formData.append('photo_selfie', file, file.name);
        } else {
          console.error('Form control photo_selfie not found');
        }
      }
    }
  }

  /**
   * Genera un token de acceso de Metamap y realiza la verificación del documento.
   * Se obtiene un token de acceso de Metamap y luego se crea una verificación del documento.
   * Se prepara y envía el documento a Metamap para su validación.
   * @returns {void} No hay valor de retorno explícito.
   */
  generarTokenMetamap() {
    // Obtener un token de acceso de Metamap
    this.registerService.generarToken().subscribe((data: any) => {
      console.log(data);

      // Crear una verificación del documento con el token de acceso obtenido
      this.registerService
        .crearVerificacion(data.access_token)
        .subscribe((dataCrearVerification: any) => {
          console.log(dataCrearVerification);

          // Preparar los documentos para la verificación
          const formDocuments = new FormData();
          const formControlPhoto_document_front = this.allyForm.get(
            'photo_document_front'
          );

          if (formControlPhoto_document_front) {
            // Agregar información del documento a los datos de verificación
            formDocuments.append(
              'inputs',
              '[ \n    {"inputType":"document-photo","group":0, \n        "data":{\n           "type":"national-id",\n           "country":"CO",\n           "region":"",\n           "page":"front",\n           "filename": "' +
                formControlPhoto_document_front.value.name +
                '"\n           }\n        }   ]'
            );

            // Agregar el documento al formulario de verificación
            formDocuments.append(
              'document',
              formControlPhoto_document_front.value
            );
          } else {
            console.error('Form control photo_document_front not found');
          }

          // Almacenar información relevante para el proceso de validación
          this.tokenMetamap = data.access_token;
          this.idMetamap = dataCrearVerification.id;
          this.idetifyMetamap = dataCrearVerification.identity;

          // Mostrar mensaje de carga durante la validación del documento
          toggleLoader(
            this.loaderService,
            true,
            'Por favor espera, estamos validando tu documento'
          );

          // Enviar los documentos para su validación a Metamap
          this.validateSendPhoto(
            dataCrearVerification.identity,
            formDocuments,
            data.access_token,
            1
          );
        });
    });
  }

  /**
   * Valida la foto del reverso del documento de identidad comparándola en Metamap.
   * Prepara y envía la foto del reverso del documento de identidad a Metamap para su validación.
   * @returns {void} No hay valor de retorno explícito.
   */
  validatePhotoReverso() {
    // Preparar los documentos para la validación del reverso del documento
    const formDocuments = new FormData();
    const formControlPhoto_document_back = this.allyForm.get(
      'photo_document_back'
    );

    if (formControlPhoto_document_back) {
      // Agregar información del reverso del documento a los datos de validación
      formDocuments.append(
        'inputs',
        '[ \n    {"inputType":"document-photo","group":0, \n        "data":{\n           "type":"national-id",\n           "country":"CO",\n           "region":"",\n           "page":"back",\n           "filename": "' +
          formControlPhoto_document_back.value.name +
          '"\n           }\n        }   ]'
      );

      // Agregar el documento del reverso del documento al formulario de validación
      formDocuments.append('document', formControlPhoto_document_back.value);
    } else {
      console.error('Form control photo_document_front not found');
    }

    // Mostrar mensaje de carga durante la validación del reverso del documento
    toggleLoader(
      this.loaderService,
      true,
      'Por favor espera, estamos validando tu documento'
    );

    // Enviar los documentos para su validación a Metamap
    this.validateSendPhoto(
      this.idetifyMetamap,
      formDocuments,
      this.tokenMetamap,
      2
    );
  }

  /**
   * Valida la foto selfie del usuario comparándola con el documento de identidad en Metamap.
   * Prepara y envía la foto selfie del usuario a Metamap para su comparación con el documento de identidad.
   * @returns {void} No hay valor de retorno explícito.
   */
  validatePhotoSelfie() {
    // Preparar los documentos para la validación de selfie
    const formDocuments = new FormData();
    const formControlPhoto_selfie = this.allyForm.get('photo_selfie');

    if (formControlPhoto_selfie) {
      // Agregar información de la foto selfie a los datos de validación
      formDocuments.append(
        'inputs',
        '[ \n    {"inputType":"selfie-photo",\n    "data":{\n "type": "selfie-photo",\n "filename": "' +
          formControlPhoto_selfie.value.name +
          '"\n           }\n        }   ]'
      );

      // Agregar la foto selfie al formulario de validación
      formDocuments.append('document', formControlPhoto_selfie.value);
    } else {
      console.error('Form control photo_document_front not found');
    }

    // Mostrar mensaje de carga durante la comparación de la foto selfie con el documento
    toggleLoader(
      this.loaderService,
      true,
      'Por favor espera, estamos validando tu identidad'
    );

    // Enviar los documentos para su comparación a Metamap
    this.validateSendPhoto(
      this.idetifyMetamap,
      formDocuments,
      this.tokenMetamap,
      3
    );
  }

  /**
   * Valida y envía los documentos a Metamap para su comparación y validación.
   * @param {string} identity - Identificador único asociado con la identidad.
   * @param {any} data - Datos y documentos a enviar para la validación.
   * @param {any} token - Token de acceso para la autenticación con Metamap.
   * @param {number} pasosFotos - Número que representa los pasos de validación de fotos.
   */
  validateSendPhoto(
    identity: string,
    data: any,
    token: any,
    pasosFotos: number
  ) {
    // Enviar los datos y documentos a Metamap para validación
    this.registerService
      .sendRegisterMetamapSendInput(identity, data, token)
      .subscribe(
        (responseInputs: any) => {
          console.log(data);
          const arrayError = [];
          // Iterar a través de las respuestas de Metamap para manejar los errores
          for (let index = 0; index < responseInputs.length; index++) {
            console.log(index);
            if (responseInputs[index].error) {
              // Determinar el tipo de foto según el paso de validación
              const tipoFoto =
                pasosFotos === 1
                  ? 'Foto cédula frontal'
                  : pasosFotos === 2
                  ? 'Foto cédula reverso'
                  : 'Foto selfie';
              console.log('tipoFoto', tipoFoto);
              // Obtener y procesar el error específico de Metamap
              const obtenerErrorMetamap = this.procesarErroresMetamap(
                responseInputs[index].error.code,
                tipoFoto
              );
              arrayError.push(`${obtenerErrorMetamap}`);
            }
          }

          //después de procesar las respuestas
          toggleLoader(this.loaderService, false);

          // Mostrar mensajes de error si existen errores de validación
          if (arrayError.length > 0) {
            this.modalService.openModal('Error', arrayError, 'error');
          }

          // Avanzar al siguiente paso si la validación es exitosa
          if (pasosFotos === 1 || pasosFotos === 2) {
            if (responseInputs[0].result === true) {
              //this.incrementar();
              this.incrementar();
            }
          } else if (pasosFotos === 3) {
            if (responseInputs[0].result === true) {
              this.verificacionMetamap = true;
              //this.incrementar();
              this.incrementar();
            }
          }
        },
        (error) => {
          // Mostrar un mensaje de error si hay un problema con el servicio de Metamap
          toggleLoader(this.loaderService, false);

          this.modalService.openModal(
            'Error',
            'Ha ocurrido un error al consumir el servicio send input',
            'error',
            5000
          );
        }
      );
  }

  /**
   * Procesa los códigos de error de Metamap y devuelve mensajes de error legibles para el usuario.
   * @param {any} respuesta - Respuesta de error recibida de Metamap.
   * @param {string} tipoFoto - Tipo de foto asociado al error.
   * @returns {string} - Mensaje de error legible para el usuario.
   */
  procesarErroresMetamap($respuesta: any, tipoFoto: string) {
    switch ($respuesta) {
      case 'documentPhoto.badText':
        return tipoFoto + ': La validación del campo del documento falló';
      case 'documentPhoto.blurryText':
        return (
          tipoFoto +
          ': La foto del documento es demasiado borrosa, toma nuevamente la foto'
        );
      case 'documentPhoto.smallImageSize':
        return (
          tipoFoto +
          ': La resolución de la foto del documento es demasiado baja, toma nuevamente la foto'
        );
      case 'documentPhoto.unexpectedData':
        return (
          tipoFoto +
          ': inesperado en la lectura del documento, toma nuevamente la foto'
        );
      case 'documentPhoto.noText':
        return (
          tipoFoto +
          ': La foto del documento no tiene texto, toma nuevamente la foto'
        );
      case 'selfiePhoto.noFace':
        return tipoFoto + ': La foto no tiene rostro toma nuevamente la foto';
      case 'documentPhoto.noFace':
        return (
          tipoFoto +
          ': Toma nuevamente la foto y verifica que sea la parte frontal de tu documento'
        );
      case 'documentPhoto.grayscaleImage':
        return (
          tipoFoto +
          ': La foto del documento está en escala de grises, toma nuevamente la foto'
        );
      case 'documentPhoto.screenPhoto':
        return (
          tipoFoto +
          ': La foto del documento es una captura de pantalla. El usuario debe subir una foto diferente'
        );
      case 'documentPhoto.noDocument':
        return (
          tipoFoto +
          ': La foto del documento no coincide con una plantilla de documento conocida'
        );
      case 'documentPhoto.missingFields':
        return (
          tipoFoto +
          ': A la foto del documento le faltan algunos campos obligatorios'
        );
      case 'documentPhoto.wrongFormat':
        return (
          tipoFoto +
          ': Algunos campos obligatorios del documento utilizan un formato no válido'
        );
      case 'documentPhoto.noMrz':
        return (
          tipoFoto +
          ': La foto del documento no tiene una zona legible por máquina (MRZ, para los pasos de validación que la requieren)'
        );
      case 'documentPhoto.badMrz':
        return (
          tipoFoto +
          ': La foto del documento ha dañado la zona legible por máquina (MRZ, para pasos de validación que lo requieran)'
        );
      case 'documentPhoto.noPdf417':
        return (
          tipoFoto +
          ': La foto del documento no tiene código de barras PDF417 (para los pasos de validación que lo requieren)'
        );
      case 'documentPhoto.badPdf417':
        return (
          tipoFoto +
          ': La foto del documento tiene el código de barras PDF417 dañado (para los pasos de validación que lo requieren)'
        );
      case 'documentPhoto.typeMismatch':
        return (
          tipoFoto +
          ': El tipo de documento reclamado por el usuario y el tipo de documento detectado en la foto son diferentes'
        );
      case 'documentPhoto.countryMismatch':
        return (
          tipoFoto +
          ': El país del documento reclamado por el usuario y el país del documento detectado a partir de la foto del documento son diferentes'
        );
      default:
        // Mensaje de error predeterminado en caso de que el código de error no se reconozca
        return (
          tipoFoto +
          ': ha ocurrido un error con la foto, por favor intente tomarla de nuevo'
        );
    }
  }

  /**
   * Obtiene información del usuario desde Metamap y actualiza los campos del formulario si los datos son válidos.
   * @param {string} identity - Identificador único del usuario en Metamap.
   * @param {string} token - Token de acceso para autenticación en Metamap.
   */
  getInfoMetamap() {
    setTimeout(() => {
      // Consumir el servicio para obtener información del usuario en Metamap
      this.registerService
        .getinformationCustomerMetamap(this.idMetamap, this.tokenMetamap)
        .subscribe(
          (dataUserMetamap: any) => {
            console.log(dataUserMetamap);
            // incrementa el valor de reintentos de busqueda en metamap 3 segundos mas cada busqueda ej: 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34
            this.retriesSearchMetamap = this.retriesSearchMetamap + 4;

            if (dataUserMetamap.ok) {
              this.allyForm.patchValue(dataUserMetamap.customer);

              if (dataUserMetamap.existCustomer) {
                this.allyForm.controls['value_request'].disable();
                this.currenValueRequest =
                  dataUserMetamap.customer.value_request;
              }

              this.allyForm.controls['name'].disable();
              this.allyForm.controls['last_name'].disable();
              this.allyForm.controls['identification'].disable();

              this.allyForm.controls['phone_1_valido'].disable();

              const phone_1 = this.phoneStorage;

              this.currentName = dataUserMetamap.customer.name;
              this.currentLastName = dataUserMetamap.customer.last_name;
              this.currentIdentification =
                dataUserMetamap.customer.identification.replace(/\./g, '');
              this.currentPhone1Valido = String(phone_1);
              this.existCustomer = dataUserMetamap.existCustomer;
              this.incrementar();

              toggleLoader(this.loaderService, false);
            } else {
              if (dataUserMetamap.retry) {
                this.getInfoMetamap();
              } else {
                const msj = dataUserMetamap.message || 'Ha ocurrido un error';

                toggleLoader(this.loaderService, false);
                this.modalService.openModal('Error', msj, 'error', 5000);
              }
            }
          },
          (error) => {
            toggleLoader(this.loaderService, false);

            if (error.error.retry) {
              this.getInfoMetamap();
            } else {
              const msj = error.error.message || 'Ha ocurrido un error';

              this.modalService.openModal('Error', msj, 'error', 5000);
            }

            console.error('Error al obtener los datos de Metamap: ', error);
          }
        );

      // va incrementando el tiempo en que se consume el servicio de metamap
    }, this.retriesSearchMetamap * 1000);
  }

  /** *********************************************************
   * PROCESO APROBACIÓN DE CUPO
   *************************************************************/

  /**
   * Procesa el formulario de registro y maneja la lógica para guardar y aprobar registros.
   *
   * @returns {void}
   */
  register(): void {
    console.log(this.allyForm);
    // Verificar si el formulario es válido
    const {
      authorization_treatment_data,
      comunication_all,
      comunication_email,
      comunication_message,
    } = this.allyForm.value;

    if (!authorization_treatment_data) {
      this.modalService.openModal(
        'Alerta',
        'Debes autorizar a zíro a consultar tu información!',
        'warning'
      );

      return;
    }

    if (this.allyForm.valid) {
      // Mostrar el componente de carga mientras se valida la información
      toggleLoader(this.loaderService, true);
      console.log(this.allyForm.valid);
      // Crear un objeto FormData para enviar los datos
      const formData = new FormData();
      const formControlPhoto_document_front = this.allyForm.get(
        'photo_document_front'
      );
      const formControlPhoto_document_back = this.allyForm.get(
        'photo_document_back'
      );
      const formControlPhoto_selfie = this.allyForm.get('photo_selfie');
      if (formControlPhoto_document_front) {
        formData.append(
          'photo_document_front',
          formControlPhoto_document_front.value,
          formControlPhoto_document_front.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }
      if (formControlPhoto_document_back) {
        formData.append(
          'photo_document_back',
          formControlPhoto_document_back.value,
          formControlPhoto_document_back.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }

      if (formControlPhoto_selfie) {
        formData.append(
          'photo_selfie',
          formControlPhoto_selfie.value,
          formControlPhoto_selfie.value.name
        );
      } else {
        console.error('Form control photo_document_front not found');
      }
      formData.append('code_shop', this.allyForm.value.code_shop);
      formData.append('name', this.currentName);
      formData.append('last_name', this.currentLastName);
      formData.append('identification', this.currentIdentification);
      // formData.append('identification', this.allyForm.value.identification);
      formData.append('phone_1', this.allyForm.value.phone_1);
      formData.append('phone_2', this.allyForm.value.phone_2);
      formData.append('email', this.allyForm.value.email);
      formData.append('address', this.allyForm.value.address);
      formData.append('nit', this.allyForm.value.nit);
      formData.append(
        'registered_chamber_commerce',
        this.allyForm.value.registered_chamber_commerce
      );
      formData.append('business_name', this.allyForm.value.business_name);

      formData.append(
        'value_request',
        this.existCustomer
          ? this.currenValueRequest
          : this.allyForm.value.value_request.replace(/,/g, '')
      );

      formData.append('city_id', this.allyForm.value.city_id);
      formData.append(
        'businness_address',
        this.allyForm.value.businness_address
      );
      formData.append(
        'customer_business_types_id',
        this.allyForm.value.customer_business_types_id
      );
      formData.append('password', this.allyForm.value.password);
      //   formData.append('user_id_commerce', this.allyForm.value.user_id_commerce);
      formData.append(
        'identification_type',
        this.allyForm.value.identification_type
      );

      // Formatear fecha
      const emission_dateOriginal = new Date(
        this.allyForm.controls['emission_date'].value
      );
      const date_of_birthOriginal = new Date(
        this.allyForm.controls['emission_date'].value
      );
      const datePipe = new DatePipe('en-US');
      const emission_date_format = datePipe.transform(
        emission_dateOriginal,
        "yyyy-MM-dd'T'HH:mm:ss"
      );
      const date_of_birth_format = datePipe.transform(
        date_of_birthOriginal,
        "yyyy-MM-dd'T'HH:mm:ss"
      );

      if (emission_date_format && date_of_birth_format) {
        formData.append('emission_date', emission_date_format);
        formData.append('date_of_birth', date_of_birth_format);
      }

      // Información de comunicación y autorización de tratamiento de datos
      formData.append('comunication_all', this.allyForm.value.comunication_all);
      formData.append(
        'comunication_email',
        this.allyForm.value.comunication_email
      );
      formData.append(
        'comunication_message',
        this.allyForm.value.comunication_message
      );
      formData.append(
        'authorization_treatment_data',
        this.allyForm.value.authorization_treatment_data
      );

      const messageError = 'Ocurrió un error al guardar el registro.';
      // Realizar la solicitud para guardar el registro
      this.registerService.saveRegister(formData).subscribe(
        (response) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            this.incrementar();
            this.modalService.openModal(
              '¡Espera unos minutos mientras evaluamos tu solicitud!',
              'Pronto recibirás un enlace a tu WhatsApp y correo para completar tu registro y activar tu cupo.',
              'warning',
              600000,
              21
            );
          } else {
            this.modalService.openModal(
              'Error',
              response.message || messageError,
              'error'
            );
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);
          this.modalService.openModal(
            'Error',
            error.error.message || messageError,
            'error'
          );
          console.error(error);
        }
      );
    } else {
      console.log(this.allyForm.valid);
      // Marcar todos los campos como tocados
      this.markAllFieldsAsTouched();
      this.modalService.openModal(
        'Error',
        'Aún faltan campos por diligenciar.',
        'error'
      );
    }
  }

  /**
   * Realiza una solicitud de aprobación de cupo y maneja el proceso de registro exitoso.
   *
   * @returns {void}
   */
  requestQuotaApproval() {
    // Datos necesarios para la solicitud de aprobación de cupo
    const data = {
      identification: this.allyForm.controls['identification'].value,
      nit: this.allyForm.controls['nit'].value,
      document_type_id: this.allyForm.controls['identification_type'].value,
      businness_type_id:
        this.allyForm.controls['customer_business_types_id'].value,
    };
    // const data = {
    //   identification: 1000253230,
    //   nit: 800226646,
    //   document_type_id: 1,
    //   businness_type_id: 1,
    // };

    // Realizar la solicitud de aprobación de cupo
    const messageErrorQuota =
      'Ocurrió un error al solicitar la aprobación de cupo.';
    this.registerService.requestQuota(data).subscribe(
      (responseQuota: any) => {
        if (
          responseQuota.ok === true &&
          responseQuota.creditApproved === true
        ) {
          toggleLoader(this.loaderService, false);
          Swal.fire(
            'Felicitaciones!',
            responseQuota.message
              ? responseQuota.message
              : 'Felicitaciones! Se ha aprobado un cupo para ti',
            'success'
          ).then(() => {
            // Mostrar componente de carga y realizar inicio de sesión
            toggleLoader(
              this.loaderService,
              true,
              responseQuota.message
                ? responseQuota.message
                : 'Felicitaciones! Se ha aprobado un cupo para ti'
            );

            this.goLogin();
          });
        } else {
          toggleLoader(this.loaderService, false);
          // Manejo de error en la solicitud de cuota
          console.error(
            'Error en la solicitud de cuota:',
            responseQuota.message
          );

          this.modalService.openModal('Error', responseQuota.message, 'error');
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        // Manejo de errores en la solicitud de aprobación de cuota
        console.error('Error en la solicitud de cuota:', error);

        this.modalService.openModal(
          'Error',
          error.error.message ? error.error.message : messageErrorQuota,
          'error'
        );
      }
    );
  }

  /**
   * Realiza una solicitud de login luego del proceso de registro exitoso.
   *
   * @returns {void}
   */
  goLogin() {
    const email = this.allyForm.value.email;
    const password = this.allyForm.value.password;
    const messageErrorLogin =
      'Ocurrió un error al iniciar sesión. Inténtalo nuevamente.';
    // Iniciar sesión después del registro exitoso
    this.authService.login(email, password).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        console.log('AUTH LOGIN', response);
        if (response.ok) {
          this.authService.setUser(response.data);
          this.authService.setToken(response.token);
          const rol = Number(response.data.role_id);
          this.navService.updateMenu(rol);
          // Redirigir a clientes/inicio
          this.router.navigate(['clientes/inicio']);
        } else {
          this.modalService.openModal(
            'Error',
            response.message ? response.message : messageErrorLogin,
            'error'
          );
        }
      },
      (error) => {
        // Manejo de errores en la solicitud de aprobación de cuota
        toggleLoader(this.loaderService, false);

        this.modalService.openModal(
          'Error',
          error.error.message ? error.error.message : messageErrorLogin,
          'error'
        );
        this.router.navigate(['/']);
      }
    );
  }

  checkboxChanged(id: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
  }
}

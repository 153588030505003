<div class="modal-header">
    <h4 class="modal-title">Comentarios y Observaciones</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <div *ngIf="roleIsAdmin">
        <div class="row my-4">
            <div class="col-12">
                <h5 class="fw-bol">¿Qué sucede con esta solicitud?</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <div class="form-group">
                    <label>Selecciona un motivo</label>
                    <select class="form-control" [(ngModel)]="selectReason">
                        <option value="Las fotos del documento no son legibles">
                            Las fotos del documento no son legibles
                        </option>
                        <option value="Una de las referencias no contesta el teléfono">
                            Una de las referencias no contesta el teléfono
                        </option>
                        <option value="Hay problemas con el documento de identidad">
                            Hay problemas con el documento de identidad
                        </option>
                    </select>
                </div>
            </div>
    
            <div class="col-12 mb-3">
                <div class="form-group">
                    <label>Comentarios</label>
                    <textarea class="form-control" placeholder="Describe tu observación para esta solicitud" rows="3"
                        cols="30" [(ngModel)]="textareaContent"></textarea>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" (click)="saveComments()">
                    Guardar <i class="fas fa-save"></i>
                </button>
            </div>
        </div>
        <hr/>
    </div>
    <!-- observaciones previas -->
    <div>
        <div class="card">
            <div class="card-header p-0">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0">Observaciones previas:</h5>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="activity-media">
                    <div class="media" *ngFor="let listComent of listComents">
                        <div class="recent-circle bg-primary"></div>
                        <div class="media-body">
                            <h6 class="font-roboto mb-0">{{ listComent.type }}</h6>
                            <span>
                                <i class="fas fa-comments"></i>
                                <span class="font-roboto">
                                    {{ listComent.comment }} <br />
                                    <i class="fas fa-clock"></i>
                                    {{ listComent.created }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="text-center" *ngIf="listComents.length === 0">
                        <h6 class="font-roboto mb-0">No hay comentarios u observaciones</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
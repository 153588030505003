<div class="container-fluid">
  <div class="row">
    <div class="alert-info-background col-12">
      <div class="alert-info">
        <div class="col-12">
          <span (click)="decrementar()"
            ><i class="fa-solid fa-circle-xmark"></i
          ></span>
        </div>

        <div class="col-12">
          <div class="info-image" *ngIf="isSuccess === 0 ? false : true">
            <img
              src="../../../../assets/img-news/alert-info.svg"
              alt="Fondo auto"
            />
          </div>
        </div>

        <div class="info-content mt-4">
          <img
            src="../../../../assets/img-news/logo-ziro-verdeOscuro.svg"
            alt="Fondo auto"
            class="mx-auto d-block img-fluid pb-3"
          />
          <h2>{{ titulo }}</h2>
          <p>
            {{ descripcion }}
            <b
              ><a [href]="link">{{ link }}</a></b
            >
          </p>
        </div>

        <div class="input-button mt-3">
          <!-- <div class="info_input">
            <input
              autofocus
              type="number"
              id="phone_1"
              placeholder="Digita el código"
              value=""
              formControlName="phone_1"
              required
            />
          </div> -->
          <button type="button" (click)="incrementar()">
            {{ textoBoton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
	AuthUserInterface,
	AuthUserResponseInterface,
} from "../interfaces/auth-user.interface";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	/**
	 * Usuario autenticado.
	 */
	private user: AuthUserInterface;

	/**
	 * URL base del servicio de autenticación.
	 */
	private baseUrl = environment.apiUrl + "/auth";

	/**
	 * Token del usuario autenticado.
	 */
	private token: string;

	/**
	 * Constructor del servicio.
	 * @param {HttpClient} http - Cliente HTTP para realizar peticiones.
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Establece el usuario autenticado y lo almacena en el almacenamiento local.
	 *
	 * @param {AuthUserInterface} user - Información del usuario autenticado.
	 * @returns {void}
	 */
	setUser(user: AuthUserInterface): void {
		this.user = user;
		localStorage.setItem("user", JSON.stringify(user));
	}

	/**
	 * Obtiene la información del usuario autenticado desde la memoria o desde el almacenamiento local.
	 *
	 * @returns {any} Información del usuario autenticado o null si no se encuentra.
	 */
	getUser(): any {
		if (this.user) {
			return this.user;
		}
		const user = localStorage.getItem("user");
		return user ? JSON.parse(user) : null;
	}

    getRolesAdministrative(): any {
        return [1,2,3];
    }

    getCanWithdrawCredit(): any {
        return [1,4,6];
    }

    isRoleUserAdministrative(): boolean {
        const roleUser = this.getUser().role_id;
        const rolesAdministrative = this.getRolesAdministrative();
        return rolesAdministrative.includes(roleUser);
    }

    
    roleCanWithdrawCredit() {
        const roleUser = this.getUser().role_id;
        const rolesAdministrative = this.getCanWithdrawCredit();
        return rolesAdministrative.includes(roleUser);
    }

	/**
	 * Establece el token del usuario autenticado y lo almacena en el almacenamiento local.
	 *
	 * @param {string} token - Token de autenticación del usuario.
	 * @returns {void}
	 */
	setToken(token: string): void {
		this.token = token;
		localStorage.setItem("token", token);
	}

	/**
	 * Obtiene el token de autenticación del usuario desde la memoria o desde el almacenamiento local.
	 *
	 * @returns {any} Token de autenticación o null si no se encuentra.
	 */
	getToken(): any {
		if (this.token) {
			return this.token;
		}
		const token = localStorage.getItem("token");
		return token ? token : null;
	}

	/**
	 * Realiza una solicitud para autenticar a un usuario con un correo y contraseña.
	 *
	 * @param {string} email - Correo electrónico del usuario.
	 * @param {string} password - Contraseña del usuario.
	 * @returns {Observable<AuthUserResponseInterface>} Observable con la respuesta de la autenticación.
	 */
	login(email: string, password: string) {
		return this.http.post<AuthUserResponseInterface>(this.baseUrl, {
			email,
			password,
		});
	}

	/**
	 * Realiza una solicitud para cerrar la sesión del usuario autenticado.
	 *
	 * @returns {Observable<AuthUserResponseInterface>} Observable con la respuesta del cierre de sesión.
	 */
	logout() {
		const url = this.baseUrl + "/cerrar-sesion";
		return this.http.get<AuthUserResponseInterface>(url);
		// localStorage.removeItem("user");
		// localStorage.removeItem('token');
	}
}

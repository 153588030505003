import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentService } from "./services/paysuccess.service";
import { Subscription } from "rxjs";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-paysuccess",
  templateUrl: "./paysuccess.component.html",
  styleUrls: ["./paysuccess.component.scss"],
})
export class PaysuccessComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  public paymentError: boolean | null = null;
  public description: String;
  public names: String;

  ngOnInit(): void {
    toggleLoader(this.loaderService, true, "Validando tu pago...");
    this.routeSubscription = this.route.queryParamMap.subscribe(
      (queryParams) => {
        const transactionId = queryParams.get("id");
        console.log(transactionId);

        if (transactionId) {
          // Realiza la verificación y actualización del pago
          try {
            this.paymentService
              .verifyAndProcessPayment(transactionId)
              .subscribe(
                (response) => {
                  if (response.status = "completed") {
                    toggleLoader(this.loaderService, false);
                    this.names = response.customer.name;
                    this.description = response.description;
                    this.paymentError = true;
                    console.log(response);
                    
                  } else {
                    this.paymentError = false;
                  }
                },
                (error) => {
                  // Manejo de errores
                  console.error("Error:", error);
                  this.paymentError = false;
                  toggleLoader(this.loaderService, false);
                  Swal.fire({
                    title: "Error!",
                    text: error.error.message,
                    icon: "error",
                    showCloseButton: true,
                  });
                }
              );
          } catch (error) {
            console.log(error);
          }
        } else {
          toggleLoader(this.loaderService, false);
          console.error("ID de transacción no encontrado en la URL.");
          this.router.navigate(["/general/fastpayment"]);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}

import { Component, HostListener } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-header-logo",
  templateUrl: "./header-logo.component.html",
  styleUrls: ["./header-logo.component.scss"],
})
export class HeaderLogoComponent {
  isActive: boolean = false;
  link: string;
  login: boolean = true;
  register: boolean = true;
  pay: boolean = true;

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.updateIsActiveState(window.innerWidth);
  }

  constructor(private location: Location) {
    // Llamamos a updateIsActiveState en el constructor para configurar el estado inicial
    this.updateIsActiveState(window.innerWidth);

    // Dentro del constructor, puedes obtener la URL actual y asignarla a la variable link
    this.link = this.location.path();
    this.link === "/iniciar-sesion" ? (this.login = false) : (this.login = true);
    this.link === "/tu-credito"
      ? (this.register = false)
      : (this.register = true);
    this.link === "/general/fastpayment" ? (this.pay = false) : (this.pay = true);
  }

  toggleMenu() {
    this.isActive = !this.isActive;
  }

  // Método para actualizar el estado isActive en función del ancho de la ventana
  private updateIsActiveState(windowWidth: number): void {
    // Cambiamos el operador de comparación para que isActive sea false si el ancho es mayor a 480
    if (windowWidth >= 563) {
      this.isActive = false;
    }
  }
}

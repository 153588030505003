<div
  [@fade]="showModal ? 'visible' : 'hidden'"
  *ngIf="showModal"
  class="modal-payment-background"
>
  <div class="modal-payment">
    <span class="modal-close" *ngIf="register != 21" (click)="modalService.closeModal()">
      <i class="fa-solid fa-circle-xmark close"></i>
    </span>
    <div class="pe-none">
      <header [ngClass]="type">
        <div *ngIf="type === 'info'">
          <i class="fa-solid fa-circle-info"></i>
        </div>
        <div *ngIf="type === 'warning'">
          <i class="fa-solid fa-circle-exclamation"></i>
        </div>
        <div *ngIf="type === 'error'">
          <i class="fa-solid fa-circle-xmark"></i>
        </div>
        <div *ngIf="type === 'success'">
          <i class="fa-solid fa-circle-check"></i>
        </div>
        <svg
          class="wave"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            [style.fill]="
              type === 'info'
                ? '#70c6ff'
                : type === 'warning'
                ? '#ffc107'
                : type === 'error'
                ? '#F66B7E'
                : type === 'success'
                ? '#1FE679'
                : '#0099ff'
            "
            fill-opacity="1"
            d="M0,192L40,165.3C80,139,160,85,240,96C320,107,400,181,480,192C560,203,640,149,720,128C800,107,880,117,960,149.3C1040,181,1120,235,1200,213.3C1280,192,1360,96,1400,48L1440,0L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg>
      </header>
      <div class="modal-content">
        <h2 class="fs-2 pb-1">
          {{ title !== null && title !== undefined ? title : "Error" }}
        </h2>

        <p class="description pb-2">
          {{
            description !== null && description !== undefined
              ? description
              : "¡Ocurrio un error, estamos trabajando para solucionarlo!"
          }}
        </p>
        <button
          *ngIf="register != 21"
          class="btn-alert pe-auto"
          [ngClass]="type"
          (click)="modalService.closeModal()"
        >
          {{ type === "success" ? "Continuar" : "Volver a intentar" }}
        </button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import JSConfetti from "js-confetti";

@Component({
  selector: "app-cupo-aprobado",
  templateUrl: "./cupo-aprobado.component.html",
  styleUrls: ["./cupo-aprobado.component.scss"],
})
export class CupoAprobadoComponent {
  constructor(private router: Router) {}
  jsConfetti = new JSConfetti();

  Confetti() {
    this.jsConfetti.addConfetti();
  }

  ngOnInit(): void {
    this.Confetti();
  }

    navigateToLogin() {
        this.router.navigate(['/iniciar-sesion']);
    }

}

import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CreditRequestService } from "src/app/administrative/credits-requests/services/credit-request.service";
import { AuthService } from "src/app/auth/login/services/auth.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-credits-requests-comments",
	templateUrl: "./credits-requests-comments.component.html",
	styleUrls: ["./credits-requests-comments.component.css"],
})
export class CreditsRequestsCommentsComponent {
	@Input() creditRequestId: number; // Propiedad para almacenar el id de la solicitud de crédito

	textareaContent: string; // Propiedad para almacenar el contenido del textarea
	selectReason: string; // Propiedad para almacenar el contenido de Selecciona un motivo
	listComents: any[] = []; // Propiedad para almacenar la lista de comentarios
	roleUser: number;
	roleIsAdmin = false;

	/**
    * Constructor de la clase.
	*/
	constructor(
		public activeModal: NgbActiveModal,
		private CreditRequestService: CreditRequestService,
		private loaderService: LoaderService,
		private authService: AuthService
	) {}

    /**
     * Método de ciclo de vida de Angular - ngOnInit.
     * Invocado cuando el componente es inicializado.
    */
	ngOnInit(): void {
		this.getCommentsRequest();
		this.userIsRoleAdmin();
	}

    /**
     * Verifica si el usuario actual tiene un rol administrativo.
     * Establece la propiedad roleIsAdmin basada en el resultado.
    */
	userIsRoleAdmin() {
		this.roleUser = this.authService.getUser().role_id;
		this.roleIsAdmin = this.authService
			.getRolesAdministrative()
			.includes(this.roleUser);
	}

    /**
     * Guarda los comentarios de una solicitud de crédito.
     * Realiza una validación para asegurarse de que los campos requeridos estén llenos.
     * Si la validación es exitosa, se procede a guardar el comentario a través del servicio `CreditRequestService`.
     * Se muestran mensajes de alerta (Swal) para notificar al usuario sobre el éxito o fracaso de la operación.
    */
	saveComments() {
		if (
			this.textareaContent == undefined ||
			(this.textareaContent == "" && this.selectReason == undefined) ||
			this.selectReason == ""
		) {
			Swal.fire(
				"Error",
				"Por favor completa todos los campos.",
				"warning"
			);
			return;
		}
		toggleLoader(this.loaderService, true);
		const customerId = this.creditRequestId;
		const valueComment = this.textareaContent;
		const selectReason = this.selectReason;
		//const note = this.customerForm?.get('note').value;
		console.log("Contenido del textarea:", valueComment);
		console.log("Contenido del selectReason:", selectReason);
		this.CreditRequestService.saveCommentsCreditRequest(
			customerId,
			valueComment,
			selectReason
		).subscribe(
			(response) => {
				if (response.ok) {
					Swal.fire(
						"Éxito",
						"Se agregó un nuevo comentario.",
						"success"
					);
					toggleLoader(this.loaderService, false);
					this.getCommentsRequest();
					this.textareaContent = "";
				} else {
					Swal.fire(
						"Error",
						"No se guardó el comentario. Intenta nuevamente.",
						"error"
					);
					toggleLoader(this.loaderService, false);
				}
			},
			(error) => {
				Swal.fire(
					"Error",
					"Ocurrió un error al guardar el comentario. Inténtalo nuevamente.",
					"error"
				);
				toggleLoader(this.loaderService, false);
				console.error(error); // Imprime el error en la consola para fines de depuración
			}
		);
	}

    /**
     * Obtiene los comentarios de una solicitud de crédito en particular.
     * Usa el servicio `CreditRequestService` para obtener la lista de comentarios.
     * Al finalizar la petición, asigna la lista de comentarios a la propiedad `listComents`.
    */
	getCommentsRequest() {
		toggleLoader(this.loaderService, true);
		const customerId = this.creditRequestId;
		this.CreditRequestService.getCommentsCreditRequest(
			customerId
		).subscribe((response) => {
			if (response.ok) {
				this.listComents = response.data;
				console.log("Lista de comentarios:", this.listComents);
				toggleLoader(this.loaderService, false);
			} else {
			}
		});
		toggleLoader(this.loaderService, false);
	}
}

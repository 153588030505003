<div
  class="w-100 border rounded-2 position-relative"
  style="background-color: #daffeb3b"
>
  <i
    class="fa-solid fa-circle-exclamation position-absolute top-0 end-0 p-2"
    style="color: #747474"
    placement="left"
    [ngbTooltip]="tooltip"
  ></i>
  <div class="p-3">
    <div class="text-left align-items-end row">
      <div class="col-7 bl-texts">
        <img [src]="urlImagen" width="40" height="40" alt="Image" />
        <div class="d-flex flex-column gap-1">
          <h5
            class="fw-semibold fs-6 p-0 mt-3 text-start"
            style="color: #484848"
          >
            {{ title }}
          </h5>
          <p
            class="p-0 m-0 lh-sm text-start bl-texts--description"
            style="font-size: 13px"
          >
            {{ description }}
          </p>
        </div>
      </div>
      <div class="col-5 text-end ps-0 bl-texts">
        <h4
          class="fw-semibold mb-0 text-right bl-texts--description"
          style="color: #484848"
        >
          {{
            customers
              ? value
              : (value | currency : "" : "symbol" : "1.0-0") || "$"
          }}
        </h4>
      </div>
    </div>
  </div>
</div>

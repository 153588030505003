import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  RegisterCustomerInterface,
  ResponseSearch,
} from '../interfaces/register.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  /*************
   *  variables**
   *************/
  private baseUrlDeceval: string = environment.apiUrl + '/deceval';
  private baseUrl: string = environment.apiUrl + '/clientes';
  private baseUsuario: string = environment.apiUrl + '/usuarios';
  private baseUrlSucursal: string = environment.apiUrl + '/sucursales';
  private _refreshUsers$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  /*************
   *  getters****
   *************/
  get _refreshUsers() {
    return this._refreshUsers$;
  }

  /*************
   *  metodos****
   *************/

  verifyCedula(cedula: string) {
    const url = `${this.baseUrl}/existe/cedula/${cedula}`;
    return this.http.get<ResponseSearch>(url);
  }
  verifyCodeProvider(codigo: string) {
    const url = `${this.baseUrlSucursal}/existe/codigo/${codigo}`;
    return this.http.get<ResponseSearch>(url);
  }
  verifyEmail(email: string) {
    const url = `${this.baseUsuario}/existe/correo/${email}`;
    return this.http.get<ResponseSearch>(url);
  }

  saveRegister(data: any) {
    const url = `${this.baseUrl}`;
    return this.http.post<RegisterCustomerInterface>(url, data);
  }

  generarToken() {
    const url = 'https://api.getmati.com/oauth';
    const data = 'grant_type=client_credentials';
    return this.http.post(url, data, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization:
          'Basic NjMwZmM0ZDQ3NzM0ZjYwMDFjOTY5NzdiOkxWVUNPRDY3UUNESFVNNEFQVlU3QVE1Nk1PWklYQ1hM',
      },
    });
  }
  crearVerificacion(token: string) {
    const url = `https://api.getmati.com/v2/verifications`;
    const data =
      '{"metadata":{"plataforma":"ziro Api angular","urlAliado":"' +
      window.location.href +
      '"},"flowId":"6376ae47b2c008001c4277d7"}';
    return this.http.post(url, data, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      },
    });
  }
  sendRegisterMetamapSendInput(id: string, data: any, token: any) {
    const url = `https://api.getmati.com/v2/identities/${id}/send-input`;
    return this.http.post(url, data, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }
  getInformationUserMetamap(id: string, token: any) {
    const url = `https://api.getmati.com/v2/verifications/${id}`;
    return this.http.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }
  sendCodeOtp(celular: number) {
    const url = `${this.baseUrl}/regitro/codigo-otp/${celular}`;
    return this.http.get(url);
  }
  validateCodeOtpCustomerService(data: any) {
    const url = `${this.baseUrl}/regitro/validar/codigo-otp`;
    return this.http.post(url, data, {
      headers: {
        Accept: 'application/json',
      },
    });
  }
  // solicita un cupo de credito para el cliente o rechaza el cupo
  requestQuota(data: any) {
    console.log('data', data);
    const url = `${this.baseUrl}/registro/score?identification=${data.identification}&nit=${data.nit}&document_type_id=${data.document_type_id}&businness_type_id=${data.businness_type_id}`;
    return this.http.get(url, {
      headers: {
        Accept: 'application/json',
      },
    });
  }
  createSpinnerDecevalServices() {
    const url = `${this.baseUrlDeceval}/createdSpinnerDeceval`;
    return this.http.get(url);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PagareInfoCustomerResponseInterface } from '../interfaces/info-customer-pagare.interface';
import { PagareSendCodeResponseInterface } from '../interfaces/pagare-send-code.interface';

@Injectable({
  providedIn: 'root',
})
export class FirmaPagareService implements CanActivate {

    private baseUrl: string =environment.apiUrl+'/pagare';

    /**
     * Constructor para inicializar las dependencias.
    */
    constructor(
        private http: HttpClient,
        private router: Router
    ) {}


    /**
     * Determina si una ruta puede ser activada basándose en la presencia del parámetro 'id'.
     * 
     * Si el parámetro 'id' está presente en la ruta, se permite la activación. 
     * En caso contrario, redirige al usuario a la página de inicio de sesión.
     * 
     * @param {ActivatedRouteSnapshot} route - Una representación estática de la ruta a activar.
     * @param {RouterStateSnapshot} state - El estado del router en un momento dado.
     * @returns {boolean} `true` si la ruta puede ser activada, `false` en caso contrario.
    */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const id = route.paramMap.get('id');
        if (id) {
        console.log(id)
        return true;
        } else {
        this.router.navigate(['/iniciar-sesion']);
        return false;
        }
    }

    /**
     * Obtiene la información detallada del cliente a partir de su ID.
     * 
     * @param {number} customerId - El ID del cliente para el cual se quiere obtener la información.
     * @returns {Observable<PagareInfoCustomerResponseInterface>} Un Observable con la respuesta del servidor que contiene la información del cliente.
    */
    getInfoCustomer(customerId: number) {
        const url = `${this.baseUrl}/info-cliente/${customerId}`;
        return this.http.get<PagareInfoCustomerResponseInterface>(url);
    }  

    /**
     * Solicita el envío de un código OTP relacionado con un pagare al cliente basándose en su ID.
     * 
     * @param {number} customerId - El ID del cliente al cual se desea enviar el código OTP.
     * @returns {Observable<PagareSendCodeResponseInterface>} 
    */
    sendCodeOtpPagare(customerId: number) {
        const url = `${this.baseUrl}/enviar-codigo-otp/${customerId}`;
        return this.http.get<PagareSendCodeResponseInterface>(url);
    }  

    /**
     * Firma un pagaré utilizando la identificación del cliente y un código.
     * 
     * Esta función realiza una solicitud POST para firmar un pagaré basado 
     * en la ID del cliente y el código proporcionado. Se espera que el 
     * endpoint correspondiente en el servidor realice la validación y 
     * actualización necesarias.
     * 
     * @param {number} customerId - El ID del cliente que desea firmar el pagaré.
     * @param {string} code - El código asociado al cliente para la firma del pagaré.
     * @return {Observable<PagareSendCodeResponseInterface>} 
    */
    signPagare(customerId: number, code:string) {
        const url = `${this.baseUrl}/firmar/`;
        return this.http.post<PagareSendCodeResponseInterface>(url, {customerId, code});
    }

    /**
     * Envía una solicitud al servidor para indicar que un elemento no ha sido aceptado para firma.
     *
     * @param {string} item - El elemento que no se ha aceptado para firma.
     * @returns {Observable<PagareSendCodeResponseInterface>} Un Observable que representa la respuesta del servidor.
    */
    noAcceptSign(reason:string,identification:number) {
        const url = `${this.baseUrl}/no-acepto-firma`;
        return this.http.post<PagareSendCodeResponseInterface>(url, {reason,identification});
    }
}


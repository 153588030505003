import { ChangeDetectorRef, Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/administrative/customers/services/customer-service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import {
	toggleLoader,
	validateCampo,
} from "../../../../shared/helpers/functions-helper.service";
import Swal from "sweetalert2";
import { forkJoin } from "rxjs";
import { AuthService } from "src/app/auth/login/services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GeneralParameterInterface } from "src/app/shared/interfaces/general-parameter";
import { ParemetersService } from "src/app/shared/services/parameters.service";
import { CustomerInterface } from "src/app/administrative/customers/interfaces/customer-interface";
import { Router } from "@angular/router";

@Component({
	selector: "app-customer-edit-cupo",
	templateUrl: "./customer-edit-cupo.component.html",
	styleUrls: ["./customer-edit-cupo.component.scss"],
})
export class CustomerEditCupoComponent {
	customerId: number;
	cupoApproved: number;
	cupoActived: string;
	customerIdentification: string;
	cupoNewApproved: number;
	cupoEditButton: boolean = false;
	userId = this.authService.getUser().id;
	validateCampo = validateCampo;
	customerForm: FormGroup;
	citiesList: GeneralParameterInterface[];

	constructor(
		public activeModal: NgbActiveModal,
		private customerService: CustomerService,
		private loaderService: LoaderService,
		private authService: AuthService,
		private parameterService: ParemetersService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private router: Router,
	) {
		this.getCities();
		this.initForm();
	}

	/**
	 * Método que se ejecuta al inicializar el componente.
	 * Inicializa el formulario y obtiene los roles.
	 */
	ngOnInit(): void {
		this.cdr.detectChanges();
	}

	/**
	 * Inicializa el formulario de usuarios con controles y validaciones.
	 */
	initForm() {
		this.customerForm = this.fb.group({
			identification: ["", [Validators.required]],
			nit: ["", [Validators.required]],
			name: ["", [Validators.required]],
			last_name: ["", [Validators.required]],
			email: ["", [Validators.required , Validators.email]],
			phone: ["", [Validators.required]],
			city_id: ["", [Validators.required]],
			total_approved_quota: ["", [Validators.required]],
			address: ["", [Validators.required]],
			businness_address: ["", [Validators.required]],
			buss_name: ["", [Validators.required]],
		});
	}

	onInputChange(event: Event) {
		const inputValue = (event.target as HTMLInputElement).value.replace(
			/[^\d]/g,
			""
		);
		const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		(event.target as HTMLInputElement).value = formattedValue;
		this.cupoNewApproved = parseFloat(inputValue);
		this.cupoEditButton = this.cupoApproved !== this.cupoNewApproved;
		this.cupoEditButton = inputValue != "";
	}

	customerUpdate(): void {
		toggleLoader(this.loaderService, true);
		const messageError = "Error al actualizar el cliente";
		this.customerService
        .updateCustomer(this.customerForm.getRawValue(),this.customerId)
        .subscribe(
            (response) => {
                toggleLoader(this.loaderService, false);
                if(response.ok) {
                    this.customerForm.reset();
                    this.router.navigate(["/clientes/listado/1"]);
                    Swal.fire('Éxito', response.message, 'success');
                    // Cerrar el modal después de la actualización exitosa
                    this.activeModal.close();
                } else {
                    Swal.fire('Error', response.message || messageError, 'error');
                }
            },
            (err) => {
                toggleLoader(this.loaderService, false);
                Swal.fire(
                    "Error",
                    err.error.message || messageError,
                    "error"
                );
            }
        );
	}


	getCities() {
		toggleLoader(this.loaderService, true, "Obteniendo listado de roles");
		this.parameterService.getCities().subscribe(
			(response) => {
				toggleLoader(this.loaderService, false);
				if (response.ok) {
					this.citiesList = response.data;
				} else {
					Swal.fire(
						"Error",
						response.message
							? response.message
							: "Ocurrió un error al obtener el listado de ciudades",
						"error"
					);
				}
			},
			(error) => {
				toggleLoader(this.loaderService, false);
				Swal.fire(
					"Error",
					error.error.message
						? error.error.message
						: "Ocurrió un error al obtener el listado de ciudades",
					"error"
				);
			}
		);
	}
}

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    ¿Estás seguro de bloquear este cupo disponible?
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>

<div class="modal-body">
  <div class="mb-3 d-flex flex-column justify-content-between gap-2">
    <div>
      <label class="form-label m-0 p-0">Valor del cupo disponible</label>
      <div class="form-text fs-5 text-black m-0 p-0" disabled>
        {{
          customer.total_available_quota | currency : "$" : "symbol" : "1.0-0"
        }}
      </div>
    </div>
    <div class="d-flex justify-content-between gap-2">
      <div class="text-start">
        <label class="form-label m-0 p-0">Identificación</label>
        <div class="form-text fs-5 text-black m-0 p-0" disabled>
          {{ customer.identification }}
        </div>
      </div>
    </div>

    <div>
      <label class="form-label m-0 p-0">Nombre completo</label>
      <div class="form-text fs-5 text-black m-0 p-0" disabled>
        {{ customer.full_name }}
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn btn-dark me-2"
      (click)="activeModal.dismiss()"
    >
      Cerrar
    </button>
    <button
    (click)="customerBlockedCupo()"
      type="button"
      class="btn btn-danger d-flex align-items-center gap-2"
    >
      Bloquear
      <i class="fa-solid fa-ban"></i>
    </button>
  </div>
</div>

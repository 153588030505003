import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentServiceCustomer {
  /**
   * URL base del servicio de autenticación.
   */
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  identification(identification: number) {
    // Construye la URL completa para enviar la solicitud HTTP GET
    const url = `${this.baseUrl}/general/fastpayment/${identification}`;
    
    // Realiza la solicitud HTTP GET a la URL construida
    return this.http.get(url);
  }
}

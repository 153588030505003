import { Component, OnDestroy } from "@angular/core";
import { ModalService } from "./modal-payment.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-modal-payment",
  templateUrl: "./modal-payment.component.html",
  styleUrls: ["./modal-payment.component.scss"],
})
export class ModalPaymentComponent implements OnDestroy {
  modalOpen = false;
  modalData: any;
  paymentMethod: any;
  totalCreditsValue: number;
  otroValue: number;
  amount: number;
  PSE: boolean = true;
  private modalSubscription: Subscription;

  constructor(private modalService: ModalService) {
    this.modalSubscription = this.modalService.modalOpen$.subscribe((open) => {
      this.modalOpen = open;
    });
  }
  ngOnInit() {
    this.modalService.modalData$.subscribe((data) => {
      if (data) {
        this.modalData = data.data;
        this.paymentMethod = data.paymentMethod;

        if (this.modalData.paymentMethod == undefined) {
          this.PSE = false;
        }

        console.log(this.modalData)
        console.log(this.paymentMethod)
      }
    });
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
  }

  closeModal() {
    this.modalOpen = false;
  }
}

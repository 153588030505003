<div class="alert-success-background">
    <div class="alert-success">
      <!-- <span (click)="redirectButton()">
        <i class="fa-solid fa-circle-xmark"></i>
      </span> -->
  
      <div class="success-image">
        <img
          src="../../../../assets/img-news/alert-success-warning.svg"
          alt="Fondo auto"
        />
      </div>
  
      <div class="success-content">
        <h3>{{ titulo }}</h3>
        <p>
          {{ descripcion }}
        </p>
      </div>
  
      <div class="input-button">
        <button
          type="button"
          (click)="redirectButton()"
          *ngIf="textoBoton === null"
        >
          {{ textoBoton }}
        </button>
      </div>
    </div>
  </div>
import { Component } from "@angular/core";
import { StepService } from "../../components/step/service/step.service";

@Component({
  selector: "app-five-page",
  templateUrl: "./five-page.component.html",
  styleUrls: ["./five-page.component.scss"],
})
export class FivePageComponent {
  constructor(public StepService: StepService) {}

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }

  // nextNumber: number = 1;

  // next() {
  //   this.nextNumber++;
  //   this.nextNumber === 5 ? this.incrementar() : null;
  // }
}

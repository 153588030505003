<div class="container-fluid vh-100">
  <div class="row h-100">
    <div class="col-12 col-sm-8 col-md-5 bg-white h-100 p-0 m-0 px-4">
      <div
        class="d-flex justify-content-sm-center align-items-sm-center w-100 h-100 pt-sm-3"
      >
        <div
        class="d-flex flex-column justify-content-between gap-3 w-100 custom-width"
          style="color: #484848"
        >
          <img
            class="img-fluid pb-2 pt-4 d-sm-none"
            style="width: 8rem"
            src="../../../assets/img-news/logo-ilustration.svg"
            alt="Logo"
          />
          <div class="d-flex flex-column gap-3" style="color: #064853">
            <header>
              <h2 class="fs-3 m-0">Recupera tu contraseña</h2>
              <p class="fs-6 m-0">
                ¡Ingresa tu correo electrónico para recuperar tu contraseña!
              </p>
            </header>
  
            <form [formGroup]="recoveryPasswordForm">
              <div class="d-flex flex-column gap-2">
                <div class="d-flex flex-column">
                  <label for="email" class="form-label m-0">Correo</label>
                  <div class="position-relative">
                    <i
                      class="fa-solid fa-envelope position-absolute px-3 d-flex align-items-center justify-content-center h-100"
                    ></i>
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      id="email"
                      autofocus
                      placeholder="Correo electrónico"
                      aria-placeholder="Ingresa tu correo electrónico"
                    />
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <span>
                    <a
                      class="link"
                      href="https://wa.link/jk2a0n"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Servicio al cliente</a
                    >
                  </span>
                </div>
                <button class="btn-login" type="submit" (click)="sendEmail()">
                  Restablecer
                </button>
                <div class="d-flex align-items-center gap-3">
                  <div class="line"></div>
                  O
                  <div class="line"></div>
                </div>
                <div class="d-flex flex-column gap-2 w-100">
                  <a
                    [routerLink]="'/tu-credito'"
                    class="btn-register"
                    type="submit"
                    >Registrarme</a
                  >
                  <a [routerLink]="'/iniciar-sesion'" class="btn-pay" type="submit"
                    >Iniciar Sesión</a
                  >
                </div>
                
              </div>
            </form>
            
          </div>
          <div class="d-flex justify-content-between gap-3 pt-4 pb-4 pb-md-0">
            <span
              ><a
                class="link-terms"
                href="https://somosziro.com/terminos-y-condiciones/"
                target="_blank"
                rel="noopener noreferrer"
                >Términos y Condiciones</a
              ></span
            >
            <span
              ><a
                class="link-terms"
                href="https://somosziro.com/"
                target="_blank"
                rel="noopener noreferrer"
                >© somosziro</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-0 col-sm-4 col-md-7 h-100 p-0 m-0 d-none d-sm-block"
      style="background-color: #BBFFBF"
    >
      <div
        class="d-flex flex-column justify-content-between align-items-start gap-3 h-100 w-100"
      >
        <img
          class="img-fluid p-3 pt-4 ps-4"
          style="width: 10rem"
          src="../../../assets/img-news/logo-ilustration.svg"
          alt="Logo"
        />
        <img
          class="img-fluid w-100"
          src="../../../assets/img-news/user_password.gif"
          alt="Login"
        />
      </div>
    </div>
  </div>
</div>

<app-modal-alert></app-modal-alert>

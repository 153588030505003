import { Component } from "@angular/core";
import { StepService } from "./service/step.service";

@Component({
  selector: "app-step",
  templateUrl: "./step.component.html",
  styleUrls: ["./step.component.scss"]
})
export class StepComponent {
  constructor(public StepService: StepService) {}

  get contador() {
    return this.StepService.getStep();
  }

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }
}

import { Component, Input } from '@angular/core';
import { StepService } from '../step/service/step.service';


@Component({
  selector: 'app-alert-error',
  templateUrl: './alert-error.component.html',
  styleUrls: ['./alert-error.component.scss']
})
export class AlertErrorComponent {
  constructor(public StepService: StepService) {}

  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() textoBoton: string;

  visible: boolean = true;

  onButtonClick() {
    alert('¡Botón clickeado!');
    this.visible = false;
  }

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }
}

import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";
import { FirmaPagareService } from "./services/firma-pagare.service";
import { ModalServicePagare } from "./services/modal.service";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";

@Component({
  selector: "app-firma-pagare",
  templateUrl: "./firma-pagare.component.html",
  styleUrls: ["./firma-pagare.component.scss"],
})
export class FirmaPagareComponent {
  sixDigitForm: FormGroup;
  // codeOTP: string = '';
  sendCodeOtp = false;
  noAcepto: boolean = true;
  customerId: number;
  step: number = 1;

  /**
   * Constructor de la clase.
   */
  constructor(
    private fb: FormBuilder,
    public modalServicePagare: ModalServicePagare,
    private loaderService: LoaderService,
    private firmaPagareService: FirmaPagareService,
    private modalService: ModalServiceAlert
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.sixDigitForm = this.fb.group({
      acceptTerms: [false, Validators.requiredTrue],
      codeOtp: ["", Validators.required],
      notAcceptTerms: [false],
    });
  }

  abrirModal() {
    this.modalServicePagare.abrirModal();
  }

  toggleCheckbox(checkedCheckbox: string) {
    const notAcceptTermsControl = this.sixDigitForm.get("notAcceptTerms");
    const acceptTermsControl = this.sixDigitForm.get("acceptTerms");

    if (
      checkedCheckbox === "acceptTerms" &&
      notAcceptTermsControl &&
      acceptTermsControl
    ) {
      acceptTermsControl.setValue(true);
      notAcceptTermsControl.setValue(false);
    } else if (
      checkedCheckbox === "notAcceptTerms" &&
      notAcceptTermsControl &&
      acceptTermsControl
    ) {
      notAcceptTermsControl.setValue(true);
      acceptTermsControl.setValue(false);
    }
  }

  focusNext(event: Event, nextControlName: string) {
    const input = event.target as HTMLInputElement;
    if (input.value !== "") {
      const nextInput = document.querySelector(
        `[formControlName="${nextControlName}"]`
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  onInputChange(controlName: string) {
    const control = this.sixDigitForm.get(controlName);

    if (control !== null) {
      const inputValue = control.value.toString();
      if (inputValue.length > 1) {
        control.setValue(inputValue.charAt(inputValue.length - 1));
      }
    }
  }

  /**
   * Envía el formulario para firmar el pagaré.
   *
   * Esta función verifica la validez del formulario que contiene un código OTP.
   * Si el formulario es válido, combina los dígitos para formar el código OTP completo
   * y luego realiza una solicitud para firmar el pagaré utilizando el servicio
   * `firmaPagareService`. Dependiendo de la respuesta del servidor, muestra una
   * notificación de éxito o error al usuario.
   *
   * Si el formulario no es válido, muestra una notificación de error indicando que
   * el usuario debe ingresar el código completo.
   */
  submitForm() {
    toggleLoader(this.loaderService, true, '¡En zíro apoyamos el crecimiento de tu negocio!');

    const code_shop = this.sixDigitForm.controls["codeOtp"].value;

    console.log(code_shop);
    if (!this.customerId) {
      this.modalService.openModal("Error", "Error en la solicitud", "error");
      toggleLoader(this.loaderService, false);

      return;
    }
    if (this.sixDigitForm.valid) {
      // const digits = Object.values(this.sixDigitForm.value)
      //   .slice(0, -2)
      //   .join('');
      // this.codeOTP = digits;
      const messageError = "Error al firmar el pagare";
      const customerId = this.customerId;
      this.firmaPagareService.signPagare(customerId, code_shop).subscribe(
        (response) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            this.step = 3;
            this.modalService.openModal(
              "¡Documentos firmados con éxito!",
              response.message || messageError,
              "success"
            );
          } else {
            this.modalService.openModal(
              "Error",
              response.message || messageError,
              "error"
            );
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);

          this.modalService.openModal(
            "Error",
            error.error.message || messageError,
            "error"
          );
          console.error(error);
        }
      );
    } else {
      Swal.fire("Error", "Por favor digite el código completo", "error");
    }
  }

  /**
   * Actualiza el estado y el ID del cliente basándose en los datos recibidos.
   *
   * @param data - Un objeto que contiene el estado (sendCodeOtp) y el ID del cliente.
   * @property data.state - El estado que indica si se ha enviado el código OTP.
   * @property data.customerId - El ID del cliente al que se le ha enviado el código.
   */
  changeStepAndCustomerId(data: { step: number; customerId: number }) {
    this.step = data.step;
    this.customerId = data.customerId;
  }
}

import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-customers-images-modal",
  templateUrl: "./customers-images-modal.component.html",
  styleUrls: ["./customers-images-modal.component.scss"],
})
export class CustomersImagesModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  document_file_down: string;
  document_file_up: string;
  image_file: string;
}

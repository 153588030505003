import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/login/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const token = this.authService.getToken();
  
    
    if (request.url.endsWith('/auth')) {
        // Si la URL termina con '/auth', la petición se maneja sin modificación
        return next.handle(request);
    }

    // Comprobar si la URL de la petición comienza con la URL de la API deseada
    if (token && (request.url.startsWith('https://zirov2back.somosziro.com/api') || request.url.includes('localhost')) 
        || request.url.includes('http://64.23.161.235')) {
        request = request.clone({
            setHeaders: {
            Authorization: `Bearer ${token}`
            }
        });
    }
    
    return next.handle(request);
  }
}

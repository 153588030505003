<div class="modal-payment" [ngClass]="{ 'modal-visible': modalOpen }">
  <div class="modal-content" [ngClass]="{ 'modal-content-visible': modalOpen }">
    <div class="content">
      <i class="fa-solid fa-circle-xmark close" (click)="closeModal()"></i>
      <div class="content-spacing">
        <div class="logo-value">
          <div class="logo">
            <div class="logo-img">
              <img
                src="../../../assets/img/somoszirosas.jpg"
                alt="SOMOS ZIRO SAS"
              />
            </div>
            <div>
              <b>Pago a</b>
              <h1>SOMOS ZIRO SAS</h1>
            </div>
          </div>
          <div class="value">
            <h1>
              {{
                (modalData?.selectedPaymentOption === "total"
                  ? modalData?.totalCreditsValue
                  : modalData?.otroValue) ?? modalData?.amount
                  | number : "1.0-3"
              }}
            </h1>

            <span>COP</span>
          </div>
        </div>

        <div class="methods" *ngIf="!PSE">
          <div class="text">
            <h2>Escoge el método de pago</h2>
            <h5>
              Elige la mejor opción de pago ejemplo (Nequi, Bancolombia, BBVA)
            </h5>
          </div>

          <div class="methods-flex">
            <a
              *ngIf="paymentMethod?.url"
              [attr.href]="paymentMethod.url"
              class="method"
              rel="noopener noreferrer"
            >
              <div class="logo-method">
                <img src="../../../assets/img/pse.png" alt="PSE" />
              </div>
              <h5>
                Transfiere con tu cuenta de ahorros o corriente a cualquier
                Banco con (PSE)
              </h5>
            </a>
          </div>
        </div>

        <h3
          *ngIf="PSE"
          class="d-flex align-items-center justify-content-center"
        >
          ¡Lo sentimos hubo un error en los metodos de pago, por favor intentalo
          de nuevo!
        </h3>
      </div>
    </div>
  </div>
</div>

<app-modal-alert></app-modal-alert>

import { Component } from '@angular/core';
import { StepService } from '../../components/step/service/step.service';

@Component({
  selector: 'app-browser-default',
  templateUrl: './browser-default.component.html',
  styleUrls: ['./browser-default.component.scss'],
})
export class BroserDefaulPageComponent {
  constructor(public StepService: StepService) {}

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }
}

<div class="cupo_aprobado_modal">
  <div class="cupo_aprobado">
    <div class="img_figure">
      <img
        src="../../../../assets/img-news/figuras.svg"
        alt="Figuras celebrando"
      />
    </div>
    <div class="cupo_message">
      <h1>
        ¡Felicidades, <br />
        Hemos terminado!
      </h1>
      <p class="fs-6 lh-base pt-2">
        <b>Tu cupo ha sido activado con éxito.</b> <br> Ya puedes
        realizar la compra de inventario con tu proveedor e ingresar a tu
        plataforma de <b>zíro</b> con el usuario y contraseña que creaste
      </p>
      <div class="cupo_button">
        <button (click)="navigateToLogin()">Iniciar sesión</button>
      </div>
    </div>
    <div class="cupo_image">
      <img src="../../../../assets/img-news/excelent.webp" alt="Celebración" />
    </div>
  </div>
</div>

<app-modal-alert></app-modal-alert>

import { Component, Input } from '@angular/core';
import { StepService } from '../step/service/step.service';


@Component({
  selector: 'app-alert-warning',
  templateUrl: './alert-warning.component.html',
  styleUrls: ['./alert-warning.component.scss']
})
export class AlertWarningComponent {
  constructor(public StepService: StepService) {}

  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() textoBoton: string;
  @Input() isSuccess: number;

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }

  allChecked: boolean = false;
  aceptoChecked: boolean = false;

  checkboxChanged(id: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      console.log(`El checkbox ${id} ha sido seleccionado.`);
    } else {
      console.log(`El checkbox ${id} ha sido deseleccionado.`);
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import Swal from "sweetalert2";
import {
  toggleLoader,
  validateCampo,
} from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { NavService } from "../../shared/services/nav.service";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  /**
   * Componente para gestionar la autenticación y la navegación.
   */
  public show: boolean = false;
  /** Formulario reactivo para gestionar el login */
  public loginForm: FormGroup;

  /** Mensaje de error en caso de problemas con la autenticación */
  public errorMessage: any;

  /** Indica si el menú está abierto o cerrado */
  public isMenuOpen: boolean = true;

  /** Función auxiliar importada para validar campos de formulario */
  validateCampo = validateCampo;

  /**
   * Constructor del componente.
   *
   * @param {FormBuilder} fb - Constructor de formularios reactivos.
   * @param {Router} router - Servicio de router de Angular.
   * @param {AuthService} authService - Servicio para gestionar la autenticación.
   * @param {NavService} navService - Servicio para gestionar la navegación.
   * @param {LoaderService} loaderService - Servicio para gestionar el loader o spinner de carga.
   */
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private navService: NavService,
    private loaderService: LoaderService,
    private modalService: ModalServiceAlert
  ) {}

  /**
   * Método de inicialización del componente.
   * Se encarga de inicializar el formulario de login con campos email y password,
   * además de sus respectivas validaciones.
   *
   * @returns {void}
   */
  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  /**
   * Cambia el estado de visibilidad del menú y registra el estado en la consola.
   *
   * @returns {void}
   */
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
  }

  /**
   * Cambia el estado de visibilidad de la contraseña en el formulario.
   *
   * @returns {void}
   */
  showPassword() {
    this.show = !this.show;
  }

  /**
   * Método para iniciar sesión del usuario. Valida el formulario y, si es válido,
   * procede con el inicio de sesión. Luego, basándose en el rol del usuario,
   * redirige a la página correspondiente. En caso de error, muestra un mensaje
   * de alerta.
   *
   * @returns {void}
   */

  login() {
    if (this.loginForm.invalid) {
      this.modalService.openModal(
        "Alerta",
        "Por favor, completa correctamente todos los campos.",
        "warning"
      );
      return;
    }
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    if (password.length === 4) {
        // Muestra el mensaje solo si la contraseña tiene 4 caracteres
        toggleLoader(this.loaderService, true, 'Paga todas las compras que realices con zíro directamente a nosotros');
    } else {
        toggleLoader(this.loaderService, true, 'zíro estres, ya te estamos redirigiendo a tu usuario.'); 
    }

    this.authService.login(email, password).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        if (response.ok) {
          this.authService.setUser(response.data);
          this.authService.setToken(response.token);
          const rol = response.data.role_id;
          this.navService.updateMenu(rol);

          //redirigir de acuerdo al rol
          if ([1, 2, 3].includes(rol)) {
            // Redirigir a panel/inicio si el rol es 1, 2 o 3
            this.router.navigate(["panel/inicio"]);
          } else if ([4, 6, 7, 8].includes(rol)) {
            // Redirigir a proveedores/panel/inicio si el rol es 4, 6, 7 u 8
            this.router.navigate(["proveedores/panel/inicio"]);
          } else if (rol === 5) {
            // Redirigir a clientes/inicio si el rol es 9
            this.router.navigate(["clientes/inicio"]);
          } else {
            this.modalService.openModal(
              "Error",
              "Ocurrió un error, intente mas tarde.",
              "error"
            );
            this.router.navigate(["/"]);
          }
        } else {
          this.modalService.openModal("Error", response.message, "error");
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        this.modalService.openModal("Error", error.error.message, "error");
      }
    );
  }
}

<div class="container-fluid p-0 container-pre">
  <div class="background">
    <img src="../../../assets/img/background.png" alt="Logo" width="120" />
  </div>
  <div class="pre-register px-4">
    <div class="ps-3 ps-md-5 pt-3">
      <img
        src="../../../assets/images/logo/logo-registro.png"
        alt="Logo"
        width="120"
      />
    </div>
    <div class="d-flex buttons">
      <a href="general/fastpayment" class="btn-credito">Pagar </a>
      <a href="auth/login" class="btn-crearCuenta">Iniciar Sesión</a>
    </div>
  </div>

  <div class="pre-register" *ngIf="paymentError">
    <div class="col-12">
      <div class="container">
        <div class="container-payment">
          <h1 class="message-payment">Pago Exitoso!</h1>
          <div>
            <p>
              ¡Hola {{names}}, tú pago ha sido procesado exitosamente! <br />
              <b>{{description}}</b><br /><br />
              Recibirás un correo electrónico con los detalles de tu pedido.
            </p>
          </div>
          <a class="btn-back" href="https://somosziro.com/">Volver al inicio</a>
        </div>

        <img src="../../../assets/img/payment.svg" alt="Logo" />
      </div>
    </div>
  </div>

  <div class="pre-register" *ngIf="!paymentError"> <!-- Mostrar solo si hay error -->
    <!-- Contenido de error de pago -->
    <div class="col-12">
      <div class="container">
        <div class="container-payment">
          <h1 class="message-payment">Error en el pago</h1>
          <div>
            <p>
              Ha ocurrido un error al procesar tu pago. Por favor, inténtalo nuevamente más tarde.
            </p>
          </div>
          <div class="buttons-help">
            <a class="btn-back" href="https://somosziro.com/">Volver al inicio</a>
            <a class="btn-support" href="https://somosziro.com/">Contactar a soporte</a>
          </div>
        </div>
        <img src="../../../assets/img/failed.svg" alt="Error" />
      </div>
    </div>
  </div>

  <div class="pre-footer p-3">
    <div class="links col-5 col-md-9 ps-3 ps-md-5 pt-3">
      <a
        target="_blank"
        href="https://somosziro.com/terminos-y-condiciones/"
        rel="noopener noreferrer"
        ><p>Términos y Condiciones</p></a
      >
      <a href="auth/registro"><p>Copyright 2023 © Zíro</p></a>
    </div>
    <div class="center-image px-3">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" width="180" />
    </div>
  </div>
</div>

<div class="container-fluid vh-100 web-cel">
  <div class="row h-100">
    <div class="col-12 col-sm-8 col-md-5 bg-white h-100 p-0 m-0">
      <div
        class="d-flex justify-content-start align-items-start justify-content-sm-center align-items-sm-center w-100 h-100 pt-sm-3"
      >
        <div
          class="d-flex flex-column gap-3 w-100 h-100 custom-width position-relative"
          style="color: #064853"
        >
          <img
            *ngIf="stepGeneral === 2"
            src="../../../../assets/img-news/nubes-sol.webp"
            class="img-fluid pb-2 pt-4 d-sm-none"
            alt="Nubes Sol"
          />
          <div *ngIf="stepGeneral === 2">
            <span
            *ngIf="stepLogic === 1"
            class="position-absolute cursor"
            style="top: 5px; right: 10px; font-size: 30px"
            (click)="decreaseStepGeneral1()"
            
          >
            <i class="fa-solid fa-circle-arrow-left"></i>
          </span>

          <span
          *ngIf="stepLogic === 2"
            class="position-absolute cursor"
            style="top: 5px; right: 10px; font-size: 30px"
            (click)="decreaseStepGeneral2()"
          >
            <i class="fa-solid fa-circle-arrow-left"></i>
          </span>

          <span
          *ngIf="stepLogic === 3"
            class="position-absolute cursor"
            style="top: 5px; right: 10px; font-size: 30px"
            (click)="decreaseStepGeneral3()"
          >
            <i class="fa-solid fa-circle-arrow-left"></i>
          </span>
          </div>

          <!-- FORMULARIO PARA ENVIAR EL NUMERO DE IDENTIFICACION-->
          <div
            class="d-flex flex-column justify-content-between justify-content-sm-center gap-2 h-100 px-4"
            *ngIf="stepGeneral === 1"
          >
            <img
              class="img-fluid pb-2 pt-4 d-sm-none"
              style="width: 8rem"
              src="../../../assets/img-news/logo-ilustration.svg"
              alt="Logo"
            />
            <!-- BUSQUEDA DE IDENTIFICACION PARA EL PAGO Y CREDITOS -->

            <div class="d-flex flex-column gap-2 justify-content-between">
              <div>
                <header>
                  <h2 class="fs-2 m-0 pb-2">Paga tu crédito</h2>
                  <p class="fs-6 m-0 pb-3">
                    ¡Paga tus cuotas en línea ingresando tu
                    <span>documento</span> de identidad. <br />
                    ¡Es <span>rápido</span>, <span>fácil </span> y
                    <span>Seguro!</span>!
                  </p>
                </header>

                <form (ngSubmit)="getIdentification()" [formGroup]="form">
                  <div class="d-flex flex-column gap-2">
                    <div class="d-flex flex-column">
                      <label for="email" class="form-label"
                        >Documento de identidad</label
                      >
                      <div class="position-relative">
                        <i
                          class="fa-solid fa-address-card position-absolute px-3 d-flex align-items-center justify-content-center h-100"
                        ></i>
                        <input
                          formControlName="identificationCustomer"
                          id="identification"
                          maxlength="10"
                          minlength="10"
                          required
                          class="form-control"
                          type="number"
                          placeholder="Documento de Identidad"
                          aria-placeholder="Cédula"
                          autofocus
                        />
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <span>
                        <a
                          class="link"
                          href="https://wa.link/jk2a0n"
                          target="_blank"
                          rel="noopener noreferrer"
                          >¿Necesitas ayuda?</a
                        >
                      </span>
                    </div>
                    <button class="btn-payment" type="submit">Buscar</button>
                    <div class="d-flex align-items-center gap-3">
                      <div class="line"></div>
                      O
                      <div class="line"></div>
                    </div>
                    <div class="d-flex flex-column gap-2 w-100">
                      <a
                        [routerLink]="'/tu-credito'"
                        class="btn-register"
                        type="submit"
                        >Registrarme</a
                      >
                      <a
                      
                        [routerLink]="'/iniciar-sesion'"
                        class="btn-pay"
                        type="submit"
                        >Iniciar Sesión</a
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="d-flex justify-content-between gap-3 py-4 pb-md-0">
              <span
                ><a
                  class="link-terms"
                  href="https://somosziro.com/terminos-y-condiciones/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Términos y Condiciones</a
                ></span
              >
              <span
                ><a
                  class="link-terms"
                  href="https://somosziro.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >© somosziro</a
                ></span
              >
            </div>
          </div>
          <!-- FORMULARIO PARA ENVIAR EL NUMERO DE IDENTIFICACION-->

          <div class="d-flex flex-column gap-2 h-100" *ngIf="stepGeneral === 2">
            <div class="col h-100">
              <!-- SIN CREDITOS ACTIVOS POR PAGAR -->
              <div
                class="d-flex flex-column justify-content-between justify-content-sm-center h-100 gap-3 px-3 p-md-0 "
                *ngIf="customerWhitoutCredits"
              >
                <div></div>
                <div class="d-flex flex-column gap-2">
                  <header>
                    <h3 class="fs-3 m-0 pt-1 pb-3">
                      {{ customer.name }} {{ customer.last_name }}!
                    </h3>
                    <p class="fs-6 m-0 pb-1">
                      ¡Te comunicamos que actualmente no cuentas con ningún
                      <span>crédito</span> pendiente de <span>pago</span> en tu
                      <span>cuenta!</span>. <br />
                    </p>
                  </header>
                  <div class="d-flex flex-column gap-2 w-100">
                    <button
                      type="button"
                      (click)="finishPayment()"
                      class="btn-pay"
                    >
                      Volver
                    </button>
                  </div>
                  <div class="d-flex justify-content-end">
                    <span>
                      <a
                        class="link"
                        href="https://wa.link/jk2a0n"
                        target="_blank"
                        rel="noopener noreferrer"
                        >¿Necesitas ayuda?</a
                      >
                    </span>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-between gap-3 pt-4 pb-4 pb-md-0"
                >
                  <span
                    ><a
                      class="link-terms"
                      href="https://somosziro.com/terminos-y-condiciones/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Términos y Condiciones</a
                    ></span
                  >
                  <span
                    ><a
                      class="link-terms"
                      href="https://somosziro.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >© somosziro</a
                    ></span
                  >
                </div>
              </div>

              <!-- CON CREDITOS ACTIVOS POR PAGAR  -->
              <div
                class="d-flex flex-column justify-content-between justify-content-sm-center h-100 gap-3"
                *ngIf="!customerWhitoutCredits"
              >
                <div class="d-flex flex-column gap-3 w-100 px-3">
                  <div class="d-flex gap-3 align-items-center pb-3">
                    <i class="fa-solid fa-circle-user fs-1"></i>
                    <span class="fs-4"
                      >{{ customer.name }} {{ customer.last_name }}</span
                    >
                  </div>

                  <!-- PANTALLA 1 CREDITOS ANTIGUOS EN ZÍRO  -->
                  <h3 *ngIf="stepCustomerPayment === 2">
                    Selecciona el crédito que deseas pagar
                  </h3>
                  <!-- PANTALLA 1 CREDITOS ANTIGUOS EN ZÍRO  -->

                  <!-- PANTALLA 2 NUEVOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO  -->
                  <h2 *ngIf="stepCustomerPayment === 1">
                    ¿Cuánto deseas pagar?
                  </h2>
                  <!-- PANTALLA 2 NUEVOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO  -->

                  <!-- PANTALLA 3 NUEVOS CREDITOS Y VIEJOS EN ZÍRO  -->
                  <h2 class="m-0 p-0" *ngIf="stepCustomerPayment === 3">
                    Selecciona una de la opciónes
                  </h2>

                  <!-- PANTALLA 3 NUEVOS CREDITOS Y VIEJOS EN ZÍRO  -->

                  <div class="payment">
                    <!-- PANTALLA 2 NUEVOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO -->
                    <div
                      class="target p-4 rounded-3 fs-6"
                      *ngIf="stepCustomerPayment === 1"
                    >
                      <ul class="d-flex flex-column gap-2">
                        <li>
                          Fecha límite de pago: <span>{{ next_due_date }}</span>
                        </li>
                        <!-- <li>
                          Fecha de corte: <span>{{ cutoff_date }}</span>
                        </li>
                        <li>
                          Período facturado: <span>{{ billing_period }}</span>
                        </li> -->
                      </ul>
                    </div>
                    <!-- PANTALLA 2 NUEVOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO -->

                    <!-- PANTALLA 1 CREDITOS EN TARJETAS PARA MOSTRAR CREDITOS ANTIGUOS  -->
                    <div *ngIf="stepCustomerPayment === 2">
                      <div
                        class="d-flex flex-column gap-2"
                        *ngFor="let credit of oldCredits"
                      >
                        <label class="w-100 border border-opacity-25 rounded-3">
                          <div
                            class="p-3 p-sm-3 d-flex justify-content-between align-items-center pe-auto card_payment position-relative"
                          >
                            <div class="w-100 pe-3 d-flex flex-column">
                              <h4 class="fs-5 pb-3">
                                <b>{{ credit.shop_commerce.name }}</b>
                              </h4>
                              <div class="d-flex justify-content-between">
                                <h3 class="fw-normal fs-6">Obligación:</h3>
                                <h3 class="fw-normal fs-6">
                                  <b>{{ credit.code_pay }} </b>
                                </h3>
                              </div>

                              <div class="d-flex justify-content-between">
                                <h3 class="fw-normal fs-6">Pago mínimo:</h3>
                                <h3 class="fw-normal fs-6">
                                  <b
                                    >
                                    {{
                                      credit.total_balance | currency : '$' : 'symbol' : '1.0-0'
                                    }}
                                  </b>
                                </h3>
                              </div>

                              <div class="d-flex justify-content-between">
                                <h3 class="fw-normal fs-6">
                                  Fecha de pago:
                                </h3>
                                <h3 class="fw-normal fs-6">
                                  <b>{{ credit.deadline }} </b>
                                </h3>
                              </div>
                            </div>
                            <input
                              style="
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                width: 20px;
                                height: 20px;
                              "
                              type="radio"
                              name="credit"
                              id="{{ credit.code_pay }}"
                              [value]="credit.total_balance"
                              name="credit"
                              (change)="selectRadioCreditOld($event, credit.deadline)"
                              (click)="sendObligation(credit.code_pay)"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <!-- PANTALLA 1 CREDITOS EN TARJETAS PARA MOSTRAR CREDITOS ANTIGUOS -->

                    <!-- PANTALLA 1 CREDITOS EN TARJETAS PARA MOSTRAR CREDITOS ANTIGUOS  -->
                    <div *ngIf="activeCreditsNew">
                      <div
                        class="d-flex flex-column gap-2"
                        *ngFor="let credit of newCredits"
                      >
                        <label
                          for="{{ credit.code_pay }}"
                          class="w-100 border border-opacity-25 rounded-3"
                        >
                          <div
                            class="p-3 p-sm-3 d-flex justify-content-between align-items-center pe-auto card_payment position-relative"
                          >
                            <div class="w-100 pe-3 d-flex flex-column">
                              <h4 class="fs-5 pb-3">
                                <b>{{ credit.shop_commerce }}</b>
                              </h4>
                              <div class="d-flex justify-content-between">
                                <h3 class="fw-normal fs-6">Obligación:</h3>
                                <h3 class="fw-normal fs-6">
                                  <b>{{ credit.code_pay }} </b>
                                </h3>
                              </div>

                              <div class="d-flex justify-content-between">
                                <h3 class="fw-normal fs-6">Pago mínimo:</h3>
                                <h3 class="fw-normal fs-6">
                                  <b
                                    >
                                    {{
                                      credit.total_balance  | currency : '$' : 'symbol' : '1.0-0'
                                    }}
                                  </b>
                                </h3>
                              </div>

                              <div class="d-flex justify-content-between">
                                <h3 class="fw-normal fs-6">
                                  Fecha de Crédito:
                                </h3>
                                <h3 class="fw-normal fs-6">
                                  <b>{{ credit.deadline }} </b>
                                </h3>
                              </div>
                            </div>
                            
                            <input
                              style="
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                width: 20px;
                                height: 20px;
                              "
                              type="radio"
                              name="credit"
                              id="{{ credit.code_pay }}"
                              [value]="credit.value_pending"
                              name="credit"
                              (change)="selectRadioCreditOld($event, credit.deadline)"
                              (click)="sendObligation(credit.code_pay)"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <!-- PANTALLA 1 CREDITOS EN TARJETAS PARA MOSTRAR CREDITOS ANTIGUOS -->

                    <!-- PANTALLA 2 NUEVOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO  -->
                    <div
                      *ngIf="stepCustomerPayment === 1"
                      class="border border-opacity-25 rounded-3"
                    >
                      <div
                        (click)="payData(min_payment)"
                        class="p-3 p-sm-3 d-flex justify-content-between align-items-center border-bottom border-opacity-25 pe-auto card_payment"
                      >
                        <div class="d-flex flex-column">
                          <h4 class="fs-6">Pago mínimo</h4>
                          <h3 class="fw-normal">
                            <b
                              >
                              {{ min_payment | currency : '$' : 'symbol' : '1.0-0' }}
                            </b>
                          </h3>
                          <p class="m-0 p-0 fs-6">Tu cuota para estar al día</p>
                        </div>
                        <i class="fa-solid fa-chevron-right fs-6 pe-1"></i>
                      </div>

                      <div
                        (click)="payData(total_payment)"
                        class="p-3 p-sm-3 d-flex justify-content-between align-items-center border-bottom border-opacity-25 pe-auto card_payment"
                      >
                        <div class="d-flex flex-column">
                          <h4 class="fs-6">Pago total</h4>
                          <h3 class="fw-normal">
                            <b> {{ total_payment | currency : '$' : 'symbol' : '1.0-0' }}</b>
                          </h3>
                          <p class="m-0 p-0 fs-6">
                            Deuda total hoy para liberar cupo
                          </p>
                        </div>
                        <i class="fa-solid fa-chevron-right fs-6 pe-1"></i>
                      </div>

                      <div
                        (click)="pay_my_debt()"
                        class="p-3 p-sm-3 d-flex justify-content-between align-items-center pe-auto card_payment"
                      >
                        <div class="d-flex flex-column">
                          <h3 class="fs-5">Abonar a mi deuda</h3>
                          <p class="m-0 p-0 fs-6">
                            Personaliza el control de tus pagos a tu manera
                          </p>
                        </div>
                        <i class="fa-solid fa-chevron-right fs-6 pe-1"></i>
                      </div>
                    </div>

                    <div *ngIf="payMyDebtValue" class="d-flex flex-column gap-2 ">
                      <div class="d-flex flex-column gap-2">
                        <div class="position-relative">
                          <i
                            class="fa-solid fa-address-card position-absolute px-3 d-flex align-items-center justify-content-center h-100"
                          ></i>
                          <input
                            type="number"
                            autofocus
                            class="form-control"
                            name="debt"
                            id="abono"
                            placeholder="Valor entre $5.000 y (Pago total)"
                            [(ngModel)]="debt"
                            [value]="debt"
                            (change)="debtValue($event)"
                          />
                        </div>
                        
                      </div>
                      <button
                        type="submit"
                        class="btn-payment"
                        (click)="payData(debt)"
                      >
                        Iniciar Pago
                      </button>
                    </div>
                    <!-- PANTALLA 2 NUEVOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO  -->

                    <!-- PANTALLA 3 NUEVOS Y ANTIGUOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO  -->
                    <div
                      *ngIf="stepCustomerPayment === 3"
                      class="d-flex flex-column gap-2"
                    >
                      <p class="m-0 p-0">
                        ¡Estamos mejorando para ti! Hemos migrado a una nueva
                        plataforma y
                        <b>aún puedes tener obligaciones anteriores</b>. Si
                        realizaste compras antes del 2 de noviembre elige la
                        opción <b>pago obligaciones anteriores</b>.
                      </p>
                      <div
                        (click)="showCreditsOld()"
                        class="p-3 p-sm-3 d-flex justify-content-between align-items-center border border-opacity-25 rounded-3 pe-auto card_payment"
                      >
                        <div class="d-flex flex-column">
                          <h4 class="fs-6">Pago obligaciones anteriores</h4>
                          <h3 class="fw-normal"></h3>
                          <p class="m-0 p-0" style="font-size: 13px">
                            Paga tus obligaciones que fueron retiradas antes de la fecha de retiro.
                          </p>
                        </div>
                        <i class="fa-solid fa-chevron-right fs-3 pe-1"></i>
                      </div>

                      <div
                        (click)="
                          min_payment !== null
                            ? sendPayValue(min_payment)
                            : null
                        "
                        class="p-3 p-sm-3 d-flex justify-content-between align-items-center pe-auto border border-opacity-25 rounded-3 card_payment"
                      >
                        <div class="d-flex flex-column">
                          <h4 class="fs-6">Pago nuevas obligaciones</h4>
                          <h3 class="fw-normal">
                            <b>{{ min_payment | currency : '$' : 'symbol' : '1.0-0' }}</b>
                          </h3>
                          <p class="m-0 p-0 fs-6">Tu cuota para estar al día</p>
                        </div>
                        <i class="fa-solid fa-chevron-right fs-3 pe-1"></i>
                      </div>
                    </div>
                    <!-- PANTALLA 3 NUEVOS Y ANTIGUOS CREDITOS EN LA NUEVA LOGICA EN ZÍRO  -->

                    <div
                      class="d-flex justify-content-between gap-1 bg-ziro rounded-4 mb-3"
                    >
                      <div
                        class="d-flex flex-column m-0 text-white py-4 ps-4 gap-2"
                        style="width: 60%"
                      >
                        <h3 class="fs-5">¿Tienes alguna duda?</h3>
                        <p class="fs-6">
                          Comunícate con uno de nuestros asesores.
                        </p>
                        <a
                          href="https://wa.link/jk2a0n"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="bg-white text-dark px-1 py-2 rounded-4 text-center"
                        >
                          Servicio al cliente
                        </a>
                      </div>
                      <img
                        class="img-fluid object-fit-cover"
                        src="https://res.cloudinary.com/dovavvnjx/image/upload/v1697211736/viejito_zfyg83.png"
                        alt="Servicio al cliente"
                      />
                    </div>
                  </div>

                  <div
                    class="d-flex justify-content-between gap-3 py-4 pb-md-0"
                  >
                    <span
                      ><a
                        class="link-terms"
                        href="https://somosziro.com/terminos-y-condiciones/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Términos y Condiciones</a
                      ></span
                    >
                    <span
                      ><a
                        class="link-terms"
                        href="https://somosziro.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >© somosziro</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-0 col-sm-4 col-md-7 h-100 p-0 m-0 d-none d-sm-block"
      style="background-color: #fff7e1"
    >
      <div
        class="d-flex flex-column justify-content-between align-items-start gap-3 h-100 w-100"
      >
        <img
          class="img-fluid p-3 pt-4 ps-4"
          style="width: 10rem"
          src="../../../assets/img-news/logo-ilustration.svg"
          alt="Logo"
        />
        <img
          class="img-fluid w-100"
          src="../../../assets/img-news/payment.svg"
          alt="Login"
        />
      </div>
    </div>
  </div>
</div>

<app-modal-payment></app-modal-payment>
<app-modal-alert></app-modal-alert>

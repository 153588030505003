<ul *ngIf="paginas.length > 0" class="pagination mt-3 justify-content-end">
  <!-- Botón para ir a la primera página -->
  <li [ngClass]="paginador.current_page === 1 ? 'disabled page-item' : 'page-item'">
    <a class="page-link cursor-pointer" (click)="changePage(1)">
      <i class="fa fa-angle-double-left"></i>
    </a>
  </li>
  
  <!-- Botón para ir a la página anterior -->
  <li class="page-item" *ngIf="paginador.current_page > 1">
    <a class="page-link cursor-pointer"  (click)="changePage(paginador.current_page - 1)">&laquo;</a>
  </li>

  <!-- Lista de páginas -->
  <li *ngFor="let pagina of paginas" [ngClass]="pagina === paginador.current_page ? 'page-item active' : 'page-item'">
    <span class="page-link" *ngIf="pagina === paginador.current_page">{{ pagina }}</span>
    <a *ngIf="pagina !== paginador.current_page" class="page-link cursor-pointer" (click)="changePage(pagina)">
      {{ pagina }}  
    </a>
  </li>

  <!-- Botón para ir a la página siguiente -->
  <li class="page-item" *ngIf="paginador.current_page < paginador.last_page">
    <a class="page-link cursor-pointer" (click)="changePage(paginador.current_page + 1)">&raquo;</a>
  </li>

  <!-- Botón para ir a la última página -->
  <li [ngClass]="paginador.current_page === paginador.last_page ? 'disabled page-item' : 'page-item'">
    <a class="page-link cursor-pointer" (click)="changePage(paginador.last_page)">
      <i class="fa fa-angle-double-right"></i>
    </a>
  </li>
</ul>

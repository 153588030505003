<div class="third_page">
  <div class="important_six">
    <span (click)="decrementar()"
      ><i class="fa-solid fa-circle-arrow-left"></i
    ></span>
    <img src="../../../../assets/img-news/nubes-sol.svg" alt="Nubes Sol" />
  </div>
  <div class="info_content">
    <div class="info_card">
      <div class="info">
        <h3>
          ¡Da el primer paso hacia el futuro de tu negocio 💚!
        </h3>
      </div>
      <h2>
        Somos un método de pago para abastecer tu negocio desde 0% interés. <br><br>
        <p>Aplica únicamente con tus proveedores aliados a zíro</p>
        <a
          target="_blank"
          href="https://somosziro.com/terminos-y-condiciones/"
          rel="noopener noreferrer"
        >
          Términos y Condiciones
        </a>
      </h2>
    </div>
  </div>
  <div class="info_footer">
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
    <button type="button" (click)="incrementar()">Siguiente</button>
  </div>
</div>

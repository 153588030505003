import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import {
  toggleLoader,
  validateCampo,
} from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { RecoveryPasswordService } from "./services/recovery-password.service";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";

@Component({
  selector: "app-recovery-password",
  templateUrl: "./recovery-password.component.html",
  styleUrls: ["./recovery-password.component.scss"],
})
export class RecoveryPasswordComponent {
  public show: boolean = false;
  public recoveryPasswordForm: FormGroup;
  public errorMessage: any;
  public isMenuOpen: boolean = true;
  public email: string = "";
  validateCampo = validateCampo;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private loaderService: LoaderService,
    private emailService: RecoveryPasswordService,
    private modalService: ModalServiceAlert
  ) {}

  //ngOnInit
  ngOnInit() {
    this.recoveryPasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  sendEmail() {
    if (this.recoveryPasswordForm.invalid) {
      this.modalService.openModal(
        "Alerta",
        "Por favor, completa correctamente todos los campos.",
        "warning"
      );
      return;
    }
    toggleLoader(
      this.loaderService,
      true,
      "¡Estamos enviando a tu correo electrónico las indicaciones para restablecer la contraseña!"
    );
    this.email = this.recoveryPasswordForm.value.email; // Cambia esto por el email que desees enviar
    this.emailService.sendEmail(this.email).subscribe(
      (response) => {
        toggleLoader(
          this.loaderService,
          false,
          "¡Restableciendo tu nueva contraseña!"
        );

        this.modalService.openModal(
          "¡Correo enviado!",
          "Se ha enviado un correo con las instrucciones para restablecer tu contraseña.",
          "success"
        );

        this.router.navigate(["/iniciar-sesion"]);
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        if (error.status === 300) {
          this.modalService.openModal(
            "Alerta",
            error.error.message,
            "warning",
            10000
          );
        } else {
          this.modalService.openModal("Error", error.error.message, "error");
        }
      }
    );
  }

  backToLogin() {
    this.router.navigate(["/iniciar-sesion"]);
  }
}

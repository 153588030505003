<div class="second_page">
  <div class="important">
    <h1>¡Importante!</h1>
  </div>
  <div class="info_content">
    <div class="info_card">
      <img
        src="../../../../../assets/img-news/Feliz ziro.svg"
        alt="Carita zíro Feliz"
      />
      <div><h2>Tendrás un cupo 100% rotativo</h2></div>
    </div>

    <div class="info_card">
      <img
        src="../../../../../assets/img-news/Dinero ziro.svg"
        alt="Dinero zíro"
      />
      <div>
        <h2>
          Si no haces tu pago a tiempo incurrirás en intereses y otros costos
          adicionales
        </h2>
      </div>
    </div>

    <div class="info_card">
      <img src="../../../../../assets/img-news/like ziro.svg" alt="Like zíro" />
      <div>
        <h2>Debes pagar tus créditos directamente a zíro.</h2>
      </div>
    </div>
  </div>
  <div class="info_footer">
    <a
      target="_blank"
      href="https://somosziro.com/terminos-y-condiciones/"
      rel="noopener noreferrer"
      >Términos y Condiciones</a
    >
    <button type="button" (click)="incrementar()">Siguiente</button>
  </div>
</div>

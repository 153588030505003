import { Component } from '@angular/core';
import { CommerceDashboardService } from '../../services/commerce-dashboard.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-commerce-dashboard-home',
  templateUrl: './commerce-dashboard-home.component.html',
  styleUrls: ['./commerce-dashboard-home.component.scss']
})
export class CommerceDashboardHomeComponent {

    totalCustomers: number=0;
    withdrawnCredits: number=0;
    withdrawnCreditsCount: number=0;
    creditsRequestsCount: number=0;

    /**
	 * Constructor de la clase CustomerDashboardComponent.
	 *
	 * Este constructor inicializa la instancia de la clase CustomerDashboardComponent
	* y se encarga de inyectar los servicios y recursos necesarios para el componente.
	 */
	constructor(
		private commerceDashboardService: CommerceDashboardService,
		private loaderService: LoaderService,
	) {}

    /**
	 * Método ngOnInit de la clase PaymentsAlliesComponent.
	 *
	 * Este método se ejecuta durante la inicialización del componente y se encarga de manejar la lógica inicial.
	 * Inicializa el indicador de carga y obtiene los créditos.
	 */
	ngOnInit(): void {
		toggleLoader(this.loaderService, true);
		this.getDataDashboard();
	}

    
    /**
     * Obtiene los datos del panel de control del comercio
     * Actualiza las propiedades del componente con la respuesta del servicio.
    */
    getDataDashboard(): void {
        // Activa el loader
        toggleLoader(this.loaderService, true);
        const messageError="Ocurrió un error al consultar la información del cliente.";
        // Realiza la solicitud para obtener datos del panel de control del cliente
        this.commerceDashboardService.getDataDashboard().subscribe(
            (response) => {
                if (response.ok) {
                    // Desactiva el loader
                    toggleLoader(this.loaderService, false);                    

                    // Maneja la respuesta del servicio y actualiza las propiedades del componente
                    this.totalCustomers = response.data.totalCustomers;
                    this.withdrawnCredits = response.data.withdrawnCredits;
                    this.withdrawnCreditsCount = response.data.withdrawnCreditsCount;
                    this.creditsRequestsCount = response.data.creditsRequestsCount;
                } else {
                    // Desactiva el loader y muestra un mensaje de error en caso de respuesta no exitosa
                    toggleLoader(this.loaderService, false);
                    Swal.fire(
                        "Error",
                        response.message || messageError,
                        "error"
                    );
                }
            },
            (error) => {
                // Desactiva el loader y maneja el error del servicio
                toggleLoader(this.loaderService, false);
                Swal.fire(
                    "Error",
                    error.error.message || messageError,
                    "error"
                );
            }
        );
    }

}

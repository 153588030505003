import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CustomerPaymentMajorPaginatedInterface } from "../interfaces/Payments-customer.interface";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { CreditResponseInterface } from "../../credits/interfaces/credits.interface";

@Injectable({
	providedIn: "root",
})
export class PaymentsCustomerServices {
	private baseUrl: string = environment.apiUrl + "/pagos-clientes";
	private flagSearch: boolean = false;

	paymentsCustomerFilter: FormGroup;

	/**
	 * Constructor de la clase [NombreDeLaClase].
	 *
	 * @param {HttpClient} http - Servicio HTTP para realizar solicitudes.
	 */
	constructor(private http: HttpClient, private fb: FormBuilder) {
		this.paymentsCustomerFilter = this.fb.group({
			dateStart: [""],
			dateEnd: [""],
			paymentValue: [""],
			identification: [""],
		});
	}

	setFlagSearch(value: boolean): void {
		this.flagSearch = value;
	}

	getFlagSearch(): boolean {
		return this.flagSearch;
	}

	/**
	 * Método para obtener la lista de pagos de aliados.
	 *
	 * @returns {Observable<CustomerPaymentMajorPaginatedInterface>} Observable que emite la lista paginada de pagos de aliados.
	*/
	getPaymentsCustomer() {
		return this.http.get<CustomerPaymentMajorPaginatedInterface>(
			this.baseUrl
		);
	}

    /**
     * Obtiene pagos de un cliente con parámetros de filtro.
     *
     * @param page Número de página para la paginación.
     * @param dateStart Fecha de inicio del período de búsqueda.
     * @param dateEnd Fecha de fin del período de búsqueda.
     * @param paymentValue Valor del pago a buscar.
     * @param identification Identificación asociada a los pagos.
     * @returns Observable<any> Observable que emite la respuesta de la solicitud HTTP.
     *
     * Esta función construye los parámetros de la solicitud HTTP y realiza una llamada GET
     * al servicio correspondiente utilizando Angular HttpClient. Se espera que la respuesta
     * contenga datos relevantes para los pagos del cliente con los filtros aplicados.
    */
	getPaymentsCustomerWithFilter(
		page: number,
		dateStart: string,
		dateEnd: string,
		paymentValue: string,
		identification: string
	): Observable<any> {
		const params = new HttpParams()
			.set("page", page)
			.set("dateStart", dateStart)
			.set("dateEnd", dateEnd)
			.set("identification", identification)
			.set("paymentValue", paymentValue);

		return this.http.get(`${this.baseUrl}`, { params });
	}

	/**
	 * Método para obtener la lista de pagos de aliados filtrada por fecha y proveedor.
	 *
	 * @param {Date} date_start - Fecha de inicio para el filtro.
	 * @param {Date} date_end - Fecha de fin para el filtro.
	 * @param {number} paymentValue - valor pagado para el filtro.
	 *
	 * @returns {Observable<any>} Observable que emite la lista paginada de pagos de aliados filtrada.
	 */
	getPaymentsCustomerFilter(
		date_start: Date,
		date_end: Date,
		paymentValue: number
	) {
		// URL del servicio
		const url = `${this.baseUrl}/buscar`;

		// Parámetros de la solicitud
		let params = new HttpParams();
		params = params.append("date_start", date_start.toString());
		params = params.append("date_end", date_end.toString());
		params = params.append("payment_value", paymentValue.toString());

		// Realizar la solicitud
		return this.http.get<any>(url, { params });
	}

	/**
	 * Elimina un pago de crédito utilizando el identificador proporcionado.
	 *
	 * @param creditPaymentId - El identificador del pago de crédito que se eliminará.
	 * @returns Una solicitud HTTP DELETE a la URL correspondiente y espera la respuesta de tipo CreditResponseInterface.
	 */
	deletePayment(creditPaymentId: number) {
		const url = `${this.baseUrl}/eliminar/${creditPaymentId}`;
		return this.http.delete<CreditResponseInterface>(url);
	}
}

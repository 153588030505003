import { Component, ViewChild } from '@angular/core';
import { CustomersSearchComponent } from '../../../modals/customers-search/customers-search.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent  {

    //variables
    @ViewChild(CustomersSearchComponent) customersSearchComponent!: CustomersSearchComponent;

    /*****************
     ** **metodos*****
    ****************/

    //abrir modal
    openModalCustomerSearch() {
        //vaciar la variable del modal ng
        this.customersSearchComponent.openModalCustomerSearch();
    }

}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfirmPasswordService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getTokenChangePassword(token: string): Observable<any> {
    const url = `${this.baseUrl}/token-change-password/${token}`;
    return this.http.get<any>(url);
  }

  updatePassword(token: string, newPassword: string): Observable<any> {
    const url = `${this.baseUrl}/update-password`;
    const data = {
      token_change_password: token,
      password: newPassword,
    };
    return this.http.post<any>(url, data);
  }
}

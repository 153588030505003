<div class="alert-error-background">
  <div class="alert-error">
    <span>
      <i class="fa-solid fa-circle-xmark"></i>
    </span>

    <div class="error-image">
      <img src="../../../../assets/img-news/alert-error.svg" alt="Fondo auto" />
    </div>

    <div class="error-content">
      <h2>{{titulo}}</h2>
      <p>
        {{descripcion}}
      </p>
    </div>

    <div class="input-button">
      <button type="button" (click)="this.StepService.setStep(5)">
        {{textoBoton}}
      </button>
    </div>
  </div>
</div>

<div class="input-group">
   <button class="btn button d-flex align-items-center gap-2"
      (click)="openModalCustomerSearch()">
      <i class="fa fa-search fw-light"></i> 
      Buscar cliente
   </button>
</div>

<!-- modal buscar cliente -->
<app-customers-search/>

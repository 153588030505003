import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/auth/login/services/auth.service";

export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}
@Injectable({
  providedIn: "root",
})
export class NavService {
  /**
   * Subject utilizado para desuscribirse de observables y evitar fugas de memoria.
   * @type {Subject<any>}
   */
  private unsubscriber: Subject<any> = new Subject();

  /**
   * BehaviorSubject que contiene el ancho actual de la pantalla.
   * Se inicializa con el ancho de la ventana actual al cargar el componente.
   * @type {BehaviorSubject<number>}
   */
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  /**
   * Indica si la búsqueda está activa o no.
   * @type {boolean}
   */
  public search: boolean = false;

  /**
   * Indica si el menú lateral (sidebar) debe estar colapsado o no.
   * Se inicializa en 'true' para pantallas con ancho menor a 991px.
   * @type {boolean}
   */
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  /**
   * Indica si se debe usar el diseño horizontal en dispositivos móviles.
   * Se inicializa en 'false' para pantallas con ancho menor a 991px.
   * @type {boolean}
   */
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  /**
   * Indica si la aplicación está en modo de pantalla completa.
   * @type {boolean}
   */
  public fullScreen: boolean = false;

  /**
   * BehaviorSubject que contiene los ítems del menú para el usuario con el rol de administrador.
   * @type {BehaviorSubject<Menu[]>}
   */
  public itemsAdmin = new BehaviorSubject<Menu[]>([]);

  /**
   * BehaviorSubject que contiene los ítems del menú para el usuario con el rol de cliente.
   * @type {BehaviorSubject<Menu[]>}
   */
  public itemsCustomer = new BehaviorSubject<Menu[]>([]);

  /**
   * BehaviorSubject que contiene los ítems del menú para el usuario con el rol de comercio.
   * @type {BehaviorSubject<Menu[]>}
   */
  public itemsCommerce = new BehaviorSubject<Menu[]>([]);

  /**
   * BehaviorSubject que contiene los ítems del menú para el usuario con el rol de analista.
   * @type {BehaviorSubject<Menu[]>}
   */
  public itemsAnalyst = new BehaviorSubject<Menu[]>([]);

  /**
   * BehaviorSubject que contiene los ítems del menú del usuario actual.
   * Esta variable se usará para almacenar el BehaviorSubject correspondiente según el rol del usuario.
   * @type {BehaviorSubject<Menu[]>}
   */
  public items: BehaviorSubject<Menu[]>;

  /**
   * Obtiene la información del usuario autenticado actual.
   * @type {any} - Podrías especificar un tipo más detallado si tienes uno definido para 'user'.
   */
  public user = this.authService.getUser();

  /**
   * Identificador del rol del usuario autenticado.
   * Si el usuario no está autenticado o no tiene un 'role_id', se establece en 'null'.
   * @type {number | null}
   */
  public role = this.user ? this.user.role_id : null;

  /**
   * Constructor de la clase.
   *
   * Inicializa la instancia, suscribiéndose a eventos de redimensionamiento de ventana para gestionar
   * el estado del menú lateral y asigna el menú correspondiente basado en el rol del usuario.
   *
   * @param {Router} router - El router de Angular para gestionar eventos de navegación.
   * @param {AuthService} authService - Servicio de autenticación para gestionar la autenticación del usuario.
   * @returns {TuClase} Retorna una instancia de la clase.
   */
  constructor(private router: Router, private authService: AuthService) {
    // Establece el ancho de pantalla inicial
    this.setScreenWidth(window.innerWidth);

    // Suscripción al evento de redimensionado de la ventana
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        // Actualiza el ancho de pantalla cuando la ventana cambia de tamaño
        this.setScreenWidth(evt.target.innerWidth);

        // Si el ancho de la ventana es menor a 991, colapsa la barra lateral
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
        }
        if (evt.target.innerWidth < 1199) {
          // Aquí parece que falta alguna acción si el ancho es menor a 1199
        }
      });

    // Si el ancho de la ventana es menor a 991, al cambiar de ruta, colapsa la barra lateral
    if (window.innerWidth < 991) {
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
      });
    }

    // Inicializa 'this.items' como un BehaviorSubject vacío
    this.items = new BehaviorSubject<Menu[]>([]);

    // Asigna el menú correspondiente según el rol del usuario
    switch (this.role) {
      case 1:
        this.items = this.itemsAdmin;
        break;
      case 3:
        this.items = this.itemsAnalyst;
        break;
      case 5:
        this.items = this.itemsCustomer;
        break;
      case 4:
      case 6:
      case 7:
        this.items = this.itemsCommerce;
        break;
      default:
        // Si 'role' no tiene valor asignado, se establece 'items' como BehaviorSubject vacío
        this.items = new BehaviorSubject<Menu[]>([]);
        break;
    }

    // Asigna el valor inicial del BehaviorSubject 'items'
    this.items.next(this.getMenuItems());
  }

  /**
   * Obtiene los ítems del menú basado en el rol del usuario.
   *
   * @returns {Menu[]} Retorna un arreglo de ítems del menú correspondientes al rol del usuario.
   */
  private getMenuItems(): Menu[] {
    // Devolver el menú correspondiente según el rol
    switch (this.role) {
      case 1:
        return this.MENUITEMSADMIN;
      case 3:
        return this.MENUITEMSANALYST;
      case 5:
        return this.MENUITEMSCUSTOMER;
      case 4:
      case 6:
      case 7:
        return this.MENUITEMSCOMMERCE;
      default:
        return []; // Retorna un arreglo vacío si no coincide con ningún rol
    }
  }

  /**
   * Establece el ancho de pantalla y emite el valor actualizado a través de un BehaviorSubject.
   *
   * @param {number} width - El nuevo ancho de pantalla a establecer.
   * @returns {void}
   */
  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  /**
   * Actualiza los ítems del menú basándose en el rol proporcionado y emite los nuevos ítems
   * a través del BehaviorSubject.
   *
   * @param {number} role - El rol del usuario basado en el cual se seleccionarán los ítems del menú.
   * @returns {void}
   */
  public updateMenu(role: number): void {
    switch (role) {
      case 1:
        this.items.next(this.MENUITEMSADMIN);
        break;
      case 3:
        this.items.next(this.MENUITEMSANALYST);
        break;
      case 5:
        this.items.next(this.MENUITEMSCUSTOMER);
        break;
      case 4:
      case 6:
      case 7:
        this.items.next(this.MENUITEMSCOMMERCE);
        break;
      default:
        this.items.next([]); // Si no coincide con ningún rol, emite un arreglo vacío
        break;
    }
  }

  /**
   * Definición de los ítems del menú para el usuario con el rol de administrador.
   *
   * @type {Menu[]}
   * - `title`: Título del ítem del menú.
   * - `icon`: Nombre del icono asociado al ítem.
   * - `type`: Tipo del ítem. Puede ser "link" para enlaces directos o "sub" para submenús.
   * - `active`: Indica si el ítem del menú está activo.
   * - `path`: Ruta asociada al ítem del menú.
   * - `children`: Si el ítem es de tipo "sub", define los sub-ítems.
   */
  MENUITEMSADMIN: Menu[] = [
    {
      title: "Inicio",
      icon: "home",
      type: "link",
      active: true,
      path: "/panel/inicio",
    },
    {
      title: "Clientes",
      icon: "users",
      type: "link",
      active: false,
      path: "/clientes/listado",
    },
    {
      title: "Clientes api test",
      icon: "users",
      type: "link",
      active: false,
      path: "/clientes-api-test/listado",
    },
    {
      title: "Créditos",
      icon: "cart",
      type: "link",
      active: false,
      path: "/creditos/listado",
    },
    {
      title: "Códigos OTP",
      icon: "bonusui",
      type: "link",
      active: false,
      path: "/codigos-otp/listado/1",
    },
    {
      title: "Empleados",
      icon: "users",
      type: "link",
      active: false,
      path: "/empleados/listado",
    },
    {
      title: "Cobranza",
      icon: "other",
      type: "sub",
      active: false,
      children: [
        {
          title: "Bandeja entrada",
          path: "/cobranza/inicio",
          type: "link",
        },

        {
          title: "Parametros",
          path: "/cobranza/parametros",
          type: "link",
        },
      ],
    },
    {
      title: "Informes",
      icon: "project",
      type: "sub",
      active: false,
      children: [
        {
          title: "Cartera",
          path: "/reportes/cartera",
          type: "link",
        },
        {
          title: "Cupos",
          path: "/reportes/cupos",
          type: "link",
        },
        {
          title: "Conciliación",
          path: "/proveedores/reporte/listado/1",
          type: "link",
        },
      ],
    },
    {
      title: "Lineas de crédito",
      icon: "buttons",
      type: "link",
      active: false,
      path: "/lineas-credito/listado",
    },
    {
      title: "Pagos clientes",
      icon: "money",
      type: "link",
      active: false,
      path: "/pagos-clientes/listado",
    },
    {
      title: "Pagos proveedores",
      icon: "money",
      type: "link",
      active: false,
      path: "/proveedores-pagos/listado",
    },
    {
      title: "Parametros",
      icon: "other",
      type: "link",
      active: false,
      path: "/parametros",
    },
    {
      title: "Proveedores",
      icon: "users",
      type: "link",
      active: false,
      path: "/proveedores-aliados/listado",
    },
    {
      title: "Solicitudes",
      icon: "page",
      type: "sub",
      active: false,
      children: [
        {
          title: "Por Lista",
          path: "/solicitudes-credito/listado/1",
          type: "link",
        },
        {
          title: "Por Tarjeta",
          path: "/solicitudes-credito/tarjeta",
          type: "link",
        },
      ],
    },
    {
      title: "Sucursales",
      icon: "ecommerce",
      type: "link",
      active: false,
      path: "/sucursales/listado/1",
    },
    {
      title: "Usuarios",
      icon: "users",
      type: "link",
      active: false,
      path: "/usuarios/listado",
    },
  ];

  /**
   * Definición de los ítems del menú para el usuario con el rol de cliente.
   *
   * @type {Menu[]}
   * - `title`: Título del ítem del menú.
   * - `icon`: Nombre del icono asociado al ítem.
   * - `type`: Tipo del ítem. Puede ser "link" para enlaces directos.
   * - `active`: Indica si el ítem del menú está activo.
   * - `path`: Ruta asociada al ítem del menú.
   */
  MENUITEMSCUSTOMER: Menu[] = [
    {
      title: "Inicio",
      icon: "home",
      type: "link",
      active: true,
      path: "/clientes/inicio",
    },
    {
      title: "Solicitudes",
      icon: "page",
      type: "link",
      active: false,
      path: "/clientes/solicitudes-credito/listado/1",
    },
    {
      title: "Créditos",
      icon: "cart",
      type: "link",
      active: false,
      path: "/creditos/listado/1",
    },

    {
      title: "Pagos",
      icon: "users",
      type: "link",
      active: false,
      path: "/clientes/pagos/listado/1",
    },
  ];

  /**
   * Definición de los ítems del menú para el usuario con el rol de analista.
   *
   * @type {Menu[]}
   * - `title`: Título del ítem del menú.
   * - `icon`: Nombre del icono asociado al ítem.
   * - `type`: Tipo del ítem. Puede ser "link" para enlaces directos o "sub" para submenús.
   * - `active`: Indica si el ítem del menú está activo.
   * - `path`: Ruta asociada al ítem del menú.
   * - `children`: Si el ítem es de tipo "sub", define los sub-ítems.
   */
  MENUITEMSANALYST: Menu[] = [
    {
      title: "Inicio",
      icon: "home",
      type: "link",
      active: true,
      path: "/panel/inicio",
    },
    {
      title: "Solicitudes de cupo",
      icon: "page",
      type: "sub",
      active: false,
      children: [
        {
          title: "Por Lista",
          path: "/solicitudes-credito/listado/1",
          type: "link",
        },
        {
          title: "Por Tarjeta",
          path: "/solicitudes-credito/tarjeta",
          type: "link",
        },
      ],
    },
  ];

  /**
   * Definición de los ítems del menú para el usuario con el rol de comercio o proveedor.
   *
   * @type {Menu[]}
   * - `title`: Título del ítem del menú.
   * - `icon`: Nombre del icono asociado al ítem.
   * - `type`: Tipo del ítem. Puede ser "link" para enlaces directos.
   * - `active`: Indica si el ítem del menú está activo.
   * - `path`: Ruta asociada al ítem del menú.
   */
  MENUITEMSCOMMERCE: Menu[] = [
    {
      title: "Inicio",
      icon: "home",
      type: "link",
      active: true,
      path: "/proveedores/panel/inicio",
    },
    {
      title: "Créditos",
      icon: "cart",
      type: "link",
      active: false,
      path: "/creditos/listado/1",
    },
    {
      title: "Clientes",
      icon: "users",
      type: "link",
      active: false,
      path: "/clientes/listado/1",
    },
    {
      title: "Informes",
      icon: "Internationalization",
      type: "link",
      active: false,
      path: "/proveedores/reporte/listado/1",
    },
    {
      title: "Solicitudes",
      icon: "page",
      type: "link",
      active: false,
      path: "/proveedores/solicitudes/creditos/listado/1",
    },
    {
      title: "Sucursales",
      icon: "ecommerce",
      type: "link",
      active: false,
      path: "/sucursales/listado/1",
    },
  ];
}

<div class="container-fluid vh-100">
  <div class="row h-100">
    <div
      class="col-12 col-sm-8 col-md-5 bg-white h-100 p-0 m-0 px-4"
    >
      <div
        class="d-flex justify-content-sm-center align-items-sm-center w-100 h-100 pt-sm-3 px-md-3"
      >
        <div
          class="d-flex flex-column justify-content-between gap-3 w-100 custom-width"
          style="color: #484848"
        >
          <img
            class="img-fluid pb-2 pt-4 d-sm-none"
            style="width: 8rem"
            src="../../../assets/img-news/logo-ilustration.svg"
            alt="Logo"
          />

          <div class="d-flex flex-column gap-3 ">
            <div
            class="d-flex flex-column gap-3"
            style="color: #064853"
          >
            <header>
              <h2 class="fs-3 m-0">¡Tu pago se esta procesando!</h2>
              <p class="fs-6 m-0 pt-3">
                Hemos recibido tu pago y estamos verificándolo. <br />
                Pronto recibirás un <b>correo</b> y un mensaje a tu <b>WhatsApp</b> con la confirmación de los detalles.
                <br /><br />
                ¡Gracias por tu paciencia! ❤️
              </p>
            </header>
            <button
              class="btn-waiting"
              type="submit"
              (click)="finishRedirect()"
            >
              Terminar
            </button>
          </div>


          <div class="d-flex justify-content-end">
            <span>
              <a
                class="link"
                href="https://wa.link/jk2a0n"
                target="_blank"
                rel="noopener noreferrer"
                >¿Necesitas ayuda?</a
              >
            </span>
          </div>
          </div>

          <div
            class="d-flex justify-content-between gap-3 pt-4 pb-4 pb-md-0"
          >
            <span
              ><a
                class="link-terms"
                href="https://somosziro.com/terminos-y-condiciones/"
                target="_blank"
                rel="noopener noreferrer"
                >Términos y Condiciones</a
              ></span
            >
            <span
              ><a
                class="link-terms"
                href="https://somosziro.com/"
                target="_blank"
                rel="noopener noreferrer"
                >© somosziro</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="p-0 m-0 col-0 col-sm-4 col-md-7 h-100 d-none d-sm-flex"
      style="background-color: #00ced535"
    >
      <div
        class="d-flex flex-column justify-content-between align-items-start gap-3 h-100 w-100"
      >
        <img
          class="img-fluid p-3 pt-4 ps-4"
          style="width: 10rem"
          src="../../../assets/img-news/logo-ilustration.svg"
          alt="Logo"
        />
        <img
          class="img-fluid w-100"
          src="../../../assets/img-news/gif_login.gif"
          alt="Pago exitoso"
        />
      </div>
    </div>
  </div>
</div>

<app-modal-alert></app-modal-alert>

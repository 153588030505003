import { Routes } from "@angular/router";

export const full: Routes = [
	{
		path: "error-page",
		loadChildren: () =>
			import("../../pages/error-page/error-page.module").then(
				(m) => m.ErrorPageModule
			),
	},

];

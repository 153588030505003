<div class="container-fluid vh-100">
  <div class="row h-100">
    <div class="col-12 col-sm-8 col-md-5 bg-white h-100 p-0 m-0 px-4">
      <div
        class="d-flex justify-content-sm-center align-items-sm-center w-100 h-100 pt-sm-3"
      >
        <div
          class="d-flex flex-column justify-content-between gap-3 w-100 custom-width"
          style="color: #484848"
        >
          <img
            class="img-fluid pb-2 pt-4 d-sm-none"
            style="width: 8rem"
            src="../../../assets/img-news/logo-ilustration.svg"
            alt="Logo"
          />
          <div class="d-flex flex-column gap-3" style="color: #064853">
            <header>
              <h2 class="fs-3 m-0 pb-1">Restablecer contraseña</h2>
              <p class="fs-6 m-0">
                ¡Llego el momento de <b>Restablecer</b> tu nueva contraseña!
              </p>
            </header>

            <form
              [formGroup]="recoveryConfirmPasswordForm"
              (ngSubmit)="recovery()"
            >
              <div class="d-flex flex-column gap-2">
                <div class="d-flex flex-column gap-1 pb-1">
                  <label for="password1" class="form-label m-0"
                    >Tu nueva contraseña</label
                  >
                  <div class="position-relative">
                    <i
                      class="fa-solid fa-lock position-absolute px-3 d-flex align-items-center justify-content-center h-100"
                    ></i>
                    <i
                      class="fa-solid fa-lock position-absolute end-0 px-3 d-flex align-items-center justify-content-center h-100"
                      [ngClass]="{ 'fa-eye': !show, 'fa-eye-slash': show }"
                      (click)="showPassword()"
                    ></i>
                    <input
                      [type]="show ? 'text' : 'password'"
                      class="form-control"
                      id="password1"
                      formControlName="password1"
                      placeholder="Nueva contraseña"
                      aria-placeholder="Nueva contraseña"
                    />
                  </div>
                  <p
                    role="alert"
                    class="text-danger px-3 py-1 m-0 rounded-5 mt-2 d-flex justify-content-between align-items-center gap-1"
                    style="background-color: #ffeeee; border: 1px solid #ffdfdf"
                    *ngIf="
                      (recoveryConfirmPasswordForm
                        .get('password1')
                        ?.hasError('minlength') ||
                        recoveryConfirmPasswordForm
                          .get('password1')
                          ?.hasError('maxlength')) &&
                      recoveryConfirmPasswordForm.get('password1')?.touched
                    "
                  >
                    La nueva contraseña deben ser 4 dígitos numéricos.
                    <i class="fa-solid fa-circle-exclamation"></i>
                  </p>
                </div>
                <div class="d-flex flex-column gap-1">
                  <label for="password2" class="form-label m-0"
                    >Confirma tu nueva contraseña</label
                  >
                  <div class="position-relative">
                    <i
                      class="fa-solid fa-lock position-absolute px-3 d-flex align-items-center justify-content-center h-100"
                    ></i>
                    <i
                      class="fa-solid fa-lock position-absolute end-0 px-3 d-flex align-items-center justify-content-center h-100"
                      [ngClass]="{ 'fa-eye': !show, 'fa-eye-slash': show }"
                      (click)="showPassword()"
                    ></i>
                    <input
                      [type]="show ? 'text' : 'password'"
                      class="form-control"
                      id="password2"
                      formControlName="password2"
                      placeholder="Confirma la contraseña"
                      aria-placeholder="Confirma la contraseña"
                    />
                  </div>
                  <p
                    role="alert"
                    class="text-danger px-3 py-1 m-0 rounded-5 mt-2 d-flex justify-content-between align-items-center gap-1"
                    style="background-color: #ffeeee; border: 1px solid #ffdfdf"
                    *ngIf="
                      recoveryConfirmPasswordForm.hasError('passwordMismatch')
                    "
                  >
                    Las contraseñas no coinciden.
                    <i class="fa-solid fa-circle-exclamation"></i>
                  </p>
                </div>
                <div class="d-flex justify-content-end">
                  <span>
                    <a
                      class="link"
                      href="https://wa.link/jk2a0n"
                      target="_blank"
                      rel="noopener noreferrer"
                      >¿Necesitas ayuda?</a
                    >
                  </span>
                </div>
                <button class="btn-login" type="submit">
                  Actualizar contraseña
                </button>
                <div class="d-flex align-items-center gap-3">
                  <div class="line"></div>
                  O
                  <div class="line"></div>
                </div>
                <div class="d-flex flex-column gap-2 w-100">
                  <a [routerLink]="'/restablecer-contraseña'" class="btn-cancel"
                    >Cancelar proceso</a
                  >
                </div>
              </div>
            </form>
          </div>

          <div class="d-flex justify-content-between gap-3 pt-4 pb-4 pb-md-0">
            <span
              ><a
                class="link-terms"
                href="https://somosziro.com/terminos-y-condiciones/"
                target="_blank"
                rel="noopener noreferrer"
                >Términos y Condiciones</a
              ></span
            >
            <span
              ><a
                class="link-terms"
                href="https://somosziro.com/"
                target="_blank"
                rel="noopener noreferrer"
                >© somosziro</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-0 col-sm-4 col-md-7 h-100 p-0 m-0 d-none d-sm-block"
      style="background-color: #00ced535"
    >
      <div
        class="d-flex flex-column justify-content-between align-items-start gap-3 h-100 w-100"
      >
        <img
          class="img-fluid p-3 pt-4 ps-4"
          style="width: 10rem"
          src="../../../assets/img-news/logo-ilustration.svg"
          alt="Logo"
        />
        <img
          class="img-fluid w-100"
          src="../../../assets/img-news/gif_login.gif"
          alt="Login"
        />
      </div>
    </div>
  </div>
</div>

<app-modal-alert></app-modal-alert>

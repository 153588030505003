import { Component } from '@angular/core';
import axios from 'axios';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-excel-processor',
  templateUrl: './excel-processor.component.html',
  styleUrls: ['./excel-processor.component.scss'],
})
export class ExcelProcessorComponent {
  async processExcel(fileInput: any) {
    const file = fileInput.files[0];
    const fileReader = new FileReader();

    fileReader.onload = async (e: any) => {
      const arrayBuffer = e.target.result;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);
      interface ExcelRecord {
        cedula: string;
      }
      interface ResultData {
        ultimo_ano_renovado: string;
        fecha_matricula: string;
        estado_matricula: string;
      }

      const results = [];

      for (const record of excelData as ExcelRecord[]) {
        if (record['cedula']) {
          try {
            const response = await axios.get(
              `https://www.datos.gov.co/resource/c82u-588k.json?nit=${record['cedula']}`
            );

            let result: ResultData = {
              ultimo_ano_renovado: 'N/A',
              fecha_matricula: 'N/A',
              estado_matricula: 'N/A',
            };

            if (Array.isArray(response.data) && response.data.length > 0) {
              result = response.data[0];
            }

            results.push({
              cedula: record['cedula'],
              ultimo_ano_renovado: result.ultimo_ano_renovado || 'N/A',
              fecha_de_matricula: result.fecha_matricula || 'N/A',
              estado_de_matricula: result.estado_matricula || 'N/A',
            });
          } catch (error: any) {
            console.error(
              `Error processing cedula ${record['cedula']}: ${error.message}`
            );
          }
        }
      }

      // Crear el nuevo archivo Excel con los resultados.
      const newWorkbook = XLSX.utils.book_new();
      const newWorksheet = XLSX.utils.json_to_sheet(results);
      XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Result');

      // Generar los datos del nuevo archivo Excel como un arreglo de bytes (Uint8Array).
      const excelDataArray = XLSX.write(newWorkbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      // Crear un Blob a partir del arreglo de bytes.
      const blob = new Blob([excelDataArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Crear un objeto Blob URL utilizando window.URL.createObjectURL.
      const blobUrl = window.URL.createObjectURL(blob);

      // Crear un enlace para descargar el archivo Excel.
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'result.xlsx';

      // Simular un clic en el enlace para iniciar la descarga.
      a.click();

      // Liberar el objeto Blob URL.
      window.URL.revokeObjectURL(blobUrl);
    };

    fileReader.readAsArrayBuffer(file);
  }
}

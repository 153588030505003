<form [formGroup]="allyForm">
  <div class="bl-contenedor">
    <div
      class="bl-modal-terms"
      [ngClass]="{ 'bl-modal-terms-visible': modalTermsAndConditions }"
    >
      <div
        class="bl-modal-terms-info"
        [ngClass]="{ 'bl-modal-terms-info-visible': modalTermsAndConditions }"
      >
        <i class="fa-solid fa-xmark close" (click)="toggleModal()"></i>

        <div class="terms-info">
          <img
            src="../../../assets/images/logo/logo-registro.png"
            alt="Logo"
            width="120"
          />
          <div class="terms-info-description">
            <p>
              ¡Hola! 💚 ¡Gracias por querer ser parte de zíro, te damos la
              bienvenida con los brazos abiertos! 🎉 🎉 Con zíro tienes la
              increíble oportunidad de abastecer tu negocio a crédito con un
              cupo rotativo y hasta 0% interés. Antes de darte luz verde, es
              súper importante que revises los términos y condiciones de cómo
              funciona tu cupo con nosotros en el siguiente enlace:
              https://somosziro.com/terminos-y-condiciones-del-credito/
            </p>
          </div>
          <div class="terms-phone">
            <div class="terms-label-input">
              <label for="phone_1">Número de celular</label>
              <div class="terms-input">
                <div class="terms-input-icon">
                  <i class="fa-solid fa-phone"></i>
                </div>
                <input
                  type="number"
                  id="phone_1"
                  placeholder="Digita el número de celular"
                  value=""
                  formControlName="phone_1"
                  required
                />
              </div>
              <p
                class="text-danger"
                *ngIf="validateCampo(allyForm, 'phone_1') as error"
              >
                {{ error }}
              </p>
            </div>
            <button class="terms-button" (click)="validarCelular()">
              <i class="fas fa-paper-plane"></i>
              Enviar codigo
            </button>
          </div>
          <div class="terms-help">
            <p>¿Necesitas ayúda?</p>
          </div>
        </div>
      </div>
    </div>
    <div class="header-register">
      <div class="logo">
        <a href="/">
          <img
            src="../../../assets/images/logo/logo-registro.png"
            alt="Logo"
            width="120"
          />
        </a>
      </div>
      <div class="next">
        <div class="next-icon">
          <i class="fa-solid fa-circle-check"></i>
          <p>{{ getNombreSeccionActual() }}</p>
        </div>
        <img src="../../../assets/images/svg-icon/next.svg" alt="Next" />
      </div>
      <div class="next-web">
        <div class="next-one">
          <div class="next-icon-web">
            <i class="fa-solid fa-circle-check"></i>
            <p>Terminos y condiciones</p>
          </div>
          <img src="../../../assets/images/svg-icon/next.svg" alt="Next" />
        </div>

        <div class="next-one">
          <div class="next-icon-web">
            <i class="fa-solid fa-circle-check"></i>
            <p>Verificación de identidad</p>
          </div>
          <img src="../../../assets/images/svg-icon/next.svg" alt="Next" />
        </div>

        <div class="next-one">
          <div class="next-icon-web">
            <i class="fa-solid fa-circle-check"></i>
            <p>Datos Personales</p>
          </div>
          <img src="../../../assets/images/svg-icon/next.svg" alt="Next" />
        </div>

        <div class="next-one">
          <div class="next-icon-web">
            <i class="fa-solid fa-circle-check"></i>
            <p>Datos de tu negocio</p>
          </div>
          <img src="../../../assets/images/svg-icon/next.svg" alt="Next" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="step-one" *ngIf="step === 1">
        <img src="../../../assets/img/imagenPreregistro.png" alt="Logo" />
        <div class="step-spacing">
          <div class="bl-cardVerde phone">
            <div class="bl-alert">
              <i class="fa-solid fa-triangle-exclamation"></i>
            </div>
            <div class="d-flex flex-column gap-2">
              <p class="text-white">
                Para poder continuar es necesario que leas y aceptes los
                términos y condiciones.
              </p>
              <button class="bl-accept" (click)="toggleModal()">
                Leer y aceptar
              </button>
            </div>
          </div>
        </div>

        <div class="bl-card-terms web">
          <div class="bl-alert">
            <i class="fa-solid fa-triangle-exclamation"></i>
          </div>
          <div class="d-flex flex-column gap-3">
            <p class="text-white terms">
              ¡Hola! 💚 ¡Gracias por querer ser parte de zíro, te damos la
              bienvenida con los brazos abiertos! 🎉 🎉 Con zíro tienes la
              increíble oportunidad de abastecer tu negocio a crédito con un
              cupo rotativo y hasta 0% interés. Antes de darte luz verde, es
              súper importante que revises los términos y condiciones de cómo
              funciona tu cupo con nosotros en el siguiente enlace:
              <a
                href="https://somosziro.com/terminos-y-condiciones-del-credito/"
                class="text-verdeClaro1"
                target="_blank"
                >https://somosziro.com/terminos-y-condiciones-del-credito/</a
              >
            </p>
            <!-- opciones continuar -->
            <div class="row mt-3">
              <label for="phone_1" class="text-white terms">
                Para continuar, ingresa tu número de teléfono celular.
              </label
              >
              <div class="d-flex flex-wrap justify-content-between gap-2">
                <div class="form-phone">
                  <div class="input-phone">
                    <div class="phone-icon">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <input
                      type="number"
                      id="phone_1"
                      placeholder="Digita el número de celular"
                      value=""
                      formControlName="phone_1"
                      required
                    />
                  </div>
                  <p
                    class="text-danger"
                    *ngIf="validateCampo(allyForm, 'phone_1') as error"
                  >
                    {{ error }}
                  </p>
                </div>
                <div>
                  <button class="btn-phone" (click)="validarCelular()">
                    <i class="fas fa-paper-plane"></i> Enviar código
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Paso 0: preRegistro -->

      <!-- Paso 2: Verificación de identidad FOTO FRONTAL -->
      <div *ngIf="step === 2" class="size">
        <!-- indicaciones de foto -->
        <div class="info-image-frontal">
          <div class="info-identity">
            <img
              src="../../../assets/images/svg-icon/identity.svg"
              alt="Huella"
            />
            <p class="identity-descripcion">
              Por seguridad, verifiquemos tu identidad - ¡Recuerda tener el
              código del proveedor!
            </p>
          </div>

          <div class="card-info">
            <div class="info-one">
              <img
                src="../../../../assets/img/iconoDado.png"
                alt="Logo"
                width="25"
                height="25"
              />
              <p>Verifica que tu documento esté en una superficie plana.</p>
            </div>

            <div class="info-one">
              <img
                src="../../../../assets/img/iconoGrafico.png"
                alt="Logo"
                width="25"
                height="25"
              />
              <p>Asegúrate de que cuentes con buena iluminación.</p>
            </div>

            <div class="info-one">
              <img
                src="../../../../assets/img/iconoIdentificacion.png"
                alt="Logo"
                width="25"
                height="25"
              />
              <p>
                Recuerda usar tu documento original. <br />
                No aceptamos fotocopias ni otros documentos.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div class="card-camara">
            <img
              src="../../../assets/images/svg-icon/camara.svg"
              alt="Camara"
            />
            <p class="camara-descripcion">FOTO CÉDULA FRONTAL (HORIZONTAL)</p>
          </div>

          <div class="screen-user">
            <div class="image-phone" id="cameraOutput">
              <label for="fileInput">
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  capture="environment"
                  (change)="onFileSelected($event, 'frontal')"
                  #fileInput
                  style="display: none"
                />
                <img
                  src="../../../../assets/img/fotoselfiefrontal.png"
                  alt="FOTO FRONTAL"
                  *ngIf="!imagenFrontalVisible"
                />
              </label>

              <img
                [src]="documentFrontImage"
                *ngIf="imagenFrontalVisible"
                class="image-screen"
              />
            </div>
          </div>

          <!-- continuar -->
          <div class="buttons-total">
            <div *ngIf="!imagenFrontalVisible">
              <label
                for="fileInput"
                class="button-image"
                *ngIf="verificacionMetamap === false"
              >
                <input
                  type="file"
                  accept="image/*"
                  capture="environment"
                  (change)="onFileSelected($event, 'frontal')"
                  #fileInput
                  style="display: none"
                />
                <i class="fa-solid fa-camera"></i> Tomar foto
              </label>
            </div>
            <div class="buttons-next">
              <button class="button-back" (click)="prevStep()">
                <i class="fa-solid fa-angles-left"></i> Volver
              </button>
              <button class="button-next" (click)="nextStep()">
                Siguiente <i class="fa-solid fa-angles-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Paso 3: Verificación de identidad FOTO reverso -->
      <div *ngIf="step === 3" class="size">
        <div class="info-image-frontal">
          <div class="info-identity">
            <img
              src="../../../assets/images/svg-icon/identity.svg"
              alt="Huella"
            />
            <p class="identity-descripcion">
              Por seguridad, verifiquemos tu identidad - ¡Recuerda tener el
              código del proveedor!
            </p>
          </div>
          <!-- contenido -->
          <div class="card-info">
            <div class="info-one">
              <img
                src="../../../../assets/img/iconoDado.png"
                alt="Logo"
                width="25"
                height="25"
              />
              <p>Verifica que tu documento esté en una superficie plana.</p>
            </div>

            <div class="info-one">
              <img
                src="../../../../assets/img/iconoGrafico.png"
                alt="Logo"
                width="25"
                height="25"
              />
              <p>Asegúrate de que cuentes con buena iluminación.</p>
            </div>

            <div class="info-one">
              <img
                src="../../../../assets/img/iconoIdentificacion.png"
                alt="Logo"
                width="25"
                height="25"
              />
              <p>
                Recuerda usar tu documento original. <br />
                No aceptamos fotocopias ni otros documentos.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div class="card-camara">
            <img
              src="../../../assets/images/svg-icon/camara.svg"
              alt="Camara"
            />
            <p class="camara-descripcion">FOTO CÉDULA TRASERA (HORIZONTAL)</p>
          </div>

          <!-- foto reverso -->
          <div class="screen-user">
            <div class="image-phone" id="cameraOutput">
              <label for="fileInput2">
                <input
                  type="file"
                  id="fileInput2"
                  accept="image/*"
                  capture="environment"
                  (change)="onFileSelected($event, 'trasera')"
                  #fileInput
                  style="display: none"
                />
                <img
                  src="../../../../assets/img/fotoReverso.png"
                  alt="FOTO TRASERA"
                  *ngIf="!imagenTraseraVisible"
                />
              </label>

              <img
                [src]="documentBackImage"
                *ngIf="imagenTraseraVisible"
                class="image-screen"
              />
            </div>
          </div>

          <!-- continuar -->
          <div class="buttons-total">
            <div *ngIf="!imagenTraseraVisible">
              <label
                for="fileInput2"
                class="button-image"
                *ngIf="verificacionMetamap === false"
              >
                <input
                  type="file"
                  accept="image/*"
                  capture="environment"
                  (change)="onFileSelected($event, 'trasera')"
                  #fileInput
                  style="display: none"
                />
                <i class="fa-solid fa-camera"></i> Tomar foto
              </label>
            </div>
            <div class="buttons-next">
              <button class="button-back" (click)="prevStep()">
                <i class="fa-solid fa-angles-left"></i> Volver
              </button>
              <button class="button-next" (click)="nextStep()">
                Siguiente <i class="fa-solid fa-angles-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Paso 3: Verificación de identidad FOTO selfie -->
      <div *ngIf="step === 4" class="size">
        <div class="image-selfie-camera">
          <div class="image-selfie-camera-max">
            <div class="card-camara">
              <img
                src="../../../assets/images/svg-icon/camara.svg"
                alt="Camara"
              />
              <p class="camara-descripcion">FOTO SELFIE (VERTICAL)</p>
            </div>

            <!--  foto selfie -->
            <div class="screen-user">
              <div class="image-phone" id="cameraOutput">
                <label for="fileInput3" class="image-center">
                  <input
                    id="fileInput3"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'selfie')"
                    #fileInput
                    style="display: none"
                  />
                  <img
                    src="../../../../assets/img/fotoSelfie.png"
                    alt="FOTO SELFIE"
                    *ngIf="!imagenSelfieVisible"
                    class="image-selfie"
                  />

                  <div class="image-alert" *ngIf="!imagenSelfieVisible">
                    <div class="alert-icon">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="image-description">
                      <p>Recuerda no usar gorras, gafas o accesorios</p>
                    </div>
                  </div>

                  <div class="image-lightbulb" *ngIf="!imagenSelfieVisible">
                    <div class="alert-lightbulb">
                      <i class="fa-solid fa-lightbulb"></i>
                    </div>
                    <div class="image-description">
                      <p>Asegúrate de buscar una buena iluminación</p>
                    </div>
                  </div>
                </label>

                <img
                  [src]="selfieImage"
                  *ngIf="imagenSelfieVisible"
                  class="image-screen"
                />
              </div>
            </div>

            <!-- continuar -->
            <div class="buttons-total">
              <div *ngIf="!imagenSelfieVisible">
                <label
                  for="fileInput3"
                  class="button-image"
                  *ngIf="verificacionMetamap === false"
                >
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    (change)="onFileSelected($event, 'selfie')"
                    #fileInput
                    style="display: none"
                  />
                  <i class="fa-solid fa-camera"></i> Tomar foto
                </label>
              </div>
              <div class="buttons-next">
                <button class="button-back" (click)="prevStep()">
                  <i class="fa-solid fa-angles-left"></i> Volver
                </button>
                <button class="button-next" (click)="nextStep()">
                  Siguiente <i class="fa-solid fa-angles-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Paso 4: CODIGO DEL PROVEEDOR -->
      <div *ngIf="step === 5">
        <!-- INICIO BLOQUE -->

        <div class="user-code-container">
          <div class="user-code">
            <div class="image-code">
              <img src="../../../assets/img/imagenPreregistro.png" alt="Logo" />
            </div>
            <div class="info-code">
              <p class="code-description">
                Para continuar con el proceso de registro, necesitas ingresar el
                código de tu proveedor. Si aún no lo tienes pídeselo a tu
                proveedor y podrás seguir adelante. ¡Estamos emocionados de
                tenerte a bordo!
              </p>

              <div class="code">
                <label for="code_shop" class="text-white"
                  >Código de tu proveedor*</label
                >
                <div class="code-input">
                  <div class="code-icon">
                    <i class="fa-solid fa-pen-clip"></i>
                  </div>
                  <input
                    type="number"
                    id="code_shop"
                    placeholder="Digita el código de tu proveedor"
                    value="73221084"
                    formControlName="code_shop"
                    required
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'code_shop') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </div>

          <!-- continuar -->
          <div class="code-buttons">
            <div class="buttons-next">
              <button class="button-back" (click)="prevStep()">
                <i class="fa-solid fa-angles-left"></i> Volver
              </button>
              <button class="button-next" (click)="nextStep()">
                Siguiente <i class="fa-solid fa-angles-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Paso 4: Información personal -->
      <div *ngIf="step === 6" class="size">
        <div class="info-personal-one">
          <div class="info-identity">
            <img
              src="../../../assets/images/svg-icon/identity.svg"
              alt="Huella"
            />
            <p class="identity-descripcion">
              Por seguridad, verifiquemos tus datos - ¡Recuerda tener a mano
              todos tus datos!
            </p>
          </div>

          <div class="data-personal">
            <div class="data-one">
              <div class="input-one">
                <label for="name" class="text-white">Nombres</label>
                <div class="input-v2">
                  <div class="v2-icon">
                    <i class="fa-solid fa-person"></i>
                  </div>
                  <input
                    type="text"
                    id="name"
                    required
                    placeholder="Joab Sebastián"
                    formControlName="name"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'name') as error"
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="last_name" class="text-white">Apellidos</label>
                <div class="input-v2">
                  <div class="v2-icon">
                    <i class="fa-solid fa-person"></i>
                  </div>
                  <input
                    type="text"
                    id="last_name"
                    required
                    formControlName="last_name"
                    placeholder="Giraldo Portela"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'last_name') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="data-one">
              <div class="input-one">
                <label for="name" class="text-white"
                  >Tipo de identificación</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-id-card"></i>
                  </div>
                  <select
                    id="identification_type"
                    required
                    formControlName="identification_type"
                  >
                    <option value="">Seleccionar</option>
                    <option value="CC">Cédula de ciudadanía</option>
                    <option value="CE">Cédula de extranjería</option>
                  </select>
                </div>
                <p
                  class="text-danger"
                  *ngIf="
                    validateCampo(allyForm, 'identification_type') as error
                  "
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="last_name" class="text-white"
                  >Tu número de identificación*</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-id-card"></i>
                  </div>
                  <input
                    type="text"
                    id="identification"
                    required
                    formControlName="identification"
                    placeholder="1006120642"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'identification') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </div>

          <!-- continuar -->
          <div class="buttons-next">
            <button class="button-back" (click)="prevStep()">
              <i class="fa-solid fa-angles-left"></i> Volver
            </button>
            <button class="button-next" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-angles-right"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Paso 5: Datos de contacto -->
      <div *ngIf="step === 7" class="d-flex flex-column gap-2 px-3">
        <div class="info-personal-one">
          <div class="info-identity">
            <img
              src="../../../assets/images/svg-icon/identity.svg"
              alt="Huella"
            />
            <p class="identity-descripcion">
              Por seguridad, verifiquemos tus datos - ¡Recuerda tener a mano
              todos tus datos!
            </p>
          </div>
          <div class="data-personal">
            <div class="data-one">
              <div class="input-one">
                <label for="phone_1_valido" class="text-white"
                  >Número de celular</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <input
                    type="number"
                    id="phone_1_valido"
                    required
                    placeholder="1472583690"
                    [disabled]="true"
                    [value]="phoneStorage"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'phone_1_valido') as error"
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="phone_2" class="text-white"
                  >Número de celular 2</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-phone-flip"></i>
                  </div>
                  <input
                    type="number"
                    id="phone_2"
                    formControlName="phone_2"
                    required
                    placeholder="3152058712"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'phone_2') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="data-one">
              <div class="input-one">
                <label for="email" class="text-white"
                  >Tu Correo Electrónico</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-envelope-circle-check"></i>
                  </div>
                  <input
                    type="email"
                    id="email"
                    required
                    formControlName="email"
                    placeholder="sebastian@somosziro.com"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'email') as error"
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="last_name" class="text-white"
                  >Contraseña
                  <small class="text-verdeClaro1"
                    >&nbsp;(4 dígitos numéricos)</small
                  ></label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-lock"></i>
                  </div>
                  <input
                    type="password"
                    id="password"
                    required
                    formControlName="password"
                    (input)="onPasswordInput()"
                    maxlength="4"
                    placeholder="****"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'password') as error"
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="last_name" class="text-white"
                  >Tu dirección personal</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <input
                    type="text"
                    id="address"
                    required
                    formControlName="address"
                    placeholder="Cra 21 A #57 B 06"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'address') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
          <!-- continuar -->
          <div class="buttons-next">
            <button class="button-back" (click)="prevStep()">
              <i class="fa-solid fa-angles-left"></i> Volver
            </button>
            <button class="button-next" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-angles-right"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Paso 6: Información de tu negocio -->
      <div *ngIf="step === 8" class="d-flex flex-column gap-2 px-3">
        <!-- inicio formulario -->
        <!-- <div class="row pt-5"> -->
        <!-- <div class="col-md-6"> -->

        <!-- <div class="form-group">
                  <label for="user_id_commerce" class="text-white"
                    >Digita el user_id de tu cuenta que aparece en el comercio
                    que vas a comprar</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="user_id_commerce"
                    formControlName="user_id_commerce"
                  />
                  <p
                    class="text-danger"
                    *ngIf="validateCampo(allyForm, 'user_id_commerce') as error"
                  >
                    {{ error }}
                  </p>
                </div> -->
        <!-- </div> -->

        <!-- </div> -->

        <div class="info-personal-one">
          <div class="info-identity">
            <img
              src="../../../assets/images/svg-icon/identity.svg"
              alt="Huella"
            />
            <p class="identity-descripcion">
              Por seguridad, verifiquemos tus datos - ¡Recuerda tener a mano
              todos tus datos!
            </p>
          </div>

          <div class="data-personal">
            <div class="data-one">
              <div class="input-one">
                <label for="nit" class="text-white"
                  >Cédula o NIT de tu Negocio*</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-id-card"></i>
                  </div>
                  <input
                    type="text"
                    id="nit"
                    required
                    formControlName="nit"
                    placeholder="1006120642"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'nit') as error"
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="city_id" class="text-white"
                  >Selecciona el tipo de Negocio*</label
                >

                <div class="ng-select">
                  <div class="ng-icon v1-ng-icon">
                    <i class="fa-solid fa-store"></i>
                  </div>
                  <ng-select
                    formControlName="customer_business_types_id"
                    [items]="businessTypes"
                    bindLabel="name"
                    bindValue="id"
                    [searchable]="true"
                    [clearable]="false"
                    placeholder=""
                    required
                  >
                  </ng-select>
                </div>

                <p
                  class="text-danger"
                  *ngIf="
                    validateCampo(
                      allyForm,
                      'customer_business_types_id'
                    ) as error
                  "
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="data-one">
              <div class="input-one">
                <label for="business_name" class="text-white"
                  >Nombre de tu Negocio*</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-file-signature"></i>
                  </div>
                  <input
                    type="text"
                    id="business_name"
                    required
                    formControlName="business_name"
                    placeholder="Sport Live"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'business_name') as error"
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="value_request" class="text-white"
                  >¿Cuánto son tus compras al mes?</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-dollar-sign"></i>
                  </div>
                  <input
                    type="text"
                    id="value_request"
                    required
                    formControlName="value_request"
                    (input)="formatInputToCurrency($event)"
                    placeholder="3,000,000"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'value_request') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="data-one">
              <div class="input-one">
                <label for="registered_chamber_commerce" class="text-white"
                  >¿Estás registrado en la cámara de comercio?*</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-file-circle-check"></i>
                  </div>
                  <select
                    id="registered_chamber_commerce"
                    required
                    formControlName="registered_chamber_commerce"
                  >
                    <option value="">Seleccionar</option>
                    <option value="1">Sí</option>
                    <option value="2">No</option>
                  </select>
                </div>
                <p
                  class="text-danger"
                  *ngIf="
                    validateCampo(
                      allyForm,
                      'registered_chamber_commerce'
                    ) as error
                  "
                >
                  {{ error }}
                </p>
              </div>

              <div class="input-one">
                <label for="city_id" class="text-white"
                  >Selecciona la ciudad de tu Negocio*</label
                >

                <div class="ng-select">
                  <div class="ng-icon v1-ng-icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <ng-select
                    class="custom-select ng-selects"
                    formControlName="city_id"
                    [items]="cities"
                    [placeholder]="'Selecciona una opción'"
                    bindLabel="name"
                    bindValue="id"
                    [searchable]="true"
                    [clearable]="false"
                    placeholder=""
                    required
                  >
                  </ng-select>
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'city_id') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="data-one">
              <div class="input-one">
                <label for="businness_address" class="text-white"
                  >Ingresa la dirección de tu Negocio*</label
                >
                <div class="input-v2">
                  <div class="v2-icon v1-icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <input
                    type="text"
                    id="businness_address"
                    required
                    formControlName="businness_address"
                    placeholder="Cra 21 A #57 B 06"
                  />
                </div>
                <p
                  class="text-danger"
                  *ngIf="validateCampo(allyForm, 'businness_address') as error"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </div>

          <!-- continuar -->
          <div class="buttons-next">
            <button class="button-back" (click)="prevStep()">
              <i class="fa-solid fa-angles-left"></i> Volver
            </button>
            <button class="button-next" (click)="register()">
              Registrarme <i class="fas fa-user-plus ps-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-footer-sticker></app-footer-sticker>
  </div>
</form>

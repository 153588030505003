import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CreditStateResponseInterface } from "src/app/administrative/credits/interfaces/credits-state.interface";
import { ArlInterface } from "src/app/administrative/parameters/submodules/arl/interfaces/ArlInterface";
import { EpsInterface } from "src/app/administrative/parameters/submodules/eps/interfaces/EpsInterface";
import { PayFrequiencieResponseInterface } from "src/app/administrative/parameters/submodules/pays-frequencies/interfaces/pays-frequiencies-interface";
import { PensionFundInterface } from "src/app/administrative/parameters/submodules/pension-fund/interfaces/PensionFundInterface";
import { PositionInterface } from "src/app/administrative/parameters/submodules/positions/interfaces/PositionInterface";
import { SeveranceFundInterface } from "src/app/administrative/parameters/submodules/severance-fund/interfaces/SeveranceFundInterface";
import { environment } from "src/environments/environment";
import { GeneralParameterInterface, generalParameterResponseInterface } from "../interfaces/general-parameter";

@Injectable({
	providedIn: "root",
})
export class ParemetersService {
    
	/** Base URL del API */
	private readonly baseUrl = environment.apiUrl;
	/** Estados predefinidos */
	statusesCredits = [
		{
			id: 1,
			name: "Sin confirmar",
		},
		{
			id: 2,
			name: "Pendiente entrega",
		},
		{
			id: 3,
			name: "Pendiente pago",
		},
		{
			id: 4,
			name: "Pagado",
		},
        {
			id: 5,
			name: "Anulado",
		},
	];

	/**
	 * @param {HttpClient} http Cliente HTTP para realizar peticiones
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Obtiene una lista de aliados desde el API.
	 *
	 * @returns {Observable<any>} Observable con la lista de aliados
	 */
	getAllies() {
		return this.http.get(`${this.baseUrl}/aliados`);
	}

	/**
	 * Obtiene una lista de ciudades desde el API.
	 *
	 * @returns {Observable<any>} Observable con la lista de ciudades
	 */
	getCities() {
		return this.http.get<generalParameterResponseInterface>(`${this.baseUrl}/ciudades`);
	}

	/**
	 * Obtiene una lista de tipos de negocios desde el API.
	 *
	 * @returns {Observable<any>} Observable con la lista de tipos de negocios
	 */
	getBusinessTypes() {
		return this.http.get(`${this.baseUrl}/clientes-tipos-negocios`);
	}

	/**
	 * Obtiene las ciudades asociadas a un departamento específico.
	 *
	 * @param {number} departmentId - ID del departamento.
	 * @returns {Observable<any>} Observable con la lista de ciudades.
	 */
	getCitiesByDepartments(departmentId: number) {
		return this.http.get(
			`${this.baseUrl}/ciudades/departamento/${departmentId}`
		);
	}

	/**
	 * Obtiene una lista de departamentos.
	 *
	 * @returns {Observable<any>} Observable con la lista de departamentos.
	 */
	getDepartments() {
		return this.http.get(`${this.baseUrl}/departamentos`);
	}

	/**
	 * Obtiene una lista de bancos.
	 *
	 * @returns {Observable<any>} Observable con la lista de bancos.
	 */
	getBanks() {
		return this.http.get(`${this.baseUrl}/bancos`);
	}

	/**
	 * Obtiene una lista de ARLs.
	 *
	 * @returns {Observable<ArlInterface[]>} Observable con la lista de ARLs.
	 */
	getArls() {
		return this.http.get<ArlInterface[]>(`${this.baseUrl}/arl`);
	}

	/**
	 * Obtiene una lista de EPS.
	 *
	 * @returns {Observable<EpsInterface[]>} Observable con la lista de EPS.
	 */
	getEps() {
		return this.http.get<EpsInterface[]>(`${this.baseUrl}/eps`);
	}

	/**
	 * Obtiene una lista de fondos de pensiones.
	 *
	 * @returns {Observable<PensionFundInterface[]>} Observable con la lista de fondos de pensiones.
	 */
	getPensionFunds() {
		return this.http.get<PensionFundInterface[]>(`${this.baseUrl}/eps`);
	}

	/**
	 * Obtiene una lista de fondos de cesantías.
	 *
	 * @returns {Observable<SeveranceFundInterface[]>} Observable con la lista de fondos de cesantías.
	 */
	getSeveranceFunds() {
		return this.http.get<SeveranceFundInterface[]>(
			`${this.baseUrl}/pensiones`
		);
	}

	/**
	 * Obtiene una lista de cargos o posiciones.
	 *
	 * @returns {Observable<PositionInterface[]>} Observable con la lista de cargos.
	 */
	getPositions() {
		return this.http.get<PositionInterface[]>(`${this.baseUrl}/cargos`);
	}

	/**
	 * Obtiene una lista de frecuencias de pago.
	 *
	 * @returns {Observable<PayFrequiencieResponseInterface>} Observable con la lista de frecuencias de pago.
	 */
	getPaysFrequencies() {
		return this.http.get<PayFrequiencieResponseInterface>(
			`${this.baseUrl}/frecuencias-pago`
		);
	}

	/**
	 * Obtiene una lista de estados de crédito.
	 *
	 * @returns {Observable<CreditStateResponseInterface>} Observable con la lista de estados de crédito.
	 */
	getCreditsStates() {
		return this.http.get<CreditStateResponseInterface>(
			`${this.baseUrl}/creditos/estados`
		);
	}

	/**
	 * Obtiene los estados de crédito desde una lista local.
	 *
	 * @returns {Observable<GeneralParameterInterface[]>} Observable con la lista de estados de crédito.
	 */
	getCreditsStatesToLocal(): Observable<GeneralParameterInterface[]> {
		return of(this.statusesCredits);
	}
}

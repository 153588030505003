import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CreditsService } from "src/app/administrative/credits/services/credits.service";
import { PaymentsCustomerServices } from "src/app/administrative/payments-customer/services/payments-customer.services";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-delete-payment-modal",
	templateUrl: "./delete-payment-modal.component.html",
	styleUrls: ["./delete-payment-modal.component.scss"],
})
export class DeletePaymentModalComponent {
	date_payment: string; // Propiedad para almacenar el valor de la solicitud de crédito
	value: number = 0;
	value_total_payment: number = 0; // Propiedad para almacenar el valor de la solicitud de crédito
    payment_id : number;
	/**
	 * Constructor para inicializar las dependencias e inyectables necesarios.
	 *
	 * @param {NgbActiveModal} activeModal - Modal activo proporcionado por Ngb.
	 * @param {LoaderService} loaderService - Servicio para gestionar el cargador/indicador de progreso.
	 * @param {CreditsService} creditsService - Servicio para gestionar operaciones relacionadas con créditos.
	 */
	constructor(
		public activeModal: NgbActiveModal,
		private loaderService: LoaderService,
		private paymentsCustomerServices: PaymentsCustomerServices
	) {}

	/**
     * Elimina un pago de crédito.
     * 
     * @returns Una suscripción a la solicitud HTTP DELETE para eliminar el pago.
    */
    deletePayment() {
        // Muestra el indicador de carga mientras se está eliminando el pago.
        toggleLoader(this.loaderService, true, "¡Eliminando pago!");
        
        // Mensaje de error predeterminado en caso de problemas durante la eliminación del pago.
        const messageError = "Hubo un error al eliminar el pago";
    
        // Realiza la solicitud HTTP DELETE para eliminar el pago.
        return this.paymentsCustomerServices.deletePayment(this.payment_id).subscribe(
        (response) => {
            // Oculta el indicador de carga después de una respuesta exitosa.
            toggleLoader(this.loaderService, false);

            if(response.ok)  {
                Swal.fire(
                "Éxito",
                    response.message || "Pago eliminado correctamente",
                    "success"
                ).then(() => {
                    // Recarga la página actual.
                    window.location.reload();
                });
            } else {
                Swal.fire(
                    "Error",
                    response.message || messageError,
                    "error"
                );
            }          
        },
        (error) => {
            // Oculta el indicador de carga en caso de error.
            toggleLoader(this.loaderService, false);
    
            // Obtiene el mensaje de error del servidor o utiliza el mensaje predeterminado.
            const errorMessage = error.message || messageError;
    
            // Muestra un mensaje de error al usuario y registra el error en la consola.
            Swal.fire("Error", errorMessage, "error");
            console.error("Error al eliminar el pago:", error);
        }
        );
    }
  
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-data-customer-y-credits',
  templateUrl: './card-data-customer-y-credits.component.html',
  styleUrls: ['./card-data-customer-y-credits.component.scss'],
})
export class CardDataCustomerYCreditsComponent {
  @Input() value: any;
  @Input() title: string;
  @Input() description: string;
  @Input() urlImagen: string;
  @Input() tooltip: string;
  @Input() customers: string;
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalServiceAlert {
  private showModalSubject = new BehaviorSubject<boolean>(false);
  public showModal$ = this.showModalSubject.asObservable();

  private modalDataSubject = new BehaviorSubject<any>({});
  public modalData$ = this.modalDataSubject.asObservable();

  constructor() {}

  public openModal(
    title: string,
    description: string | string[],
    type: string,
    timer?: number,
    register?: number
  ): void {
    this.modalDataSubject.next({ title, description, type, register });
    this.showModalSubject.next(true);

    if (timer) {
      setTimeout(() => {
        this.closeModal();
      }, timer);
    }
  }

  public closeModal(): void {
    this.showModalSubject.next(false);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public message$: Subject<string> = new Subject<string>();
}

import { Component } from "@angular/core";
import {
  formatCurrency,
  toggleLoader,
} from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";
import { AdministrativeDashboardService } from "../../services/administrative-dashboard.service";

@Component({
  selector: "app-administrative-dashboard-home",
  templateUrl: "./administrative-dashboard-home.component.html",
  styleUrls: ["./administrative-dashboard-home.component.scss"],
})
export class AdministrativeDashboardHomeComponent {
  response: any;
  totalCustomersRejectedData: any;
  totalCustomersData: any;
  totalNotActivatedData: any;
  totalActivatedData: any;

  totalWithdrawnCreditsData: any;
  totalOutstandingPortfolioData: any;
  totalOutstandingPendingPaymentData: any;

  totalCreditsMoraBalanceWithdrawnLessSavingsData: any;
  totalOutstandingCreditsBalanceTotalData: any;
  formatCurrency = formatCurrency;

  constructor(
    private administrativeDashboardService: AdministrativeDashboardService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    toggleLoader(this.loaderService, true);
    this.getDataDashboard();
  }
  getDataDashboard() {
    this.administrativeDashboardService.getDashboard().subscribe(
      (response: any) => {
        if (response.ok) {
          toggleLoader(this.loaderService, false);

          this.totalCustomersData = response.data[0];
          this.totalActivatedData = response.data[1];
          this.totalNotActivatedData = response.data[2];
          this.totalCustomersRejectedData = response.data[3];

          this.totalCreditsMoraBalanceWithdrawnLessSavingsData =
          response.data[4];
         
          this.totalWithdrawnCreditsData = response.data[5];
          this.totalOutstandingPendingPaymentData = response.data[6];

          
          this.totalOutstandingPortfolioData = response.data[7];
          this.totalOutstandingCreditsBalanceTotalData = response.data[8];
        } else {
          toggleLoader(this.loaderService, false);
          Swal.fire(
            "Error",
            "Ocurrió un error al consultar la información del cliente.",
            "error"
          );
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        // Manejar el error del servicio
        Swal.fire(
          "Error",
          "Ocurrió un error al consultar la información del cliente.",
          "error"
        );
        console.error(error);
      }
    );
  }
}

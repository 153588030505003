import { Component, Input } from '@angular/core';
import { StepService } from '../step/service/step.service';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss']
})
export class AlertInfoComponent {
  constructor(public StepService: StepService) {}

  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() textoBoton: string;
  @Input() isSuccess: number;
  @Input() link: string;

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordService {
  /**
   * URL base del servicio de autenticación.
   */
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  sendEmail(email: string) {
    // Construye la URL completa para enviar la solicitud HTTP POST
    const url = `${this.baseUrl}/existe/correo/${email}`;
    
    // Realiza la solicitud HTTP POST a la URL construida
    // Aquí se está enviando un cuerpo vacío ({}) porque no se están enviando datos en el cuerpo de la solicitud
    return this.http.post(url, {});
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  verifyAndProcessPayment(transactionId: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/verify/${transactionId}`; // Ajusta la URL de acuerdo a tu configuración
    return this.http.get(apiUrl);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalServicePagare } from "../../services/modal.service";
import { FormBuilder } from "@angular/forms";
import { FirmaPagareService } from "../../services/firma-pagare.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-not-accept",
  templateUrl: "./not-accept.component.html",
  styleUrls: ["./not-accept.component.scss"],
})
export class NotAcceptComponent {
  /**
   * El título que se muestra en el componente.
   */
  @Input() titulo: string;

  /**
   * La descripción que se muestra en el componente.
   */
  @Input() descripcion: string;

  /**
   * El texto que se muestra en el botón del componente.
   */
  @Input() textoBoton: string;

  /**
   * Indica si el componente está en un estado de éxito.
   */
  @Input() isSuccess: string;

  /**
   * Indica si el componente debe mostrarse o no.
   */
  @Input() mostrarModal: boolean;

  /**
   * Indica si todos los elementos están marcados.
   */
  allChecked: boolean = false;

  /**
   * Indica si el elemento de acepto está marcado.
   */
  aceptoChecked: boolean = false;

  reason: string = "";

  /**
   * Constructor de la clase.
   */
  constructor(
    private modalServicePagare: ModalServicePagare,
    private firmaPagareService: FirmaPagareService,
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   * Método del ciclo de vida Angular llamado cuando el componente se inicializa.
   * Imprime el valor de la propiedad mostrarModal en la consola.
   */
  OnInit() {
    console.log(this.mostrarModal);
  }

  /**
   * Activa un enlace HTML con el id "asesor" haciendo clic en él si existe.
   */
  activarLink() {
    const enlace = document.getElementById("asesor");
    if (enlace) {
      enlace.click();
    }
  }

  /**
   * Maneja el cambio de estado de un checkbox.
   *
   * @param {string} id - El identificador del checkbox.
   * @param {Event} event - El evento del cambio.
   */
  checkboxChanged(id: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      console.log(`El checkbox ${id} ha sido seleccionado.`);
    } else {
      console.log(`El checkbox ${id} ha sido deseleccionado.`);
    }
  }

  /**
   * Cierra el modal actual utilizando el servicio de modal.
   */
  cerrarModal() {
    this.modalServicePagare.cerrarModal();
  }

  /**
   * Maneja el proceso de envío del formulario y muestra mensajes de éxito o error.
   * Si el formulario se envía con éxito, redirige al usuario a la ruta correspondiente.
   */
  saveForm() {
    toggleLoader(this.loaderService, true);
    const messageError =
      "Ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde.";
    if (!this.reason) {
      Swal.fire("Error", "Debe seleccionar una opción", "error");
    }
    this.activatedRoute.paramMap.subscribe((params) => {
      let identification: number = Number(params.get("id"));
      this.firmaPagareService
        .noAcceptSign(this.reason, identification)
        .subscribe(
          (response) => {
            toggleLoader(this.loaderService, false);
            if (response.ok) {
              Swal.fire({
                title: "Éxito!",
                text: response.message || messageError,
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  if (this.reason === "Hablar con un asesor") {
                    // Redirige al usuario a la ruta de WhatsApp
                    window.location.href = "https://wa.link/jk2a0n";
                  } else {
                    this.router.navigate(["/auth/login"]);
                  }
                }
              });
            } else {
              Swal.fire("Error", response.message || messageError, "error");
            }
          },
          (error) => {
            toggleLoader(this.loaderService, false);
            Swal.fire("Error", error.error.message || messageError, "error");
            console.error(error);
          }
        );
    });
  }
}

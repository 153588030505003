import { Routes } from "@angular/router";

export const administrativeRouters: Routes = [
  /***********************
   * ADMINISTRATIVE ROUTES*
   **********************/
  {
    path: "panel",
    loadChildren: () =>
      import(
        "../../administrative/administrative-dashboards/administrative-dashboards.module"
      ).then((m) => m.AdministrativeDashboardsModule),
  },
  {
    path: "clientes",
    loadChildren: () =>
      import("../../administrative/customers/customers.module").then(
        (m) => m.CustomersModule
      ),
  },
  {
    path: 'clientes-api-test',
    loadChildren: () =>
      import('../../administrative/customers-api-test/customers-api-test.module').then(
        (m) => m.CustomersApiTestModule
      ),
  },
  {
    path: 'codigos-otp',
    loadChildren: () =>
      import(
        "../../administrative/customers-code-otp/customers-code-otp.module"
      ).then((m) => m.CustomersCodeOtpModule),
  },
  {
    path: "cobranza-old",
    loadChildren: () =>
      import("../../administrative/collections/collections.module").then(
        (m) => m.CollectionsModule
      ),
  },
  {
    path: "cobranza",
    loadChildren: () =>
      import(
        "../../administrative/dashboard-collections/dashboard-colletions.module"
      ).then((m) => m.DashboardCollectionsModule),
  },

  {
    path: "creditos",
    loadChildren: () =>
      import("../../administrative/credits/credits.module").then(
        (m) => m.CreditsModule
      ),
  },

  {
    path: "empleados",
    loadChildren: () =>
      import("../../administrative/employees/employees.module").then(
        (m) => m.EmployeesModule
      ),
  },

  {
    path: "lineas-credito",
    loadChildren: () =>
      import("../../administrative/credit-lines/credits-lines.module").then(
        (m) => m.CreditsLinesModule
      ),
  },
  {
    path: "pagos",
    loadChildren: () =>
      import("../../administrative/payments/payments.module").then(
        (m) => m.PaymentsModule
      ),
  },

  {
    path: "parametros",
    loadChildren: () =>
      import("../../administrative/parameters/parameters.module").then(
        (m) => m.ParametersModule
      ),
  },

  {
    path: "proveedores-aliados",
    loadChildren: () =>
      import("../../administrative/providers/providers.module").then(
        (m) => m.ProvidersModule
      ),
  },
  {
    path: "pagos-clientes",
    loadChildren: () =>
      import(
        "../../administrative/payments-customer/payments-customer.module"
      ).then((m) => m.PaymentsCustomerModule),
  },
  {
    path: "pagos-pagos",
    loadChildren: () =>
      import(
        "../../administrative/payments-allies/payments-allies.module"
      ).then((m) => m.PaymentsAlliesModule),
  },

  {
    path: "proveedores-pagos",
    loadChildren: () =>
      import(
        "../../administrative/payments-allies/payments-allies.module"
      ).then((m) => m.PaymentsAlliesModule),
  },
  {
    path: "reportes",
    loadChildren: () =>
      import("../../administrative/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
  },
  {
    path: "solicitudes-credito",
    loadChildren: () =>
      import(
        "../../administrative/credits-requests/credits-requests.module"
      ).then((m) => m.CreditsRequestsModule),
  },

  {
    path: "cobranza/parametros",
    loadChildren: () =>
      import(
        "../../administrative/parameters-collections/parameters-collections.module"
      ).then((m) => m.ParametersCollectionsModule),
  },

  {
    path: "sucursales",
    loadChildren: () =>
      import("../../administrative/commerces/commerces.module").then(
        (m) => m.CommercesModule
      ),
  },

  {
    path: "usuarios",
    loadChildren: () =>
      import("../../administrative/users/users.module").then(
        (m) => m.UsersModule
      ),
  },
];

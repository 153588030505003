import { Component } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { ModalService } from "../components/modal-payment/modal-payment.service";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";
import JSConfetti from "js-confetti";
import { PaymentServiceCustomer } from "../service/payment-service.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-credit-payment",
  templateUrl: "./credit-payment.component.html",
  styleUrls: ["./credit-payment.component.scss"],
})
export class CreditPaymentComponent {
  form: FormGroup;
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private ModalServiceAlert: ModalServiceAlert,
    private formBuilder: FormBuilder,
    private paymentService: PaymentServiceCustomer
  ) {}

  jsConfetti = new JSConfetti();
  customer : any;
  ngOnInit() {
    this.form = this.formBuilder.group({
      identificationCustomer: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]+$"),
        ],
      ],
    });
  }

  // Step general para utilizar las pantallas por numero de secciones
  stepGeneral: number = 1;

  // Step para validar las pantallas dependiendo de los creditos que tiene viejos o nuevos
  // Info del Step para validar el estado del credito
  stepCustomerPayment: number;

  stepLogic: number;

  // 100000000001

  // Funcion para dismnuir y volver cada paso del step, devuelve a la antigua pantalla
  decreaseStepGeneral1() {
    if (this.stepCustomerPayment === 1) {
      this.stepGeneral = 1;
      console.log(1);
      this.payMyDebtValue = false;
    }
  }

  decreaseStepGeneral2() {
    if (this.stepCustomerPayment === 2) {
      this.stepGeneral = 1;
      console.log(1);
      this.payMyDebtValue = false;
    }

    if (this.stepCustomerPayment === 1) {
      this.stepCustomerPayment = 2;
      console.log(2);
      this.payMyDebtValue = false;
    }
  }

  decreaseStepGeneral3() {
    if (this.stepCustomerPayment === 3) {
      this.stepGeneral = 1;
      console.log(1);
      this.payMyDebtValue = false;
    }

    if (this.stepCustomerPayment === 2) {
      this.stepCustomerPayment = 3;
      console.log(2);
      this.payMyDebtValue = false;
    }

    if (this.stepCustomerPayment === 1) {
      console.log(3);
      this.payMyDebtValue = false;
      this.stepCustomerPayment = 3;
    }
  }

  finishPayment() {
    this.stepGeneral = 1;
  }

  // else if (this.stepGeneral === 2) {
  //   this.stepGeneral = 1;
  // }

  // Variables para el manejo de los creditos y la información del cliente
  infoCustomerCredits: any;

  // Creditos antiguos
  oldCredits: any;

  //Creditos nuevo
  newCredits: any;

  // Variables para la tarjeta de fechas indicadoras para el cliente
  next_due_date: string | null;
  cutoff_date: string | null;
  billing_period: string | null;

  // Pago minimo y pago total
  min_payment: string | number | null | any;
  total_payment: string | number | null | any;

  // Seleccion de credito por tarjeta
  selectedCredit: string;

  // Seleccio de abono para pagar otro valor
  payMyDebtValue: boolean = false;

  // Otro valor para ser el abono
  debt: number = 0;

  // Codepay numero de obligación
  codepay: number;

  // Pago de nuevas obligaciones
  newPaymentObligation: number;

  // Obtiene la obligación si selección un credito
  obligationIdCredit: number;

  customerWhitoutCredits: boolean;

  // Confetti de creditos al dia
  Confetti() {
    this.jsConfetti.addConfetti();
  }

  // Mostrar creditos antiguos
  activeCreditsNew: boolean = false;

  // Esta función se llamará cuando quieras realizar la solicitud de identificación
  getIdentification() {
    toggleLoader(this.loaderService, true);
    const identification = this.form.get("identificationCustomer")?.value;

    // Se valida que el numero de documento este lleno
    if (!identification) {
      toggleLoader(this.loaderService, false);
      this.ModalServiceAlert.openModal(
        "Alerta!",
        "El número de cédula es obligatorio.!",
        "warning"
      );
      return;
    }

    //
    this.paymentService.identification(identification).subscribe(
      (response: any) => {
        toggleLoader(this.loaderService, false);
        const data= response.data;
        if(response.ok) {
            if (data.customer_whitout_credits) {
                this.Confetti();
            }
            this.customer= data.customer;
            // Steps general de pantalla y step dependiendo de los creditos que traiga antiguos o nuevos
            this.stepGeneral = 2;
            // Info de creditos antiguos
            this.oldCredits = data.credits_old;
            // Info de creditos nuevos
            this.newCredits = data.credits_new;
            // Variables para la tarjeta de fechas indicadoras para el cliente
            this.next_due_date = data.next_due_date;
            // Pago minimo y pago total
            this.min_payment = data.min_payment;
            this.total_payment = data.total_payment;
            // Info del Step para validar el estado del credito
            this.stepCustomerPayment = data.step;
            this.stepLogic = data.step;  
            this.customerWhitoutCredits = data.customer_whitout_credits;        
        } else {
            this.ModalServiceAlert.openModal(
                "¡Alerta!",
                response.message,
                "warning"
            );
            return;
        }
      },
      (error) => {
        const messageError= 'Hubo un problema al conectar con el servidor';
        toggleLoader(this.loaderService, false);
          this.ModalServiceAlert.openModal(
            "Error!",
            error.error.message || messageError,
            "error"
          );
        
      }
    );
  }

  // Funcion para activar y mostrar los creditos antiguos

  showCreditsOld() {
    console.log(this.stepCustomerPayment);
    if (this.stepCustomerPayment === 3) {
      this.stepCustomerPayment = 2;
      // this.activeCreditsNew = !this.activeCreditsNew;
    }
  }

  sendPayValue(value: number | string) {
    console.log(this.stepCustomerPayment);
    this.min_payment = value.toString();

    if (this.stepCustomerPayment === 3) {
      this.stepCustomerPayment = 1;

      this.total_payment = this.min_payment;

      console.log(this.total_payment);
    }

    if (this.stepCustomerPayment === 2) {
      this.stepCustomerPayment = 1;
    }
  }

  // Funcion para enviar el valor seleccionado de las tarjetas a la pantalla principal para realizar el pago
  selectRadioCreditOld(event: Event, deadline: string) {
    console.log(this.stepCustomerPayment);
    const target = event.target as HTMLInputElement;
    this.selectedCredit = target.value;

    this.min_payment = this.selectedCredit;
    this.total_payment = this.selectedCredit;
    this.next_due_date = deadline;
    if (this.stepCustomerPayment === 2) {
      this.stepCustomerPayment = 1;
    }
  }

  pay_my_debt() {
    console.log(this.stepCustomerPayment);
    this.payMyDebtValue = !this.payMyDebtValue;
  }

  debtValue(event: Event) {    
    console.log(this.stepCustomerPayment);
    const target = event.target as HTMLInputElement;
    this.selectedCredit = target.value;
    console.log(target.value);
  }



  createCustomerOpenpay(customer: any) {
    console.log(this.stepCustomerPayment);
    const url = `${environment.apiUrl}/v1/create/customer`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    console.log(customer);

    const customerData = {
      customer,
    };

    console.log(customerData);
    // toggleLoader(this.loaderService, true);

    this.http.post(url, customerData, { headers }).subscribe(
      (response: any) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  sendObligation (obligationId: number) {
    console.log(obligationId)
    this.obligationIdCredit = obligationId;
  }

  // Funcion para enviar la data a Openpay y crear el link de pago

  payData(parametro1?: number | string, parametro2?: number | string) {
    toggleLoader(this.loaderService, true);
    // const url = `https://sandbox-api.openpay.co/v1/${environment.MERCHANT_ID}/charges`;
    const customer = {
      name: this.customer.name,
      last_name: this.customer.last_name,
      email: this.customer.email,
      phone_number: this.customer.phone,
      requires_account: false,
      external_id: this.customer.identification,
      customer_address: {
        department: this.customer.department,
        city: this.customer.city,
        additional: this.customer.address,
      },
    };

    // this.createCustomerOpenpay(customer);

    console.log(customer);

    const amount = parametro1 ?? parametro2;
    const description = `IDENTIFICACIÓN:${this.customer.identification} | OBLIGACIÓN:${this.obligationIdCredit}`;
    console.log(description);

    const dataToSend = {
      customer,
      amount,
    };

    if (
      typeof amount !== "undefined" &&
      typeof amount === "number" &&
      amount < 5000
    ) {
      this.ModalServiceAlert.openModal(
        "¡Alerta!",
        `El valor de abono debe ser igual o mayor a $5000`,
        "warning"
      );
      toggleLoader(this.loaderService, false);
      return;
    }

    if (
      typeof amount !== "undefined" &&
      typeof amount === "number" &&
      amount > this.total_payment
    ) {
      this.ModalServiceAlert.openModal(
        "¡Alerta!",
        `El valor de abono debe ser igual o menor a ${this.total_payment}`,
        "warning"
      );
      toggleLoader(this.loaderService, false);
      return;
    }

    if (!amount) {
      this.ModalServiceAlert.openModal(
        "¡Alerta!",
        `El valor de abono esta vacio ${amount}`,
        "warning"
      );
      toggleLoader(this.loaderService, false);
      return;
    }

    const generatedIds: number[] = [];

    function generateRandomId() {
      let randomId;
      do {
        randomId = Math.floor(Math.random() * 10000);
      } while (generatedIds.includes(randomId));

      generatedIds.push(randomId);
      return randomId;
    }

    const data = {
      method: "bank_account",
      amount,
      currency: "COP",
      description,
      order_id: generateRandomId(),
      iva: "0",
      redirect_url: "https://creditos.somosziro.com/estado/pago",
      customer: {
        name: this.customer.name,
        last_name: this.customer.last_name,
        email: this.customer.email,
        phone_number: this.customer.phone,
        requires_account: false,
        external_id: generateRandomId(),
        customer_address: {
          department: this.customer.department,
          city: this.customer.city,
          additional: this.customer.address,
        },
      },
    };

    console.log(typeof this.min_payment);
    console.log(typeof this.total_payment);

    const url = `${environment.apiUrl}/v1/create/link`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    this.http.post(url, data, { headers }).subscribe(
      (response: any) => {
        console.log(response);
        toggleLoader(this.loaderService, false);
        const paymentMethod = response.payment_method;

        if (paymentMethod === undefined) {
          toggleLoader(this.loaderService, false);
          this.ModalServiceAlert.openModal(
            "Error!",
            "¡Lo sentimos hubo un error en los metodos de pago, por favor vuelve a intentarlo!",
            "error"
          );
        } else {
          this.modalService.openModalWithData({
            data: dataToSend,
            paymentMethod: paymentMethod,
          });
          toggleLoader(this.loaderService, false);
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);
        this.ModalServiceAlert.openModal(
          "Error!",
          error.error.message,
          "error"
        );
      }
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ColorComponent } from './components/customizer/color/color.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { LayoutSettingComponent } from './components/customizer/layout-setting/layout-setting.component';
import { FeatherIconComponent } from './components/feather-icon/feather-icon.component';
import { FooterComponent } from './components/footer/footer.component';
import { AccountComponent } from './components/header/header/account/account.component';
import { MaximizeComponent } from './components/header/header/maximize/maximize.component';
import { ModeComponent } from './components/header/header/mode/mode.component';
import { SearchCustomizeComponent } from './components/header/header/search-customize/search-customize.component';
import { SearchComponent } from './components/header/header/search/search.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CreditsRequestsCommentsComponent } from './components/modals/credits-requests-comments/credits-requests-comments.component';
import { CreditsRequestsEditValueComponent } from './components/modals/credits-requests-modal-edit-value-credit/credits-requests-modal-edit-value-credit.component';
import { CustomerInfoShowModalComponent } from './components/modals/customer-info-show-modal/customer-info-show-modal.component';
import { CustomersSearchComponent } from './components/modals/customers-search/customers-search.component';
import { WithdrawCreditComponent } from './components/modals/withdraw-credit/withdraw-credit.component';
import { MsjNoRegistersComponent } from './components/msj-no-registers/msj-no-registers.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { StateBadgeComponent } from './components/state-badge/state-badge.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { NavService } from './services/nav.service';
import { StatePayBadgeComponent } from './components/state-pay-badge/state-pay-badge.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { FooterStickerComponent } from './components/footer-sticker/footer-sticker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component';
import { CreditsCanceledModalComponent } from './components/modals/credits-canceled-modal/credits-canceled-modal.component';
import { CustomerEditCupoComponent } from './components/modals/customer-edit-cupo/customer-edit-cupo.component';
import { DeletePaymentModalComponent } from './components/modals/delete-payment-modal/delete-payment-modal.component';
import { CustomersImagesModalComponent } from './components/modals/customers-images-modal/customers-images-modal.component';
import { CustomerBlockedCupoComponent } from './components/modals/customer-blocked-cupo/customer-blocked-cupo.component';
import { PaginatorDetailComponent } from './components/paginator-detail/paginator-detail.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ContentComponent,
    SidebarComponent,
    FeatherIconComponent,
    BreadcrumbComponent,
    TapToTopComponent,
    FooterComponent,
    MaximizeComponent,
    AccountComponent,
    ModeComponent,
    CustomizerComponent,
    LoaderComponent,
    FullComponent,
    SearchComponent,
    ColorComponent,
    LayoutSettingComponent,
    SearchCustomizeComponent,
    PaginatorComponent,
    MsjNoRegistersComponent,
    CustomerInfoShowModalComponent,
    CreditsRequestsCommentsComponent,
    CreditsRequestsEditValueComponent,
    WithdrawCreditComponent,
    CustomersSearchComponent,
    StateBadgeComponent,
    StatePayBadgeComponent,
    HeaderLogoComponent,
    FooterStickerComponent,
    ModalAlertComponent,
    CreditsCanceledModalComponent,
    CustomerEditCupoComponent,
    DeletePaymentModalComponent,
    CustomersImagesModalComponent,
    CustomerBlockedCupoComponent,
    PaginatorDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [NavService, DecimalPipe],
  exports: [
    RouterModule,
    BreadcrumbComponent,
    TapToTopComponent,
    FeatherIconComponent,
    ContentComponent,
    LoaderComponent,
    NgbModule,
    AngularSvgIconModule,
    PaginatorComponent,
    MsjNoRegistersComponent,
    WithdrawCreditComponent,
    CustomersSearchComponent,
    StateBadgeComponent,
    StatePayBadgeComponent,
    CreditsRequestsEditValueComponent,
    HeaderLogoComponent,
    FooterStickerComponent,
    ModalAlertComponent,
    PaginatorDetailComponent
  ],
})
export class SharedModule {}

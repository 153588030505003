<div class="customizer-links" [ngClass]="{'open': customizer != '' }">
   <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
      <!-- <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': customizer == 'layout' }" (click)="Customizer('layout')">
         <div class="settings"><i class="icon-paint-bucket"></i></div> 
         <span>Check layouts</span>
      </a> -->
      <!-- <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': customizer == 'option' }" id="c-pills-home-tab" (click)="Customizer('option')">
         <div class="settings"><i class="icon-settings"></i></div>
         <span>Quick option</span> 
      </a> -->
   </div>
</div>

<div class="customizer-contain" [ngClass]="{'open' : customizer != '' }">
   <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
         <i class="icofont-close icon-close" (click)="Customizer('')"></i>
         <h5>Preview Settings</h5>
         <p class="mb-0">Try It Real Time <i class="fa fa-thumbs-o-up txt-primary"></i></p>
         <button class="btn btn-primary plus-popup mt-2" (click)="openModal(popup)">Configuration</button>
         <ng-template #popup let-modal>
            <div class="modal-header modal-copy-header">
               <h5 class="headerTitle mb-0">Customizer configuration</h5>
               <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
               </button>
            </div>
            <div class="modal-body">
               <div class="modal-body config-popup">
                  <div class="row">
         
                     <p>To replace our design with your desired theme. Please do configuration as mention </p>
                     <p> <b> Path : src > app > shared > services > layout.service.ts </b> </p>
                     <div>
                        <pre>
   <code>
      public config = &#123;  
         settings&#58; &#123;
            sidebar&#58; '{{layout.config.settings.sidebar}}',
            layout_type&#58; '{{layout.config.settings.layout_type}}',
            layout_version&#58; '{{layout.config.settings.layout_version}}',
            sidebar_type&#58; '{{layout.config.settings.sidebar_type}}',
         &#125;,
         color&#58; &#123;
            primary_color&#58; '{{layout.config.color.primary_color}}',
            secondary_color&#58; '{{layout.config.color.secondary_color}}'
         &#125;
      &#125;
   </code>
                     </pre>
                     </div>
                  </div>
               </div>
               <button class="btn btn-primary mt-2" (click)="copyText(this.layout.config)">Copy Json</button>
            </div>
         </ng-template>
      </div>
      <div class="customizer-body custom-scrollbar">
         <div class="tab-pane fade" [ngClass]="{'active show': customizer == 'layout' }" id="c-pills-home" role="tabpanel"
         aria-labelledby="c-pills-home-tab">
            <app-color></app-color>
         </div>
         <div class="tab-pane fade" [ngClass]="{'active show': customizer == 'option' }" id="c-pills-home" role="tabpanel"
         aria-labelledby="c-pills-home-tab">
            <app-layout-setting></app-layout-setting>
      </div>
   </div>
</div>

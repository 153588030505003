import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CreditsService } from "src/app/administrative/credits/services/credits.service";
import {
  toggleLoader,
} from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-credits-canceled-modal",
  templateUrl: "./credits-canceled-modal.component.html",
  styleUrls: ["./credits-canceled-modal.component.scss"],
})
export class CreditsCanceledModalComponent {
  creditIdDelete: number = 0; // Propiedad para almacenar el valor de la solicitud de crédito
  creditRequestValue: number = 0;
  creditRequestnObligacion: number = 0; // Propiedad para almacenar el valor de la solicitud de crédito
  creditState: number;
  creditId: number;
  dateCredit: string; // Propiedad para almacenar el contenido de Selecciona un motivo
  creditStateName: string;
  creditOrder: number;
  creditShopCommerceId: number;
  creditIdentification: string;
  creditFullNames: string;

  /**
   * Constructor para inicializar las dependencias e inyectables necesarios.
   *
   * @param {NgbActiveModal} activeModal - Modal activo proporcionado por Ngb.
   * @param {LoaderService} loaderService - Servicio para gestionar el cargador/indicador de progreso.
   * @param {CreditsService} creditsService - Servicio para gestionar operaciones relacionadas con créditos.
   */
  constructor(
    public activeModal: NgbActiveModal,
    private loaderService: LoaderService,
    private creditsService: CreditsService,
  ) {}

  /**
   * Inicializa el formulario para editar el crédito.
   *
   * @return {void}
   */

  /**
   * Actualiza la información del crédito basándose en los datos proporcionados en el formulario.
   * Primero, verifica la validez del formulario, muestra un mensaje de error si es inválido,
   * y si es válido, procede a realizar la actualización a través del servicio `creditsService`.
   * Muestra mensajes de éxito o error según el resultado de la actualización.
   *
   * @return {void}
   */

  actionCanceledCredit() {
    console.log(this.creditShopCommerceId, this.creditOrder);
    
    toggleLoader(this.loaderService, true, '¡Anulando crédito!');
    this.creditsService
      .cancelCredit(this.creditId)
      .subscribe(
        (response: any ) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            Swal.fire(
              "Éxito",
              "¡El crédito se anuló correctamente!",
              "success"
            );
            this.activeModal.close();
          } else {
            Swal.fire("Error", response.message, "error");
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);
          Swal.fire("Error", error.message, "error");
        }
      );
  }
  
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-msj-no-registers',
  templateUrl: './msj-no-registers.component.html',
  styleUrls: ['./msj-no-registers.component.scss']
})
export class MsjNoRegistersComponent {

}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  AdministrativeDashboardGetInterface,
  AdministrativeDashboardResponseInterface,
} from "../interfaces/administrative-dashboard.interface";

@Injectable({
  providedIn: "root",
})
export class AdministrativeDashboardService {
  private readonly baseUrl = environment.apiUrl + "/admin";

  //constructor
  constructor(private http: HttpClient) {}

  //getCommitment
  getDataDashboard() {
    const url = `${this.baseUrl}/reportes/inicio`;
    return this.http.get<AdministrativeDashboardResponseInterface>(url);
  }

  getDashboard() {
    const url = `${this.baseUrl}/reportes/dashboards`;
    return this.http.get<AdministrativeDashboardResponseInterface>(url);
  }
}

<div class="error-wrapper">
   <div class="container">
     <div class="error-page1">
       <div class="svg-wrraper mb-0">
        <svg-icon src="assets/svgIcon/error-page-1.svg"></svg-icon>  
       </div>
       <div class="col-md-8 offset-md-2">
        <h2>Lo sentimos, algo salió mal</h2>
        <p class="sub-content">La página a la que intentas acceder no está disponible actualmente. Esto puede ser porque la página no existe o ha sido movida.</p><a class="btn btn-primary btn-lg" href="/">
        REGRESAR A LA PÁGINA DE INICIO</a>
       </div>
     </div>
   </div>
 </div>
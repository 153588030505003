<div class="third_page">
  <div class="important_six">
    <span (click)="decrementar()"
      ><i class="fa-solid fa-circle-arrow-left"></i
    ></span>
    <img src="../../../../assets/img-news/nubes-sol.svg" alt="Nubes Sol" />
  </div>
  <div class="info_content">
    <h2>
      Para una mejor experiencia de registro, usa solo uno de estos dos navegadores.
      <br />
    </h2>
    <div class="row">
      <div class="col-6 text-center">
        <img
          src="../../../assets/img/chrome-os-android.png"
          width="80"
          alt="Logo"
          class="img-fluid d-block mx-auto"
        />
        <br />
        <p class="text-center">
          <strong>Chrome</strong>
        </p>
      </div>
      <div class="col-6 text-cente">
        <img
          src="../../../assets/img/safari-os-ios.png"
          width="80"
          alt="Logo"
          class="img-fluid d-block mx-auto"
        />
        <br />
        <p class="text-center">
          <strong>Safari</strong>
        </p>
      </div>
    </div>
  </div>
  <div class="info_footer">
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
    <button type="button" (click)="incrementar()">Siguiente</button>
  </div>
</div>

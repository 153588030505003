import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommerceDashboardResponseInterface } from '../interfaces/commerce-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class CommerceDashboardService {

    /**
     * La URL base para las solicitudes relacionadas con los clientes en la API.
    */
    private readonly baseUrl = environment.apiUrl + '/comercio';
    
   /**
     * Constructor de la clase que recibe una instancia de HttpClient para realizar solicitudes HTTP.
     *
     * @param {HttpClient} http - Instancia de HttpClient utilizada para las solicitudes HTTP.
    */
    constructor(private http: HttpClient) { }


    /**
     * Obtiene los datos del panel de control del cliente desde el servidor.
     *
     * @returns {Observable<CommerceDashboardResponseInterface>} 
    */
    getDataDashboard() {
        const url = `${this.baseUrl}/reportes/inicio`;
        return this.http.get<CommerceDashboardResponseInterface>(url);
    }

}

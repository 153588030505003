import { Component, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CreditsService } from "src/app/administrative/credits/services/credits.service";
import {
  toggleLoader,
  validateCampo,
} from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-credits-requests-modal-edit-value-credit",
  templateUrl: "./credits-requests-modal-edit-value-credit.component.html",
  styleUrls: ["./credits-requests-modal-edit-value-credit.component.css"],
})
export class CreditsRequestsEditValueComponent {
  creditRequestValue: number = 0; // Propiedad para almacenar el valor de la solicitud de crédito
  creditRequestnObligacion: number = 0; // Propiedad para almacenar el valor de la solicitud de crédito
  creditState: number;
  creditId: number;
  dateCredit: string; // Propiedad para almacenar el contenido de Selecciona un motivo
  creditStateName: string;
  orderNumber:string;
  formEditCredit: FormGroup;
  validateCampo = validateCampo; // Función para validar campos

  /**
   * Constructor para inicializar las dependencias e inyectables necesarios.
   *
   * @param {NgbActiveModal} activeModal - Modal activo proporcionado por Ngb.
   * @param {LoaderService} loaderService - Servicio para gestionar el cargador/indicador de progreso.
   * @param {CreditsService} creditsService - Servicio para gestionar operaciones relacionadas con créditos.
   * @param {FormBuilder} formBuilder - Herramienta para construir formularios reactivos.
   */
  constructor(
    public activeModal: NgbActiveModal,
    private loaderService: LoaderService,
    private creditsService: CreditsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Inicializa el formulario para editar el crédito.
   *
   * @return {void}
   */
  initForm(): void {
    this.formEditCredit = this.formBuilder.group({
      value_credit: [this.creditRequestValue, [Validators.required]],
      date_credit: [this.dateCredit],
      reason_edit: [""],
      order_number: [this.orderNumber],
      state: [this.creditState, Validators.required],
    });

    if (this.formEditCredit) {
      this.formEditCredit
        .get("value_credit")
        ?.valueChanges.subscribe((value) => {
          this.onValueCreditInputChange(value);
        });
    }
  }

  onValueCreditInputChange(value: string): void {
    const numericValue = value.replace(/[^\d]/g, ""); // Elimina caracteres no numéricos

    // Formatea el número con comas y puntos para mejorar la legibilidad
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Actualiza el valor en el formulario
    const valueCreditControl = this.formEditCredit?.get("value_credit");

    // Asegúrate de que valueCreditControl no sea null antes de continuar
    if (valueCreditControl) {
      valueCreditControl.setValue(formattedValue, { emitEvent: false });
    }

    // Resto del código...
  }

  /**
   * Actualiza la información del crédito basándose en los datos proporcionados en el formulario.
   * Primero, verifica la validez del formulario, muestra un mensaje de error si es inválido,
   * y si es válido, procede a realizar la actualización a través del servicio `creditsService`.
   * Muestra mensajes de éxito o error según el resultado de la actualización.
   *
   * @return {void}
   */
  updateCredit() {
    if (this.formEditCredit.invalid) {
      this.formEditCredit.markAllAsTouched();
      Swal.fire("Error", "Debe completar todos los campos", "error");
      return; // Salir de la función si faltan campos por llenar
    }

    if (
      (this.formEditCredit.value.state == 3 &&
        this.formEditCredit.value.value_credit == "") ||
      (this.formEditCredit.value.state == 3 &&
        this.formEditCredit.value.date_credit == null)
    ) {
      Swal.fire(
        "Error",
        "Debe completar fecha entrega  y valor del crédito",
        "error"
      );
      return; // Salir de la función si faltan campos por llenar
    }

    if (this.creditState === 3 && this.formEditCredit.value.state === 2) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Deseas pasar el crédito a estado entregado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, estoy seguro",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          // Llamada al servicio si el usuario confirma
          this.actionUpdateCredit();
        }
      });
    } else {
      this.actionUpdateCredit();
    }
  }

  actionUpdateCredit() {
    toggleLoader(this.loaderService, true);
    this.creditsService
      .updateCredit(this.creditId, this.formEditCredit.getRawValue())
      .subscribe(
        (response) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            Swal.fire(
              "Éxito",
              `El crédito  ${this.creditRequestnObligacion}, ha sido actualizado correctamente`,
              "success"
            );
          } else {
            Swal.fire("Error", response.message, "error");
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);
          Swal.fire("Error", error.error.message, "error");
        }
      );
  }
}

<footer>
  <div class="copyright">
    <a
      target="_blank"
      href="https://somosziro.com/terminos-y-condiciones/"
      rel="noopener noreferrer"
      ><p>Términos y Condiciones</p></a
    >
    <a target="_blank" href="https://somosziro.com/" rel="noopener noreferrer"
      ><p>&copy; somosziro</p></a
    >
  </div>
  <div class="zíro-estres">
    <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
  </div>
</footer>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    ¿Estás seguro de eliminar este pago?
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>

<div class="modal-body">
  <div class="mb-3 d-flex flex-column justify-content-between gap-2">
    <div class="text-start">
      <label class="form-label m-0 p-0">Fecha que se realizo el pago</label>
      <div class="form-text fs-5 text-black m-0 p-0">
        {{ date_payment }}
      </div>
    </div>
    <div class="d-flex justify-content-between gap-2">
      <div class="text-start">
        <label class="form-label m-0 p-0">Valor capital</label>
        <div class="form-text fs-5 text-black m-0 p-0" disabled>
          {{ value_total_payment | currency : "$" : "symbol" : "1.0-0" }}
        </div>
      </div>

      <div>
        <label class="form-label m-0 p-0">Valor total pagado</label>
        <div class="form-text fs-5 text-black m-0 p-0" disabled>
          {{ value | currency : "$" : "symbol" : "1.0-0" }}
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn btn-dark me-2"
      (click)="activeModal.dismiss()"
    >
      Cerrar
    </button>
    <button
      type="button"
      class="btn btn-danger d-flex align-items-center gap-2"
      (click)="deletePayment()"
    >
      Eliminar
      <i class="fa-solid fa-trash"></i>
    </button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AlertErrorComponent } from './auth/new-register/components/alert-error/alert-error.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { SharedModule } from './shared/shared.module';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { ToastrModule } from 'ngx-toastr';
import { CardDataCustomerYCreditsComponent } from './administrative/administrative-dashboards/components/card-data-customer-y-credits/card-data-customer-y-credits.component';
import { AdministrativeDashboardHomeComponent } from './administrative/administrative-dashboards/pages/administrative-dashboard-home/administrative-dashboard-home.component';
import { DashboardColletionsListComponent } from './administrative/dashboard-collections/pages/dashboard-colletions-list/dashboard-colletions-list.component';
import { RegisterComponent } from './auth/Register/register.component';
import { NotAcceptComponent } from './auth/firma-pagare/components/alert-warning/not-accept.component';
import { CupoAprobadoComponent } from './auth/firma-pagare/components/cupo-aprobado/cupo-aprobado.component';
import { PagareSendCodeComponent } from './auth/firma-pagare/components/pagare-send-code/pagare-send-code.component';
import { FirmaPagareComponent } from './auth/firma-pagare/firma-pagare.component';
import { AlertInfoComponent } from './auth/new-register/components/alert-info/alert-info.component';
import { AlertSuccessWarningComponent } from './auth/new-register/components/alert-success-warning/alert-success-warning.component';
import { AlertSuccessComponent } from './auth/new-register/components/alert-success/alert-success.component';
import { AlertWarningComponent } from './auth/new-register/components/alert-warning/alert-warning.component';
import { StepComponent } from './auth/new-register/components/step/step.component';
import { BroserDefaulPageComponent } from './auth/new-register/pages/browser-default/browser-default.component';
import { FivePageComponent } from './auth/new-register/pages/five-page/five-page.component';
import { FourPageComponent } from './auth/new-register/pages/four-page/four-page.component';
import { OnePageComponent } from './auth/new-register/pages/one-page/one-page.component';
import { SecondPageComponent } from './auth/new-register/pages/second-page/second-page.component';
import { SixPageComponent } from './auth/new-register/pages/six-page/six-page.component';
import { ThirdPageComponent } from './auth/new-register/pages/third-page/third-page.component';
import { NewRegisterComponent2 } from './auth/new-register/register.component';
import { ConfirmPasswordComponent } from './auth/recovery-password/confirm-password/confirm-password.component';
import { RecoveryPasswordComponent } from './auth/recovery-password/recovery-password.component';
import { CommerceDashboardHomeComponent } from './commerce/commerce-dashboards/pages/commerce-dashboard-home/commerce-dashboard-home.component';
import { PaysuccessModule } from './commerce/paysuccess/paysuccess.module';
import { ExcelProcessorComponent } from './excel-processor/excel-processor.component';
import { TokenInterceptor } from './interceptors/TokenInterceptor';
import { ModalPaymentComponent } from './payment/components/modal-payment/modal-payment.component';
import { CreditPaymentComponent } from './payment/credit-payment/credit-payment.component';
import { StatusPaymentComponent } from './payment/status-payments/status-payment.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    NewRegisterComponent2,
    AdministrativeDashboardHomeComponent,
    CommerceDashboardHomeComponent,
    CreditPaymentComponent,
    StatusPaymentComponent,
    ModalPaymentComponent,
    RecoveryPasswordComponent,
    BroserDefaulPageComponent,
    OnePageComponent,
    SecondPageComponent,
    ThirdPageComponent,
    StepComponent,
    FourPageComponent,
    FivePageComponent,
    SixPageComponent,
    AlertInfoComponent,
    AlertSuccessComponent,
    AlertWarningComponent,
    AlertErrorComponent,
    FirmaPagareComponent,
    PagareSendCodeComponent,
    CupoAprobadoComponent,
    NotAcceptComponent,
    AlertSuccessWarningComponent,
    ConfirmPasswordComponent,
    ExcelProcessorComponent,
    DashboardColletionsListComponent,
    CardDataCustomerYCreditsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    SharedModule,
    OverlayModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgSelectModule,
    PaysuccessModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LightboxModule,
    ToastrModule.forRoot()
    // NgxDaterangepickerBootstrapModule.forRoot()
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // NgxDaterangepickerBootstrapDirective
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

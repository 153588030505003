import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalServicePagare {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();

  constructor() { }

  abrirModal() {
    this.isOpenSubject.next(true);
  }

  cerrarModal() {
    this.isOpenSubject.next(false);
  }

  activarLink() {
    setTimeout(() => {
      const enlace = document.getElementById('asesor');

      if (enlace) {
        enlace.click();
      }
    }, 1000); // Espera 1 segundo antes de activar el enlace
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from "@angular/core";


@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
})
export class PaginatorComponent implements OnChanges {
  @Input() paginador?: any;
  @Input() route?: string;
  @Input() queryParams?: any;
  paginas: number[] = [];
  @Output() pageChangeEvent = new EventEmitter<number>();



  /**
   * Método del ciclo de vida ngOnChanges.
   *
   * Recalcula la lista de números de página cada vez que cambia la propiedad `paginador`.
   * Las páginas se recalculan en función del valor `last_page` del objeto `paginador`.
   */
  ngOnChanges() {
    const paginasAntesDespues = 3; // cuántas páginas mostrar antes y después de la página actual

    // Verificar si this.paginador está definido antes de acceder a sus propiedades
    if (this.paginador && this.paginador.current_page) {
      const currentPage = this.paginador.current_page;
      const lastPage = this.paginador.last_page;
      const startPage = Math.max(1, currentPage - paginasAntesDespues);
      const endPage = Math.min(lastPage, currentPage + paginasAntesDespues);

      this.paginas = [];
      for (let i = startPage; i <= endPage; i++) {
        this.paginas.push(i);
      }
    }
  }

  /**
   * Navega a la página especificada.
   *
   * @param {number} pagina - Número de página al cual navegar.
   */
  navigateRoute(pagina: number) {
    // alert('paginator');
    // // Mostrar el loader
    // toggleLoader(this.loaderService, true);
    // // Redireccionar inmediatamente
    // this.router.navigate([`/${this.route}/${pagina}`]);
    // console.log(this.route);
    // console.log(pagina);

    // // Ocultar el loader después de 3 segundos
    // setTimeout(() => {
    //   toggleLoader(this.loaderService, false);
    // }, 6000);
  }

  changePage(page: number) {
    this.pageChangeEvent.emit(page);
  }
}

<h6 class="">Unlimited Color</h6>
            <ul class="layout-grid unlimited-color-layout">
               <input id="ColorPicker1" [(ngModel)]="primary_color" type="color" value="#7366ff">
               <input id="ColorPicker2" [(ngModel)]="secondary_color" type="color" value="#f73164">
               <button type="button" class="color-apply-btn btn btn-primary color-apply-btn" (click)="applyColor()">Apply</button>
            </ul>
            <h6>Mix Layout</h6>
            <ul class="layout-grid customizer-mix">
               <li class="color-layout" data-attr="default" [ngClass]="{'active': MIXLayout == 'default'}"
                  (click)="customizeMixLayout('default')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-light sidebar"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li class="color-layout" data-attr="dark-sidebar" [ngClass]="{'active': MIXLayout == 'dark-sidebar'}"
                  (click)="customizeMixLayout('dark-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li class="color-layout" data-attr="dark-only" [ngClass]="{'active': MIXLayout == 'dark-only'}"
                  (click)="customizeMixLayout('dark-only')">
                  <div class="header bg-dark">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-dark body"> </li>
                     </ul>
                  </div>
               </li>
            </ul>
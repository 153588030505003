import { Component } from "@angular/core";
import { StepService } from "../../components/step/service/step.service";

@Component({
  selector: "app-one-page",
  templateUrl: "./one-page.component.html",
  styleUrls: ["./one-page.component.scss"],
})
export class OnePageComponent {
  constructor(public StepService: StepService) {}

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }
}

<app-breadcrumb
  [title]="'Inicio'"
  [items]="['Dashboard']"
  [active_item]="'Default'"
></app-breadcrumb>

<div class="container-fluid">
  <div class="d-flex gap-2 flex-column flex-lg-row">
    <div class="col-12 col-lg-3">
      <div class="row">
        <h3>Clientes</h3>
        <div class="col pt-2">
          <div class="row mb-3">
            <app-card-data-customer-y-credits
              [value]="totalCustomersData"
              customers="customers"
              title="Clientes Totales"
              description="Registrados."
              urlImagen="../assets/img-news/user-register-card.svg"
              tooltip="Sin Contar Rechazados"
            ></app-card-data-customer-y-credits>
          </div>
          <div class="row mb-3">
            <app-card-data-customer-y-credits
              [value]="totalActivatedData"
              customers="customers"
              title="Clientes Activos"
              description="Totales."
              urlImagen="../assets/img-news/user-actived-card.svg"
              tooltip="Activos"
            ></app-card-data-customer-y-credits>
          </div>
          <div class="row mb-3">
            <app-card-data-customer-y-credits
              [value]="totalNotActivatedData"
              customers="customers"
              title="Clientes No Activos"
              description="Totales."
              urlImagen="../assets/img-news/user-noactived-card.svg"
              tooltip="No Activos"
            ></app-card-data-customer-y-credits>
          </div>
          <div class="row mb-3">
            <app-card-data-customer-y-credits
              [value]="totalCustomersRejectedData"
              customers="customers"
              title="Clientes Rechazados"
              description="Totales."
              urlImagen="../assets/img-news/user-rejected-card.svg"
              tooltip="Rechazados"
            ></app-card-data-customer-y-credits>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <h3 class="text-end pe-3 mb-3">Créditos y Cartera</h3>
      <div class="row p-0 m-0">
        <div class="col-12 col-xl-6 pe-1 mb-3">
          <app-card-data-customer-y-credits
            [value]="totalCreditsMoraBalanceWithdrawnLessSavingsData"
            title="Vigente Total"
            description="Mora Saldo Vigente Total."
            urlImagen="../assets/img-news/financial-card.svg"
            tooltip="Contando Intereses"
          ></app-card-data-customer-y-credits>
        </div>
        <div class="col-12 col-xl-6 pe-1 mb-3">
          <app-card-data-customer-y-credits
            [value]="totalOutstandingCreditsBalanceTotalData"
            title="Retirado"
            description="Créditos en Mora Saldo Retirado."
            urlImagen="../assets/img-news/credit-card.svg"
            tooltip="Menos Abonos"
          ></app-card-data-customer-y-credits>
        </div>

        <div class="col-12 col-xl-6 pe-1 mb-3">
          <app-card-data-customer-y-credits
            [value]="totalWithdrawnCreditsData"
            title="Retirados"
            description="Créditos retirados sin contar."
            urlImagen="../assets/img-news/retired-card.svg"
            tooltip="Anulados y sin Confirmar"
          ></app-card-data-customer-y-credits>
        </div>
        <div class="col-12 col-xl-6 pe-1 mb-3">
          <app-card-data-customer-y-credits
            [value]="totalOutstandingPortfolioData"
            title="Cartera Total Vigente"
            description="Pendiente de pago Entregada."
            urlImagen="../assets/img-news/icon-retired-card.svg"
            tooltip="Cartera Total Vigente"
          ></app-card-data-customer-y-credits>
        </div>

        <div class="col-12 col-xl-6 pe-1 mb-3">
          <app-card-data-customer-y-credits
            [value]="totalOutstandingPendingPaymentData"
            title="Pendiente de Pago"
            description="Cartera Total Vigente."
            urlImagen="../assets/img-news/dinner-card.svg"
            tooltip="Entregada y No Entregada"
          ></app-card-data-customer-y-credits>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-alert></app-modal-alert>
<app-one-page *ngIf="stepNew === 1"></app-one-page>
<!-- <app-browser-default *ngIf="stepNew === 2"></app-browser-default> -->
<!-- <app-second-page *ngIf="stepNew === 2"></app-second-page> -->
<app-third-page *ngIf="stepNew === 2"></app-third-page>
<form [formGroup]="allyForm">
  <!-- NÚMERO DE CELULAR -->
  <div class="four_page" *ngIf="stepNew === 3">
    <div class="important">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span> Paso 1 </span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="four_page_content">
      <div class="info_content">
        <div class="info">
          <span><i class="fa-solid fa-phone"></i></span>
          <h2 class="text-start">
            Para ofrecerte un mejor servicio verificaremos tu número de celular.
          </h2>
        </div>

        <div class="input_phone">
          <div
            class="container_phone"
            [ngStyle]="{
              borderColor: validateCampo(allyForm, 'phone_1') ? '#F66B7E' : null
            }"
          >
            <input
              type="text"
              placeholder="Ingresa tu número de celular"
              type="number"
              id="phone_1"
              value=""
              formControlName="phone_1"
              required
            />
            <span
              *ngIf="validateCampo(allyForm, 'phone_1')"
              class="input_phone_error"
              ><i class="fa-solid fa-circle-xmark"></i
            ></span>
          </div>
          <span
            *ngIf="validateCampo(allyForm, 'phone_1') as error"
            class="message_error"
            >{{ error }}</span
          >
        </div>

        <div class="button_phone">
          <button type="button" (click)="validarCelular()">
            Enviar código
          </button>
          <p>
            Recibirás un código a tu <b>WhatsApp</b> para continuar con el
            proceso
          </p>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <app-five-page *ngIf="stepNew === 4"></app-five-page>
  <!-- FOTO FRONTAL -->
  <div class="six_page" *ngIf="stepNew === 5">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Toma la <b>foto frontal</b> de tu documento de manera
            <b>horizontal</b>, centrada y con buena iluminación
          </h2>
        </div>

        <div class="six_screen">
          <label for="fileInput">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'frontal')"
              #fileInput
              style="display: none"
            />
            <img
              src="../../../../assets/img-news/screen-frontal.webp"
              alt="FOTO FRONTAL"
              *ngIf="!imagenFrontalVisible"
            />
          </label>

          <img
            [src]="documentFrontImage"
            *ngIf="imagenFrontalVisible"
            class="image-screen"
          />
        </div>

        <div class="button_screen" *ngIf="!imagenFrontalVisible">
          <label for="fileInput" *ngIf="verificacionMetamap === false">
            <input
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'frontal')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenFrontalVisible">
          <label for="fileInput" class="button-return">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'frontal')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br />
      <br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>
  <!-- FOTO REVERSO -->
  <div class="six_page" *ngIf="stepNew === 6">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Toma la <b>foto del reverso</b> de tu documento de manera
            <b>horizontal</b>, centrada y con buena iluminación
          </h2>
        </div>

        <div class="six_screen" id="cameraOutput">
          <label for="fileInput2">
            <input
              type="file"
              id="fileInput2"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'trasera')"
              #fileInput
              style="display: none"
            />
            <img
              src="../../../../assets/img-news/screen-trasera.webp"
              alt="FOTO TRASERA"
              *ngIf="!imagenTraseraVisible"
            />
          </label>

          <img
            [src]="documentBackImage"
            *ngIf="imagenTraseraVisible"
            class="image-screen"
          />
        </div>

        <div class="button_screen" *ngIf="!imagenTraseraVisible">
          <label for="fileInput2" *ngIf="verificacionMetamap === false">
            <input
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'trasera')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenTraseraVisible">
          <label for="fileInput" class="button-return">
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'trasera')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br /><br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- FOTO SELFIE -->
  <div class="six_page" *ngIf="stepNew === 7">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Nubes Sol" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info">
          <h2>
            Ahora toma una <b>foto de tu rostro</b> con buena iluminación, de
            manera
            <b>vertical, sin gafas ni otros elementos</b>
          </h2>
        </div>

        <div class="six_screen" id="cameraOutput">
          <label for="fileInput3">
            <input
              id="fileInput3"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'selfie')"
              #fileInput
              style="display: none"
            />
            <img
              src="../../../../assets/img-news/screen-selfie.webp"
              alt="FOTO SELFIE"
              *ngIf="!imagenSelfieVisible"
              class="six_selfie"
            />
          </label>

          <img
            [src]="selfieImage"
            *ngIf="imagenSelfieVisible"
            class="image-selfie"
          />
        </div>

        <div class="button_screen" *ngIf="!imagenSelfieVisible">
          <label for="fileInput3" *ngIf="verificacionMetamap === false">
            <input
              id="fileInput3"
              type="file"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'selfie')"
              #fileInput
              style="display: none"
            />
            <button #fileInput type="button" class="button_screen_border">
              Tomar foto <i class="fa-solid fa-camera"></i>
            </button>
          </label>
        </div>

        <div class="buttons-total" *ngIf="imagenSelfieVisible">
          <label for="fileInput" class="button-return">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              capture="environment"
              (change)="onFileSelected($event, 'selfie')"
              #fileInput
              style="display: none"
            />
            <i class="fa-solid fa-rotate-left"></i> Reintentar
          </label>
          <button class="button-next" (click)="nextStep()">
            Siguiente <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="sticker">
      <br />
      <br />
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- Paso 4: CODIGO DEL PROVEEDOR -->
  <div class="six_page" *ngIf="stepNew === 8">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span>Paso 3</span>
      <img src="../../../../assets/img-news/fondo-auto.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="code_proveedor">
          <div class="info_code_proveedor">
            <span><i class="fa-solid fa-tag"></i></span>
            <h2>Código de tu proveedor.</h2>
          </div>

          <div class="input_code_proveedor">
            <div
              class="container_code_proveedor"
              [ngStyle]="{
                borderColor: validateCampo(allyForm, 'code_shop')
                  ? '#F66B7E'
                  : null
              }"
            >
              <div class="d-flex pe-2 ">
                <input
                type="number"
                id="code_shop"
                placeholder="Ingresa el código"
                formControlName="code_shop"
                class="w-75"
                required
                [(ngModel)]="code"
              />
              <!-- <img style="width: 6rem; object-fit: contain;" src="https://res.cloudinary.com/dovavvnjx/image/upload/v1698768615/Superf%C3%BCds_logo_1_yuust3.png" alt="Logo Proveedor"> -->
              </div>
              <span
                *ngIf="validateCampo(allyForm, 'code_shop')"
                class="input_code_proveedor_error"
                ><i class="fa-solid fa-circle-xmark"></i
              ></span>
            </div>
            <span
              *ngIf="validateCampo(allyForm, 'code_shop') as error"
              class="message_error"
              >{{ error }}</span
            >
          </div>

          <div class="button_code">
            <button type="button" (click)="nextStep()">
              Continuar <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>
              Si no tienes el código diligenciado,
              <b>por favor solicita el código a tu proveedor</b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>
  <!--  NOMBRE Y APELLIDO  -->
  <div class="six_page" *ngIf="stepNew === 9">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span>Paso 4</span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-user"></i></span>
            <h2>Verifiquemos tu nombre y apellidos.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'name')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="name"
                  required
                  placeholder="Nombres"
                  formControlName="name"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'name')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'name') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'last_name')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="last_name"
                  required
                  formControlName="last_name"
                  placeholder="Apellidos"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'last_name')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'last_name') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Continuar <i class="fa-solid fa-circle-right"></i>
            </button>
            <!-- <p>
              Por favor, verifica que los datos proporcionados sean correctos y
              coincidan con tu información <b>real</b>
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- IDENTIFICACION Y TIPO DE IDENTIFICACION -->
  <div class="six_page" *ngIf="stepNew === 10">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-address-card"></i></span>
            <h2>Tipo de identificación.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'identification_type')
                    ? '#F66B7E'
                    : null
                }"
              >
                <select
                  id="identification_type"
                  required
                  formControlName="identification_type"
                >
                  <option value="">Seleccionar</option>
                  <option value="CC">Cédula de ciudadanía</option>
                  <option value="CE">Cédula de extranjería</option>
                  <option value="PPT">PPT</option>
                </select>
                <span
                  *ngIf="validateCampo(allyForm, 'identification_type')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'identification_type') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'identification')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="identification"
                  required
                  formControlName="identification"
                  placeholder="Introduce tu número de cédula"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'identification')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'identification') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>Selecciona el tipo de tu documento</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- FECHA NACIMIENTO Y FECHA EXPEDICCION -->
  <div class="six_page" *ngIf="stepNew === 11">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-address-card"></i></span>
            <h2>Fecha de nacimiento.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal bl-date"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'identification_type')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="date"
                  id="date_of_birth"
                  required
                  formControlName="date_of_birth"
                  placeholder="Introduce tu número de cédula"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'date_of_birth')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'date_of_birth') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="info_personal_one">
              <span><i class="fa-solid fa-address-card"></i></span>
              <h2>Fecha Expedición Documento.</h2>
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal bl-date"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'identification')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="date"
                  id="emission_date"
                  required
                  formControlName="emission_date"
                  placeholder="Fecha expedción del documento"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'emission_date')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'emission_date') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- CORREO Y PASSWORD -->
  <div class="six_page" *ngIf="stepNew === 12">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-envelope"></i></span>
            <h2>Ingresa tu correo electrónico.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'email')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="email"
                  id="email"
                  required
                  formControlName="email"
                  placeholder="Introduce tu correo electrónico"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'email')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'email') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="info_personal_one">
            <span><i class="fa-solid fa-lock"></i></span>
            <h2>Crea una contraseña.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'password')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="password"
                  id="password"
                  required
                  formControlName="password"
                  (input)="onPasswordInput()"
                  maxlength="4"
                  placeholder="Ingresa 4 dígitos numéricos"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'password')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'password') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>
              Esta información registrada te dará acceso a tu cuenta en zíro
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- DIRECCION -->
  <div class="six_page" *ngIf="stepNew === 13">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-location-dot"></i></span>
            <h2>Ingresa tu dirección personal.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'address')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="address"
                  required
                  formControlName="address"
                  placeholder="Dirección de tu domicilio"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'address')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'address') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>Validaremos que tu ubicación coincida con tu información real</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- CELULAR 1 Y CELULAR 2 -->
  <div class="six_page" *ngIf="stepNew === 14">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <!-- <div class="info_personal_one">
            <span><i class="fa-solid fa-phone"></i></span>
            <h2>Danos tu número celular.</h2>
          </div> -->

          <!-- <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'phone_1_valido')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="number"
                  id="phone_1_valido"
                  required
                  placeholder="Número de teléfono alterno"
                  [disabled]="true"
                  [value]="phoneStorage"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'phone_1_valido')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'phone_1_valido') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div> -->

          <input
            hidden
            type="number"
            id="phone_1_valido"
            required
            placeholder="Número de teléfono alterno"
            [disabled]="true"
            [value]="phoneStorage"
          />

          <div class="info_personal_one">
            <span><i class="fa-solid fa-phone"></i></span>
            <h2>Proporciona otro número celular de contacto.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'phone_2')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="number"
                  id="phone_2"
                  formControlName="phone_2"
                  required
                  placeholder="Celular alternativo"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'phone_2')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'phone_2') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>

            <p>
              Al proporcionar un segundo número de contacto,<b>
                autorizas a zíro a comunicarse contigo a través de este medio
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- VISTA INTERMEDIA -->
  <div class="six_page" *ngIf="stepNew === 15">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <span> Paso 5 </span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="success_next">
            <img src="../../../../assets/img-news/negocio.webp" alt="Negocio" />

            <p>
              Continuemos con tu registro. <br />
              Ahora te pediremos <b>información sobre tu negocio.</b>
            </p>
          </div>

          <div class="button_personal">
            <button type="button" (click)="incrementar()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- NIT  -->
  <div class="six_page" *ngIf="stepNew === 16">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-user-tag"></i></span>
            <h2>Ingresa tu NIT o cédula.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'nit') ? '#F66B7E' : null
                }"
              >
                <input
                  type="text"
                  id="nit"
                  required
                  formControlName="nit"
                  placeholder="Ingresa tu NIT o número de cédula"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'nit')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'nit') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Verificar <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>Sin dígito de verificación</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- <app-alert-info
    titulo="Ten en cuenta..."
    descripcion="Estamos verificando si podemos empezar un historial crediticio contigo. Si tu caso llega a ser validado, te enviaremos un link via WhatsApp al número que ingresaste para seguir con el proceso de registro. "
    textoBoton="Entendido"
    ></app-alert-info> -->

  <!-- <app-alert-success
    titulo="Ten en cuenta..."
    descripcion="Estamos verificando si podemos empezar un historial crediticio contigo. Si tu caso llega a ser validado, te enviaremos un link via WhatsApp al número que ingresaste para seguir con el proceso de registro. "
    textoBoton="Entendido"
    ></app-alert-success> -->

  <!-- <app-alert-error
    titulo="Ten en cuenta..."
    descripcion="Estamos verificando si podemos empezar un historial crediticio contigo. Si tu caso llega a ser validado, te enviaremos un link via WhatsApp al número que ingresaste para seguir con el proceso de registro. "
    textoBoton="Entendido"
    ></app-alert-error> -->

  <!-- <app-alert-warning
    titulo="Ten en cuenta..."
    descripcion="Estamos verificando si podemos empezar un historial crediticio contigo. Si tu caso llega a ser validado, te enviaremos un link via WhatsApp al número que ingresaste para seguir con el proceso de registro. "
    textoBoton="Entendido"
    ></app-alert-warning> -->

  <!-- TIPO DE NEGOCIO Y NOMBRE DEL NEGOCIO -->
  <div class="six_page" *ngIf="stepNew === 17">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-store"></i></span>
            <h2>Cuéntanos sobre tu negocio.</h2>
          </div>
          <div class="inputs_doble">
            <div class="input_info_personal">
              <ng-select
                formControlName="customer_business_types_id"
                [items]="businessTypes"
                bindLabel="name"
                bindValue="id"
                [searchable]="true"
                [clearable]="false"
                required
                id="ng-select"
                class="custom"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.name ? item.name : 'Tipo de negocio' }}
                </ng-template>
              </ng-select>

              <span
                *ngIf="
                  validateCampo(allyForm, 'customer_business_types_id') as error
                "
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'business_name')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="business_name"
                  required
                  formControlName="business_name"
                  placeholder="Nombre de tu negocio"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'business_name')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'business_name') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="info_personal_one">
            <span><i class="fa-solid fa-clipboard-check"></i></span>
            <h2>¿Estás registrado en la cámara de comercio?</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <ng-select
                formControlName="registered_chamber_commerce"
                [items]="registeredChamberCommerceOptions"
                bindValue="value"
                bindLabel="label"
                [searchable]="false"
                [clearable]="false"
                required
                id="registered_chamber_commerce"
                class="custom"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.label ? item.label : 'Selecciona una opción' }}
                  <!-- Agrega una salida para depurar -->
                </ng-template>
              </ng-select>

              <span
                *ngIf="
                  validateCampo(
                    allyForm,
                    'registered_chamber_commerce'
                  ) as error
                "
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>Validaremos que tus datos coincidan con tu información real</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- COMPRAS AL MES -->
  <div class="six_page" *ngIf="stepNew === 18">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-wallet"></i></span>
            <h2>¿Cuánto cupo quieres solicitar a zíro?</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'value_request')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="value_request"
                  required
                  formControlName="value_request"
                  (input)="formatInputToCurrency($event)"
                  placeholder="Indica el monto que deseas solicitar"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'value_request')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'value_request') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- CIUDAD Y DIRECCION DEL NEGOCIO -->
  <div class="six_page" *ngIf="stepNew === 19">
    <div class="important_six">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-arrow-left"></i
      ></span>
      <img src="../../../../assets/img-news/nubes-sol.webp" alt="Auto" />
    </div>
    <div class="six_page_content">
      <div class="info_content_six">
        <div class="info_personal">
          <div class="info_personal_one">
            <span><i class="fa-solid fa-map-pin"></i></span>
            <h2>Elige la ciudad e ingresa la dirección de tu negocio.</h2>
          </div>

          <div class="inputs_doble">
            <div class="input_info_personal">
              <ng-select
                class="custom-select ng-selects"
                formControlName="city_id"
                [items]="cities"
                bindLabel="name"
                bindValue="id"
                [searchable]="true"
                [clearable]="false"
                placeholder=""
                class="custom"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.name ? item.name : 'Elige tu ciudad' }}
                  <!-- Agrega una salida para depurar -->
                </ng-template>
              </ng-select>

              <span
                *ngIf="validateCampo(allyForm, 'city_id') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>

            <div class="input_info_personal">
              <div
                class="container_info_personal"
                [ngStyle]="{
                  borderColor: validateCampo(allyForm, 'businness_address')
                    ? '#F66B7E'
                    : null
                }"
              >
                <input
                  type="text"
                  id="businness_address"
                  required
                  formControlName="businness_address"
                  placeholder="Dirección de tu negocio"
                />
                <span
                  *ngIf="validateCampo(allyForm, 'businness_address')"
                  class="input_info_personal_error"
                  ><i class="fa-solid fa-circle-xmark"></i
                ></span>
              </div>

              <span
                *ngIf="validateCampo(allyForm, 'businness_address') as error"
                class="message_error"
                >{{ error }}</span
              >
            </div>
          </div>

          <div class="button_personal">
            <button type="button" (click)="nextStep()">
              Siguiente <i class="fa-solid fa-circle-right"></i>
            </button>
            <p>Validaremos que tus datos coincidan con tu información real</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sticker">
      <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
    </div>
  </div>

  <!-- <app-alert-info
    class="six_page"
    *ngIf="stepNew === 20"
    titulo="Autorización de tratamiento de datos personales"
    descripcion="Autorizo de manera voluntaria e informada a la empresa Somos zíro S.A.S con NIT 901.592.209-1, en su calidad de responsable de tratamiento de datos, para que recolecte, almacene, utilice y procese mis datos personales con las siguientes finalidades:

    1.        Consultar mi información en centrales de riesgos
    2.        Envío de información promocional e información sobre los productos o servicios que maneja zíro
    3.        Gestión de mi cuenta de cliente y para transferir mis datos personales a terceros para fines comerciales
    
    Entiendo que la información proporcionada incluirá datos personales, como mi nombre, dirección, número de teléfono, dirección de correo electrónico, y otros datos relevantes que puedan ser necesarios para las finalidades mencionadas.

    Esta autorización es válida desde la fecha en que autorizó y estará en vigor mientras sea necesario para cumplir con las finalidades mencionadas anteriormente o hasta que sea revocada por mí.
    
    Puede consultar más sobre nuestra política de tratamiento de datos personales en el siguiente link"
    link="https://somosziro.com/politica-de-privacidad/"
    textoBoton="Acepto"
    [isSuccess]="0"
  ></app-alert-info> -->

  <!-- VISTA ALERTA -->

  <div class="alert-warning-background" *ngIf="stepNew === 20">
    <div class="alert-warning">
      <span (click)="decrementar()"
        ><i class="fa-solid fa-circle-xmark"></i
      ></span>

      <div class="warning-content">
        <img
          src="../../../../assets/img-news/logo-ilustration.svg"
          alt="Fondo auto"
          class="mx-auto d-block img-fluid pb-2"
          width="120"
          height="120"
        />

        <h1>Autorizaciones</h1>

        <div class="warning">
          <h4 class="text-start">
            <b
              >Paso 1: Tratamiento de datos y verificación en centrales de
              riesgo:</b
            >
          </h4>
          <strong class="pb-2 fw-normal">Selecciona la opción:</strong>
          <div class="d-flex gap-3">
            <div class="checksbox">
              <label for="authorization_treatment_data">
                <input
                  type="checkbox"
                  id="authorization_treatment_data"
                  class="ui-checkbox"
                  (change)="
                    checkboxChanged('authorization_treatment_data', $event)
                  "
                  name="authorization_treatment_data"
                  formControlName="authorization_treatment_data"
                />
              </label>
            </div>
            <label for="authorization_treatment_data">
              Autorizo a Somos Zíro SAS a consultar mi información en centrales
              de riesgo y a enviarme información. También acepto los términos de
              la 
              <b
                ><a
                  style="color: rgb(0, 89, 255); text-decoration: underline"
                  href="https://somosziro.com/politica-de-privacidad/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Política de Tratamiento de Datos Personales.</a
                ></b
              >
            </label>
          </div>
          <br />
          <h4 class="text-start">
            <b>Paso 2: Autorizo a Somos Ziro SAS a contactarme via:</b>
          </h4>

          <div class="checksbox">
            <label for="comunication_all">
              <input
                type="checkbox"
                id="comunication_all"
                class="ui-checkbox"
                value="comunication_all"
                name="comunication_all"
                formControlName="comunication_all"
                checked
                onclick="return false;"
                onkeydown="return false;"
              />

              Whatsapp y llamadas telefónicas al número celular registrado en mi
              perfil
            </label>
            <label for="comunication_email">
              <input
                type="checkbox"
                id="comunication_email"
                class="ui-checkbox"
                value="comunication_email"
                name="comunication_email"
                formControlName="comunication_email"
                checked
                onclick="return false;"
                onkeydown="return false;"
              />
              Correo
            </label>
            <label for="comunication_message">
              <input
                type="checkbox"
                id="comunication_message"
                class="ui-checkbox"
                value="mensajeTexto"
                (change)="checkboxChanged('comunication_message', $event)"
                name="comunication_message"
                formControlName="comunication_message"
              />
              Mensaje de texto
            </label>
          </div>

          <label class="lh-base pt-2 autorizo negrita">
            <b
              >*Comprendo que WhatsApp y correo se utilizarán para
              confirmaciones de pedidos y códigos, y las llamadas telefónicas
              para validar información personal y asuntos relacionados con
              compras, incluyendo posibles cobros</b
            >
          </label>
        </div>
      </div>

      <div class="input-button">
        <button type="button" (click)="register()">Acepto</button>
      </div>
    </div>
  </div>

  <!-- <div class="six_page bl-warning" >
    <div class="container-fluid">
      <div class="row">
        
      </div>
    </div>
  </div> -->

  <!-- <app-alert-success-warning
    class="six_page"
    *ngIf="stepNew === 20"
    titulo="¡Espera unos minutos mientras evaluamos tu solicitud!"
    descripcion="Pronto recibirás un enlace a tu WhatsApp y correo para completar tu registro y activar tu cupo"
  ></app-alert-success-warning> -->
</form>

<app-modal-alert></app-modal-alert>

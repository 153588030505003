// shared-step.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private contador = 1;

  incrementar() {
    this.contador++;
  }

  decrementar() {
    this.contador--;
  }

  setStep(nuevoStep: number) {
    this.contador = nuevoStep;
  }

  getStep() {
    return this.contador;
  }
}

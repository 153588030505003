<div class="loader-wrapper" *ngIf="loaderService.isLoading$ | async">
  <div class="loader">
    <img
      src="../../../../assets/images/logo/logo.gif"
      width="300"
      alt="Loader"
    />
    <div class="text-center text-white px-2 ">
      {{ message }}
    </div>
  </div>
</div>

<div class="five_page">
  <div class="important_six">
    <span (click)="decrementar()"
      ><i class="fa-solid fa-circle-arrow-left"></i
    ></span>

    <span>Paso 2</span>
    <img src="../../../../assets/img-news/fondo-auto.webp" alt="Nubes Sol" />
  </div>
  <div class="five_page_content">
    <div class="info_content">
      <h1>Por tu seguridad verificaremos tu identidad</h1>

      <ul>
        <li>Prepara tu <b>cédula y un teléfono con cámara</b></li>

        <li>
          Asegúrate de que
          <b>la cédula esté en una superficie plana y bien iluminada</b>. Debe
          ser el <b>documento original</b>; no aceptamos fotocopias ni otros
          documentos
        </li>
        <li>
          Solo <b>si eres usuario de un proveedor aliado a zíro, avanza con el registro.</b> Ten el código proporcionado por tu proveedor a la mano
        </li>
      </ul>

      <button type="button" (click)="incrementar()">
        Siguiente <i class="fa-solid fa-circle-right"></i>
      </button>
    </div>
  </div>
  <div class="sticker">
    <img src="../../../assets/img/iconoEstres.webp" alt="Logo" />
  </div>
</div>

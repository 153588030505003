<div class="header-wrapper d-flex align-items-center justify-content-between">
  <div class="header-logo-wrapper col-auto p-0 d-flex gap-2 align-items-center">
    <div class="toggle-sidebar" (click)="sidebarToggle()">
      <div class="status_toggle sidebar-toggle d-flex">
        <i class="fa-solid fa-bars" style="color: #064853; font-size: 30px; padding-right: 10px;"></i>
      </div>
    </div>

    <div class="left-side-header col ps-0 d-md-block">
      <div *ngIf="role !== 5">
        <app-search></app-search>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="role !== 5" class="left-side-header col ps-0 d-none d-md-block"> -->

  <div class="user">
    <!-- <app-maximize></app-maximize> -->
    <div class="profile-nav onhover-dropdown pe-0 py-0 me-0">
      <app-account></app-account>
    </div>
  </div>

  <!-- 
  <div class="nav-right">
    <ul class="nav-menus">
      <li class="mode" (click)="layoutToggle()">
        <app-mode></app-mode>
      </li>
      <li class="maximize">
        <app-maximize></app-maximize>
      </li>
      <li class="profile-nav onhover-dropdown pe-0 py-0 me-0">
        <app-account></app-account>
      </li>
    </ul>
  </div> -->
</div>

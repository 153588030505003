<div class="">
  <!-- solicitar codigo otp -->
  <div class="col-12 container-fluid px-0" *ngIf="step == 1">
    <app-pagare-send-code
      (changeStepAndCustomerId)="changeStepAndCustomerId($event)"
    />
  </div>

  <!-- firmar pagare -->
  <div *ngIf="step == 2">
    <div class="row">
      <div class="col-12 px-0">
        <div class="bl-header-registro">
          <div class="bl-header-registro--imagen">
            <img src="../../../../assets/img-news/nubes-sol.svg" alt="Auto" />
          </div>
          <div class="six_page_content">
            <div class="info_content_six"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="code_otp_signature container">
      <div class="info_code_otp_signature">
        <form [formGroup]="sixDigitForm">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6">
              <h3 for="codigo" class="text-start">Ingresa tu código aquí:</h3>

              <div class="more_info">
                Recuerda que con este código estás firmando: <br />
                <ul>
                  <li class="pb-2">
                    Contrato de crédito
                    <a
                      aria-label="Contrato de Crédito Zíro - En Blanco.pdf"
                      rel="noreferrer"
                      target="_blank"
                      href="..//..//../assets/download/Contrato de Crédito Zíro - En Blanco.pdf"
                      download="Contrato de Crédito Zíro - En Blanco.pdf"
                    >
                      (Descargar).
                    </a>
                  </li>
                  <li>
                    Pagaré y Carta de Instrucciones
                    <a
                      aria-label="Pagaré y Carta de Instrucciones Zíro - En Blanco.pdf"
                      rel="noreferrer"
                      target="_blank"
                      href="..//..//../assets/download/Pagaré y Carta de Instrucciones Zíro - En Blanco.pdf"
                      download="Pagaré y Carta de Instrucciones Zíro - En Blanco.pdf"
                    >
                      (Descargar).
                    </a>
                  </li>
                </ul>
              </div>
              <div class="code_signature">
                <div class="code_spacing mb-4">
                  <input
                    autofocus
                    type="number"
                    formControlName="codeOtp"
                    [maxlength]="6"
                    placeholder="Tu código"
                    id="codeOtp"
                    value=""
                    required
                  />
                </div>
              </div>

              <div class="accept">
                <div class="checkboxes">
                  <div class="accept_signature">
                    <input
                      id="check"
                      type="checkbox"
                      class="ui-checkbox"
                      formControlName="acceptTerms"
                      (change)="toggleCheckbox('acceptTerms')"
                    />
                    <label for="check">Estoy de acuerdo</label>
                  </div>

                  <div class="accept_signature" (click)="abrirModal()">
                    <input
                      id="check-not"
                      type="checkbox"
                      class="ui-checkbox-not"
                      formControlName="notAcceptTerms"
                      (change)="toggleCheckbox('notAcceptTerms')"
                    />
                    <label for="check-not">No estoy de acuerdo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accept">
            <button
              [ngClass]="{
                'clase-activa':
                  sixDigitForm.valid && sixDigitForm.get('acceptTerms')?.value,
                'clase-inactiva':
                  !sixDigitForm.valid || !sixDigitForm.get('acceptTerms')?.value
              }"
              [disabled]="!sixDigitForm.get('acceptTerms')?.value"
              (click)="submitForm()"
            >
              Firmar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- mensaje finalización del proceso -->
  <div class="col-12" *ngIf="step == 3">
    <app-cupo-aprobado />
  </div>
</div>

<app-not-accept
  *ngIf="modalServicePagare.isOpen$ | async"
  titulo="No estoy de acuerdo"
  textoBoton="Enviar"
  isSuccess="1"
></app-not-accept>
<app-modal-alert></app-modal-alert>
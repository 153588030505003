import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmPasswordService } from "./services/confirm-password.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";

@Component({
  selector: "app-confirm-password",
  templateUrl: "./confirm-password.component.html",
  styleUrls: ["./confirm-password.component.scss"],
})
export class ConfirmPasswordComponent {
  public show: boolean = false;
  public tokenChangePassword: string;
  public passwordForm: FormGroup;
  public submitted: boolean;
  recoveryConfirmPasswordForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getTokenChangePassword: ConfirmPasswordService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private modalService: ModalServiceAlert
  ) {
    this.recoveryConfirmPasswordForm = this.fb.group(
      {
        password1: [null, [Validators.required, this.validatePassword]],
        password2: [null, Validators.required],
      },
      { validator: this.matchPasswords }
    );
  }

  role_id: number;

  validatePassword(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const pattern = /^\d{4}$/;
    if (!pattern.test(control.value)) {
      return { minlength: true };
    }
    return null;
  }

  matchPasswords(control: AbstractControl): { [key: string]: boolean } | null {
    const password1 = control.get("password1");
    const password2 = control.get("password2");

    if (password1?.value !== password2?.value) {
      return { passwordMismatch: true };
    }

    return null;
  }

  recovery() {
    toggleLoader(this.loaderService, true);
    if (this.recoveryConfirmPasswordForm.valid) {
      const newPassword =
        this.recoveryConfirmPasswordForm.get("password2")?.value;

      // Hacer una solicitud HTTP POST al backend para actualizar la contraseña
      this.getTokenChangePassword
        .updatePassword(this.tokenChangePassword, newPassword)
        .subscribe(
          (response) => {
            // Manejar la respuesta del backend (éxito)
            toggleLoader(this.loaderService, false); // Ocultar el indicador de carga
            this.router.navigate(["/iniciar-sesion"]);

            this.modalService.openModal(
              "Éxito",
              "La nueva contraseña se actualizo con éxito",
              "success",
              10000
            );
          },
          (error) => {
            // Manejar errores de la respuesta del backend
            toggleLoader(this.loaderService, false); // Ocultar el indicador de carga

            this.modalService.openModal(
              "Error",
              "Hubo un error al actualizar la contraseña, por favor vuelve a intentar.",
              "error"
            );
          }
        );
    } else {
      toggleLoader(this.loaderService, false); // Ocultar el indicador de carga

      this.modalService.openModal(
        "Alerta!",
        "Por favor, los campos son requeridos y deben cumplir los requerimientos.",
        "warning",
        10000
      );
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const token = params["token"];
      if (!token) {
        this.router.navigate(["/restablecer-contraseña"]);
      } else {
        this.getTokenChangePassword.getTokenChangePassword(token).subscribe(
          (response) => {
            this.tokenChangePassword = response.message.token;
            this.role_id = response.message.role_id;
            if (this.role_id !== 5) {
              // Deshabilitar las validaciones de contraseña excepto matchPasswords
              this.recoveryConfirmPasswordForm
                .get("password1")
                ?.clearValidators();
              this.recoveryConfirmPasswordForm
                .get("password1")
                ?.updateValueAndValidity();
              this.recoveryConfirmPasswordForm
                .get("password2")
                ?.clearValidators();
              this.recoveryConfirmPasswordForm
                .get("password2")
                ?.updateValueAndValidity();
            }
          },
          (error) => {
            if (error.status === 404) {
              toggleLoader(this.loaderService, false);

              this.modalService.openModal(
                "Alerta",
                "El token es invalido o ya expiro, por favor vuelve a realizar el proceso.",
                "error",
                10000
              );
              this.router.navigate(["/restablecer-contraseña"]);
            } else {
              toggleLoader(this.loaderService, false);
              this.modalService.openModal(
                "Error",
                "Ocurrio un error, vuelve a intentarlo mas tarde.",
                "error"
              );
              this.router.navigate(["/restablecer-contraseña"]);
            }
          }
        );
      }
    });
  }

  showPassword() {
    this.show = !this.show;
  }
}

<div class="alert-warning-background">
  <div class="alert-warning">
    <span (click)="cerrarModal()">
      <i class="fa-solid fa-circle-xmark"></i>
    </span>

    <!-- <div class="warning-image" *ngIf="isSuccess === 0 ? false : true">
      <img
        src="../../../../assets/img-news/alert-warning.svg"
        alt="Fondo auto"
      />
    </div> -->

    <div class="warning-content">
      <h2>{{ titulo }}</h2>
      <div class="warning">
        ¿Qué acción deseas tomar?
        <br /><br />

        <div class="checksbox">
          <label for="asesor">
            <input type="radio" id="asesor" class="ui-checkbox" name="opciones" [(ngModel)]="reason"
              value="Hablar con un asesor" />
            Hablar con un asesor
          </label>
          <label for="noCupo">
            <input type="radio" id="noCupo" class="ui-checkbox" name="opciones" [(ngModel)]="reason"
              value="No quiero tener cupo activo" />
            No quiero tener cupo activo
          </label>
        </div>
        <br>
      </div>
    </div>

    <div class="input-button">
      <button type="button" [disabled]="!reason" (click)="saveForm()">
        {{ textoBoton }}
      </button>
    </div>
  </div>
</div>
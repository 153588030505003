<div>
    <form [formGroup]="formEditCredit">
        <div class="modal-header">
            <h4 class="modal-title">
                <span>Editar valores de crédito</span>
                <br />
                <div class="credit-details">
                    <span class="fw-bold">Obligación: </span>#{{ creditRequestnObligacion }} <br />
                    <span class="fw-bold"># Crédito: </span>#{{ creditId }} <br />
                    <span class="fw-bold">Estado: </span>{{ creditStateName }} <br />
                </div>
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label>Número de orden</label>
                        <input type="text" class="form-control" formControlName="order_number" />
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'order_number') as error">
                        {{ error }}
                    </p>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label>Valor del crédito</label>
                        <input type="text" class="form-control" formControlName="value_credit" />
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'value_credit') as error">
                        {{ error }}
                    </p>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label>Fecha de inicio</label>
                        <input class="form-control" type="date" formControlName="date_credit" />
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'date_credit') as error">
                        {{ error }}
                    </p>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label>Estado entrega</label>
                        <select 
                            name="state" 
                            class="form-control" 
                            formControlName="state">
                            <option value="2">Pendiente de entrega</option>
                            <option value="3">Entregado</option>
                        </select>
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'state') as error">
                        {{ error }}
                    </p>
                </div>

                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label>Razón de la modificación</label>
                        <textarea class="form-control" placeholder="Razón de la modificación" rows="3" cols="30"
                            formControlName="reason_edit"></textarea>
                    </div>
                    <p class="text-danger" *ngIf="validateCampo(formEditCredit, 'reason_edit') as error">
                        {{ error }}
                    </p>

                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button type="button" class="btn btn-primary" (click)="updateCredit()">
                        <i class="fas fa-save"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
import { Component, Input } from '@angular/core';
import { StepService } from '../step/service/step.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-success-warning',
  templateUrl: './alert-success-warning.component.html',
  styleUrls: ['./alert-success-warning.component.scss']
})
export class AlertSuccessWarningComponent {
  constructor(public StepService: StepService, private router: Router) {}

  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() textoBoton: string;
  @Input() redirectTo: string;

  visible: boolean = true;

  onButtonClick() {
    alert('¡Botón clickeado!');
    this.visible = false;
  }

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }

  redirectButton() {
    this.router.navigate(['/iniciar-sesion']);
  }
}

<div class="alert-warning-background">
  <div class="alert-warning">
    <span *ngIf="isSuccess === 0 ? false : true">
      <i class="fa-solid fa-circle-xmark"></i>
    </span>

    <div class="warning-image" *ngIf="isSuccess === 0 ? false : true">
      <img
        src="../../../../assets/img-news/alert-warning.svg"
        alt="Fondo auto"
      />
    </div>

    <div class="warning-content">
      <h2>{{ titulo }}</h2>
      <div class="warning">
        "Para brindarte un mejor servicio y mantenernos en contacto de manera
        efectiva, nos gustaría conocer tus preferencias en cuanto a los medios
        de comunicación que prefieres. <br> Por favor, selecciona los medios de
        contacto que más te convengan: <br><br>


        <div class="checksbox">
          <label for="all">
            <input type="checkbox" (change)="checkboxChanged('all', $event)" id="all" class="ui-checkbox" />
            Por todos los medios que dispone zíro
          </label>
          <label for="email">
            <input type="checkbox" id="email" class="ui-checkbox" />
            Correo electrónico
          </label>
          <label for="text">
            <input type="checkbox" id="text" class="ui-checkbox" />
            Mensaje de texto
          </label>
          <label for="personal">
            <input type="checkbox" id="personal" class="ui-checkbox" />
            Teléfono diferente al personal
          </label>
          <label for="otro">
            <input type="checkbox" id="otro" class="ui-checkbox" />
            Otro (especifica cual)
          </label>
        </div> <br>
        <label for="acepto">
          <input type="checkbox" id="acepto" class="ui-checkbox" (change)="checkboxChanged('acepto', $event)" />
          Autorizo el
        contacto a través de WhatsApp y llamadas telefónicas al número
        registrado en mi perfil.
        </label>  Comprendo que las comunicaciones por WhatsApp
        incluyen confirmaciones de pedidos realizados con mi proveedor, así como
        códigos de verificación. Las llamadas telefónicas se utilizarán para
        validar información personal y para cuestiones relacionadas con la
        gestión de mis compras, incluyendo llamadas de cobranza si fuera
        necesario."
      </div>
    </div>

    <div class="input-button">
      <button type="button" (click)="incrementar()">
        {{ textoBoton }}
      </button>
    </div>
  </div>
</div>

<app-breadcrumb
  [title]="'Inicio'"
  [items]="['Dashboard']"
  [active_item]="'Default'"
></app-breadcrumb>
<div class="container-fluid default-dash">
  <div class="cards">
    <div class="e-card playing card-1">
      <div class="image"></div>

      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>

      <div class="infotop">
        <i class="fa fa-cart-plus fa-4x"></i><br />
        Creditos retirados
        <br />
        <h3>
          {{ withdrawnCredits | currency : '$' : 'symbol' : '1.0-0' }}
        </h3>
      </div>
    </div>

    <div class="e-card playing card-2">
      <div class="image"></div>

      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>

      <div class="infotop">
        <i class="fa fa-rocket fa-4x"></i><br />
        Cantidad retirados
        <br />
        <h3>
          {{ withdrawnCreditsCount  }}
        </h3>
      </div>
    </div>

    <div class="e-card playing card-3">
      <div class="image"></div>

      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>

      <div class="infotop">
        <i class="fa fa-user-plus fa-4x"></i><br />
        Total clientes
        <br />
        <h3>
          {{ totalCustomers | currency : '$' : 'symbol' : '1.0-0' }}
        </h3>
      </div>
    </div>

    <div class="e-card playing card-4">
      <div class="image"></div>

      <div class="wave"></div>
      <div class="wave"></div>
      <div class="wave"></div>

      <div class="infotop">
        <i class="fa fa-user-plus fa-4x"></i><br />
        Total solicitudes
        <br />
        <h3>
          {{ creditsRequestsCount | currency : '$' : 'symbol' : '1.0-0' }}
        </h3>
      </div>
    </div>
  </div>
</div>

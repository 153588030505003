import { statusPaymentService } from "./services/status-payment.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PaymentService } from "src/app/commerce/paysuccess/services/paysuccess.service";
import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import JSConfetti from "js-confetti";
import { ModalServiceAlert } from "src/app/shared/components/modal-alert/services/modal-alert.service";

@Component({
  selector: "app-status-payment",
  templateUrl: "./status-payment.component.html",
  styleUrls: ["./status-payment.component.scss"],
})
export class StatusPaymentComponent {
  private routeSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private statusPaymentService: PaymentService,
    private router: Router,
    private loaderService: LoaderService,
    private ModalServiceAlert: ModalServiceAlert
  ) {}

  public names: String;
  public pay: number = 0;
  public state: number = 0;
  public status: boolean = true;
  jsConfetti = new JSConfetti();

  Confetti() {
    this.jsConfetti.addConfetti();
  }

  // ngOnInit(): void {
  //   toggleLoader(this.loaderService, true, "Validando tu pago...");

  //   this.routeSubscription = this.route.queryParamMap.subscribe(
  //     (queryParams) => {
  //       const transactionId = queryParams.get("id");
  //       console.log(transactionId);

  //       if (transactionId) {
  //         // Realiza la verificación y actualización del pago
  //         try {
  //           this.statusPaymentService
  //             .verifyAndProcessPayment(transactionId)
  //             .subscribe(
  //               (response) => {
  //                 this.status = false;
  //                 switch (response.message.status) {
  //                   case "completed":
  //                     this.handleCompletedPayment(response);
  //                     console.log(response);
  //                     this.pay = +response.amount;
  //                     break;
  //                   case "in_progress":
  //                     this.handlePendingPayment(response);
  //                     break;
  //                   case "failed":
  //                     this.handleFailedPayment(response);
  //                     break;
  //                   default:
  //                     this.handleUnknownStatus(response);
  //                 }
  //               },
  //               (error) => {
  //                 this.handleError(error);
  //               }
  //             );
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       } else {
  //         this.handleTransactionIdNotFound();
  //       }
  //     }
  //   );
  // }

  ngOnInit() :void {
    this.Confetti();
  }

  finishRedirect() {
    this.router.navigate(["/iniciar-sesion"]);
  }

  // private handleCompletedPayment(response: any): void {
  //   toggleLoader(this.loaderService, false);
  //   this.Confetti();
  //   this.state = 1;
  //   this.names = response.message.customer.full_name;
  //   // setTimeout(() => {
  //   //   this.router.navigate(["/pagar/credito"]);
  //   // }, 10000);
  // }

  // private handlePendingPayment(response: any): void {
  //   this.state = 2;
  //   toggleLoader(this.loaderService, false);
  //   this.Confetti();
  //   // setTimeout(() => {
  //   //   this.router.navigate(["/pagar/credito"]);
  //   // }, 10000);
  // }

  // private handleFailedPayment(response: any): void {
  //   this.state = 3;
  //   toggleLoader(this.loaderService, false);
  //   // setTimeout(() => {
  //   //   this.router.navigate(["/pagar/credito"]);
  //   // }, 10000);
  // }

  // private handleUnknownStatus(response: any): void {
  //   this.state = 3;
  //   toggleLoader(this.loaderService, false);
  // }

  // private handleError(error: any): void {
  //   toggleLoader(this.loaderService, false);
  //   this.ModalServiceAlert.openModal("Error!", error.error.message, "error");
  // }

  // private handleTransactionIdNotFound(): void {
  //   toggleLoader(this.loaderService, false);
  //   this.router.navigate(["/general/fastpayment"]);
  // }

  // ngOnDestroy(): void {
  //   if (this.routeSubscription) {
  //     this.routeSubscription.unsubscribe();
  //   }
  // }
}

<div class="header">
  <div class="modal-buttons" [class.active]="isActive">
    <div class="info-buttons" [class.active]="isActive">
      <span (click)="toggleMenu()">
        <i class="fa-solid fa-xmark"></i>
      </span>
      <img src="../../../assets/img/zíro.svg" alt="Logo zíro" />
      <p>Bienvenido, elige la mejor opción para ti!</p>
      <div class="modal-buttons-actions">
        <a *ngIf="pay" [routerLink]="'/general/fastpayment'" class="btn-pay">Pagar créditos<i class="fa-solid fa-cart-shopping"></i></a>
        <a *ngIf="register" [routerLink]="'/autenticacion/registrarme'" class="btn-pay">Registrarme <i class="fa-solid fa-circle-user"></i></a>
        <a *ngIf="login" [routerLink]="'/autenticacion/iniciar-sesion'" class="btn-pay">Iniciar sesión <i class="fa-solid fa-caret-right"></i></a>
      </div>
    </div>
  </div>
  <div class="logo">
    <img src="../../../assets/img/zíro.svg" alt="Logo zíro" />
  </div>
  <div class="close" (click)="toggleMenu()">
    <i class="fa-solid fa-bars"></i>
  </div>
  <div class="buttons">
    <a *ngIf="pay" [routerLink]="'/general/fastpayment'" class="btn-pay">Pagar<i class="fa-solid fa-cart-shopping"></i></a>
    <a *ngIf="register" [routerLink]="'/autenticacion/registrarme'" class="btn-pay">Registrarme <i class="fa-solid fa-circle-user"></i></a>
    <a *ngIf="login" [routerLink]="'/autenticacion/iniciar-sesion'" class="btn-pay">Iniciar sesión <i class="fa-solid fa-caret-right"></i></a>
  </div>
</div>

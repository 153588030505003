import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './auth/Register/register.component';
import { FirmaPagareComponent } from './auth/firma-pagare/firma-pagare.component';
import { FirmaPagareService } from './auth/firma-pagare/services/firma-pagare.service';
import { LoginComponent } from './auth/login/login.component';
import { NewRegisterComponent2 } from './auth/new-register/register.component';
import { ConfirmPasswordComponent } from './auth/recovery-password/confirm-password/confirm-password.component';
import { RecoveryPasswordComponent } from './auth/recovery-password/recovery-password.component';
import { PaysuccessComponent } from './commerce/paysuccess/paysuccess.component';
import { ExcelProcessorComponent } from './excel-processor/excel-processor.component';
import { ErrorPage1Component } from './pages/error-page/error-page1/error-page1.component';
import { CreditPaymentComponent } from './payment/credit-payment/credit-payment.component';
import { StatusPaymentComponent } from './payment/status-payments/status-payment.component';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { administrativeRouters } from './shared/routes/administrative-routers';
import { commerceRouters } from './shared/routes/commerce-routers';
import { customerRouters } from './shared/routes/customer-routers';
import { full } from './shared/routes/full';

const routes: Routes = [
  {
    path: 'iniciar-sesion',
    component: LoginComponent,
  },
  {
    path: 'registrarme/anterior',
    component: RegisterComponent,
  },
  {
    path: 'tu-credito',
    component: NewRegisterComponent2,
  },
  {
    path: 'tu-credito/:name/:code',
    component: NewRegisterComponent2,
  },
  {
    path: 'tu-credito/:code',
    component: NewRegisterComponent2,
  },
  {
    path: 'firma/pagare/:id', // Agregué ':id' para indicar que es un parámetro
    component: FirmaPagareComponent,
    canActivate: [FirmaPagareService], // Aplicamos el guard aquí
  },
  {
    path: 'firma/pagare/:id', // Agregué ':id' para indicar que es un parámetro
    component: FirmaPagareComponent,
    canActivate: [FirmaPagareService], // Aplicamos el guard aquí
  },
  {
    path: 'restablecer-contraseña',
    component: RecoveryPasswordComponent,
  },
  {
    path: 'confirmar-contraseña',
    component: ConfirmPasswordComponent,
  },
  {
    path: 'general/fastpayment',
    component: CreditPaymentComponent,
  },
  {
    path: 'estado/pago',
    component: StatusPaymentComponent,
  },
  { path: 'pago/exitoso', component: PaysuccessComponent },
  {
    path: 'consultar-rues',
    component: ExcelProcessorComponent,
  },
  {
    path: 'error/404',
    component: ErrorPage1Component,
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: administrativeRouters,
  },
  {
    path: '',
    component: ContentComponent,
    children: commerceRouters,
  },
  {
    path: '',
    component: ContentComponent,
    children: customerRouters,
  },
  {
    path: '',
    component: ContentComponent,
    children: full,
  },
  {
    path: '',
    redirectTo: '/iniciar-sesion',
    pathMatch: 'full',
  },  
  {
    path: '**',
    redirectTo: '/error/404',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}


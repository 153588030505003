import { Component } from '@angular/core';
import { StepService } from '../../components/step/service/step.service';

@Component({
  selector: 'app-third-page',
  templateUrl: './third-page.component.html',
  styleUrls: ['./third-page.component.scss']
})
export class ThirdPageComponent {
  constructor(public StepService: StepService) {}

  incrementar() {
    this.StepService.incrementar();
  }

  decrementar() {
    this.StepService.decrementar();
  }
}

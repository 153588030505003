import { Component, OnInit } from '@angular/core';
import { ModalServiceAlert } from './services/modal-alert.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  animations: [
    trigger('fade', [
      transition(':enter', animate(500, style({ opacity: 1 }))),
      transition(':leave', animate(100, style({ opacity: 0 }))),
    ]),
  ],
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  title: string = '';
  description: string = '';
  type: string = '';
  showModal: boolean = false;
  register: number;

  constructor(public modalService: ModalServiceAlert) {}

  ngOnInit() {
    this.modalService.showModal$.subscribe(showModal => {
      this.showModal = showModal;
    });

    this.modalService.modalData$.subscribe(data => {
      this.title = data.title;
      this.description = data.description;
      this.type = data.type;
      this.register = data.register;
    });
  }

  cerrarModal() {
    this.showModal = false;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-paginator-detail',
  templateUrl: './paginator-detail.component.html',
  styles: [
  ]
})
export class PaginatorDetailComponent {

    @Input() paginador?: any;

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalOpenSubject = new BehaviorSubject<boolean>(false);
  private modalDataSubject = new BehaviorSubject<any>(null); // Nuevo subject para los datos
  modalOpen$ = this.modalOpenSubject.asObservable();
  modalData$ = this.modalDataSubject.asObservable(); // Observable para los datos

  openModalWithData(data: any) { // Asegúrate de recibir los datos como argumento
    this.modalDataSubject.next(data);
    console.log(data)
    this.modalOpenSubject.next(true);
  }

  closeModal() {
    this.modalOpenSubject.next(false);
  }
}


import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalServiceAlert } from 'src/app/shared/components/modal-alert/services/modal-alert.service';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';
import { PagareInfoCustomerInterface } from '../../interfaces/info-customer-pagare.interface';
import { FirmaPagareService } from '../../services/firma-pagare.service';

@Component({
  selector: 'app-pagare-send-code',
  templateUrl: './pagare-send-code.component.html',
  styleUrls: ['./pagare-send-code.component.scss'],
})
export class PagareSendCodeComponent {
  customerInfo: PagareInfoCustomerInterface;
  @Output() changeStepAndCustomerId: EventEmitter<{
    step: number;
    customerId: number;
  }> = new EventEmitter();

  /**
   * Constructor para inicializar las dependencias.
   */
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private firmaPagareService: FirmaPagareService,
    private modalService: ModalServiceAlert
  ) {}

  /**
   * Método del ciclo de vida del componente que se ejecuta al inicializar el componente.
   * Realiza las siguientes acciones:
   */
  ngOnInit(): void {
    toggleLoader(this.loaderService, true);
    this.getInfoCustomer();
  }

  /**
   * Obtiene la información del cliente basada en el ID proporcionado en los parámetros de la ruta.
   *
   * La función inicia escuchando cambios en los parámetros de la ruta. Cuando detecta un cambio,
   * intenta obtener el ID del cliente y luego hace una solicitud para obtener la información detallada
   * del cliente usando ese ID. Si la solicitud es exitosa, asigna la información del cliente a
   * `this.customerInfo`. Si ocurre un error, se muestra un mensaje de error.
   *
   * @returns {void} No devuelve ningún valor.
   */
  getInfoCustomer() {
    const messageError = 'Hubo un error al obtener los créditos';
    this.activatedRoute.paramMap.subscribe((params) => {
      let customerId: number = Number(params.get('id'));
      this.firmaPagareService.getInfoCustomer(customerId).subscribe(
        (response) => {
          toggleLoader(this.loaderService, false);
          if (response.ok) {
            this.customerInfo = response.data;
          } else {
            Swal.fire({
              title: 'Error',
              text: response.message || messageError,
              icon: 'error',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/iniciar-sesion']);
              }
            });
          }
        },
        (error) => {
          toggleLoader(this.loaderService, false);
          Swal.fire({
            title: 'Error',
            text: error.error.message || messageError,
            icon: 'error',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/iniciar-sesion']);
            }
          });
          console.error(error);
        }
      );
    });
  }

  /**
   * Envia un código OTP para el pagare al cliente correspondiente.
   *
   * Al iniciar la solicitud, muestra un loader indicando que está "Enviando código OTP".
   * Una vez que la solicitud se completa, se oculta el loader y se muestra un mensaje de éxito o error
   * según la respuesta del servidor.
   *
   * @function
   * @returns {void} No devuelve ningún valor.
   */
  sendCodeOtpPagare() {
    toggleLoader(this.loaderService, true, 'Enviando código');
    const messageError = 'Error al enviar el código';
    const customerId = this.customerInfo.id;
    this.firmaPagareService.sendCodeOtpPagare(customerId).subscribe(
      (response) => {
        toggleLoader(this.loaderService, false);
        if (response.ok) {
          this.changeStepAndCustomerId.emit({
            step: 2,
            customerId: this.customerInfo.id,
          });

          this.modalService.openModal(
            '¡Perfecto!',
            response.message || messageError,
            'success'
          );
        } else {
          this.modalService.openModal(
            'Error',
            response.message || messageError,
            'error'
          );
        }
      },
      (error) => {
        toggleLoader(this.loaderService, false);

        this.modalService.openModal(
          'Error',
          error.error.message || messageError,
          'error'
        );
        console.error(error);
      }
    );
  }
}

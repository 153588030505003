<div
  id="carouselExampleFade"
  class="carousel slide carousel-fade"
  data-bs-ride="carousel"
>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img
        [src]="document_file_up"
        class="d-block w-100"
        alt="Frontal cedula"
      />
    </div>
    <div class="carousel-item">
      <img
        [src]="document_file_down"
        class="d-block w-100"
        alt="Trasera cedula"
      />
    </div>
    <div class="carousel-item">
      <img [src]="image_file" class="d-block w-100" alt="Selfie" />
    </div>
  </div>
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleFade"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleFade"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

import { Routes } from "@angular/router";

export const commerceRouters: Routes = [

	/***********************
	 * RUTAS USUARIO COMERCIO*
	 **********************/
    {
		path: "proveedores/panel",
		loadChildren: () =>
            import(
                "../../commerce/commerce-dashboards/commerce-dashboards.module"
            ).then((m) => m.CommerceDashboardsModule),
	},
	{
		path: "proveedores/solicitudes/creditos",
		loadChildren: () =>
			import(
				"../../commerce/credits-requests-commerces/credits-requests-commerces.module"
			).then((m) => m.CreditsRequestsCommercesModule),
	},

    {
		path: "proveedores/creditos",
		loadChildren: () =>
			import(
				"../../commerce/commerce-credits/commerce-credits.module"
			).then((m) => m.CommerceCreditsModule),
	},

    {
		path: "proveedores/reporte",
		loadChildren: () =>
			import(
				"../../commerce/commerce-credits-report/commerce-credits-report.module"
			).then((m) => m.CommerceCreditsReportModule),
	},

];

import { toggleLoader } from "src/app/shared/helpers/functions-helper.service";
import { Component } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/administrative/customers/services/customer-service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import Swal from "sweetalert2";
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: "app-customer-blocked-cupo",
  templateUrl: "./customer-blocked-cupo.component.html",
  styleUrls: ["./customer-blocked-cupo.component.scss"],
})
export class CustomerBlockedCupoComponent {
  customer: any;

  constructor(
    private customerService: CustomerService,
    private loaderService: LoaderService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    console.log(this.customer);
  }

  customerBlockedCupo() {
    toggleLoader(this.loaderService, true);
    // this.customerService.customerBlockedCupo(this.customer.id).subscribe(
    //   (response) => {
    //     toggleLoader(this.loaderService, false);
    //     if (response) {
    //       console.log(response);
    //       Swal.fire("Éxito", "Cupo bloqueado correctamente", "success");
    //       this.activeModal.dismiss();
    //     } else {
    //       Swal.fire("Error", response, "error");
    //     }
    //   },
    //   (error) => {
    //     toggleLoader(this.loaderService, false);
    //     console.error(error);
    //     Swal.fire(
    //       "Error",
    //       error.error.message ? error.error.message : error.message,
    //       "error"
    //     );
    //   }
    // );
  }
}
